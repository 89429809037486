/* eslint-disable jsx-a11y/alt-text, jsx-a11y/media-has-caption */
import React from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Typography,
  Grid,
  Tabs,
  Tab,
  Box,
  IconButton,
  Tooltip,
  makeStyles,
} from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import DeleteIcon from '@material-ui/icons/Delete'
import StarIcon from '@material-ui/icons/Star'
import { Link, useParams, useLocation, useHistory } from 'react-router-dom'
import moment from 'moment'
import { useYouVersionAuth } from '@youversion/react'
import { Api4Client } from '@youversion/api/4.0/core'
import {
  statusTypes,
  arrayMove,
  getParamsFromProps,
  handlePresignedUpload,
  getFeaturedCollectionById,
  consolidateVideoIdLists,
} from 'utils'
import { AlertBar, AlertProvider, useAlert } from 'components/layout/alert-bar'
import SecondaryAppBar from 'components/layout/secondary-app-bar'
import Video from 'tupos/models/video'
import Collections from 'tupos/models/collections'
import Languages from 'tupos/models/languages'
import DetailCard from 'components/layout/detail-card'
import CollectionDatePicker from 'components/views/collection/collection-date-picker'
import VideoSelector from 'components/views/video/video-selector-list'
import { LanguageAddCard, LanguageEditCard } from 'components/ui/language-cards'
import { FriendlyError } from 'components/ui/friendly-error-page'
import imgPeopleCelebrating from 'assets/img/people-celebrating.png'
import ButtonDialog from 'components/ui/button-dialog'

const useStyles = makeStyles((theme) => ({
  content: {
    maxWidth: 960,
    margin: 'auto',
    padding: theme.spacing(2),
  },
  tabs: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  textField: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(1),
    width: 375,
  },
  w100: { width: '100%' },
  subtitle: {
    display: 'flex',
    alignItems: 'center',
    '& svg': { marginRight: 4 },
  },
}))
function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  // Setting index and value to use number, since by default they are integers
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

function CollectionEditPage() {
  const location = useLocation()
  const history = useHistory()
  const { id: collectionId } = useParams()
  const { throwAlert } = useAlert()
  const classes = useStyles()
  const parentLanguage = getParamsFromProps({ location }, 'lang', 'en')

  const [state, setState] = React.useState({
    value: 0,
  })
  const [collection, setCollection] = React.useState({})
  const [loading, setLoading] = React.useState({ status: 'loading' })
  const [publishDates, setPublishDates] = React.useState([null, null])
  const [needsSave, setNeedsSave] = React.useState(false)
  const [initialLanguageVideos, setInitialLanguageVideos] = React.useState([])

  const { getToken } = useYouVersionAuth()

  React.useEffect(() => {
    async function loadData() {
      try {
        const fetchedCollection = await Collections.get(
          collectionId,
          parentLanguage,
        )

        const { rows: videos } = await Video.getCollection({
          collection: collectionId,
          fields: 'title,id',
          page: '*',
          language: parentLanguage,
          orientation: '*',
          types: '*',
        })
        const videoIdlist = videos.map((video) => {
          return video.id
        })
        const collectionObject = fetchedCollection.toObject()
        setCollection({ ...collectionObject, videos: videoIdlist })
        setInitialLanguageVideos(videoIdlist)
        setPublishDates([
          collectionObject.start
            ? moment().dayOfYear(collectionObject.start).format('MMMM D YYYY')
            : null,
          collectionObject.end
            ? moment().dayOfYear(collectionObject.end).format('MMMM D YYYY')
            : null,
        ])
        setLoading({ status: 'loaded' })
      } catch (error) {
        setLoading({
          status: 'error',
          error: { message: 'Error loading collection data.' },
        })
      }
    }

    if (loading.status === 'loading') {
      loadData()
    }
  }, [loading, collectionId, parentLanguage])

  const [languages, setLanguages] = React.useState()

  React.useEffect(() => {
    async function handleFetchLanguages() {
      try {
        const languagesResponse = await Languages.get(
          'collection',
          collectionId,
        )
        setLanguages(languagesResponse)
      } catch (error) {
        throwAlert({
          type: 'error',
          id: 'languages_fetch_error',
          message: 'Error fetching languages for this collection',
        })
      }
    }
    handleFetchLanguages()
  }, [collectionId, throwAlert])

  function handleChange(event, value) {
    setState({ ...state, value })
  }

  // Updating Dates and Video Order
  async function handleUpdateDatesAndOrder() {
    const token = await getToken()
    const allVideosList = await Api4Client.get({
      token,
      subDomain: 'movies',
      path: 'videos',
      options: {
        headers: {
          'Accept-Language': '*',
        },
        cache: 'no-cache',
      },
      params: {
        fields: 'id',
        page: '*',
        collection: collection.id,
      },
    })
    // const allVideosList = await Video.getAllVideosForCollection(collection.id)
    const collectionClass = Collections.toClass({
      json: {
        ...collection,
        videos: consolidateVideoIdLists({
          completeIdList: allVideosList.$data?.map((video) => video.$id) || [],
          initialLanguageIdList: initialLanguageVideos,
          languageIdList: collection.videos,
        }),
        start: moment(publishDates[0]).dayOfYear(),
        end: moment(publishDates[1]).dayOfYear(),
      },
      language: parentLanguage,
    })
    try {
      await collectionClass.updateDatesAndOrder()
      setLoading({ status: 'loading' })
      setNeedsSave(false)
    } catch (error) {
      setLoading({
        status: 'error',
        error: { message: error },
      })
    }
  }

  // Adding/removing videos
  function handleVideoItemCheck(checked, value) {
    setNeedsSave(true)

    const videosArray = [...collection.videos]

    if (!checked) {
      const index = videosArray.indexOf(value)
      if (index !== -1) {
        videosArray.splice(index, 1)
      }
    } else {
      videosArray.push(parseInt(value, 10))
    }
    setCollection({
      ...collection,
      videos: videosArray,
    })
  }

  function handleCurrentVideosSortEnd({ oldIndex, newIndex }) {
    setNeedsSave(true)

    setCollection({
      ...collection,
      videos: arrayMove(collection.videos, oldIndex, newIndex),
    })
  }

  const [
    hasDeletedAllCollections,
    setHasDeletedAllCollections,
  ] = React.useState(false)

  async function handleDeleteLanguage(language) {
    const collectionClass = Collections.toClass({ json: collection, language })
    try {
      await collectionClass.delete(language)
      const languagesArray = [...languages]
      const filteredLanguages = languagesArray.filter(
        (lang) => lang !== language,
      )
      // if last video was deleted, let the admin know
      if (filteredLanguages.length === 0) {
        setHasDeletedAllCollections(true)
        setTimeout(() => history.replace('/collections/'), 2500)
      } else {
        throwAlert({
          type: statusTypes.SUCCESS,
          id: `collection_delete_success_${language}`,
          message: `Successfully deleted (${language}) collection.`,
          enableFuse: true,
        })
      }
      setLanguages([...filteredLanguages])
      throwAlert({
        type: 'success',
        id: `language_delete_success_${language}`,
        message: `Language ${language.toUpperCase()} successfully deleted.`,
        enableFuse: true,
      })
    } catch (error) {
      setLoading({
        status: 'error',
        error: { message: error },
      })
      throwAlert({
        type: 'error',
        id: `language_delete_error_${language}`,
        message: `There was an error deleting language ${language.toUpperCase()}. ${
          languages.length === 1
            ? 'There might still be videos attached to this collection.'
            : ''
        }`,
      })
    }
  }

  // Delete All Languages Code: Removed, but code is kept intentionally just in case we want to use it again.
  // async function deleteAllCollectionsFromId(collectionLanguages) {
  //   const deleteAllCollectionsPromises = collectionLanguages.map((language) => {
  //     const collectionClass = Collections.toClass({
  //       json: { id: collectionId },
  //       language,
  //     })
  //     return collectionClass.delete()
  //   })
  //   return Promise.all(deleteAllCollectionsPromises)
  // }

  // Delete All Languages Code: Removed, but code is kept intentionally just in case we want to use it again.
  // async function handleCollectionDeleteAll() {
  //   try {
  //     await deleteAllCollectionsFromId(languages)
  //   } catch ({ message }) {
  //     throwAlert({
  //       type: statusTypes.ERROR,
  //       id: 'collection_delete_all_error',
  //       message: `Failed to delete collections. ${message}. Videos may still be attached to the collection.`,
  //     })
  //     return
  //   }
  //   setHasDeletedAllCollections(true)
  //   setTimeout(() => history.replace('/collections/'), 2500)
  // }

  async function handleUpdateLanguage({ object, language }) {
    const collectionClass = Collections.toClass({
      json: object,
      language,
    })
    try {
      if (object.thumbnailFile) {
        try {
          // Upload thumbnail
          await collectionClass
            .getPresignedRequest(collectionClass.id)
            .then((response) => {
              // On success, actually attempt our S3 file upload!
              const thumbnailUploadResponse = handlePresignedUpload(
                object.thumbnailFile,
                response.upload_url,
                response.params,
              )

              return thumbnailUploadResponse
            })
        } catch (error) {
          throwAlert({
            type: 'error',
            id: `language_thumbnail_upload_error_${language}`,
            message: `There was an error uploading the thumbnail for language ${language.toUpperCase()}`,
          })
        }
      }
      await collectionClass.updateTitleDescriptionCollection()
      throwAlert({
        type: 'success',
        id: `language_update_success_${language}`,
        message: `Language ${language.toUpperCase()} successfully updated.`,
        enableFuse: true,
      })
    } catch (error) {
      throwAlert({
        type: 'error',
        id: `language_update_error_${language}`,
        message: `There was an error updating language ${language.toUpperCase()}`,
      })
    }
  }

  async function handleSaveNewLanguage({ object, language }) {
    const collectionClass = Collections.toClass({
      json: {
        ...object,
        id: collection.id,
        start: collection.start,
        end: collection.end,
      },
      language,
    })
    try {
      if (object.thumbnailFile) {
        // Upload thumbnail
        await collectionClass
          .getPresignedRequest(collectionClass.id)
          .then((response) => {
            // On success, actually attempt our S3 file upload!
            const thumbnailUploadResponse = handlePresignedUpload(
              object.thumbnailFile,
              response.upload_url,
              response.params,
            )

            return thumbnailUploadResponse
          })
      }
      await collectionClass.addCollections(collectionId)
      setLanguages([...languages, language].sort())
      throwAlert({
        type: 'success',
        id: `new_language_save_success_${language}`,
        message: `Language ${language.toUpperCase()} added successfully. It may take a few minutes for it to display correctly.`,
        enableFuse: true,
      })
    } catch (error) {
      throwAlert({
        type: 'error',
        id: `new_language_save_error_${language}`,
        message: `Error! The new language ${language.toUpperCase()} failed to save.`,
      })
    }
  }

  const { value } = state
  if (loading.status !== 'loaded') return null

  if (hasDeletedAllCollections)
    return (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        height="calc(100vh - 64px)"
        width="100%"
      >
        <FriendlyError
          imageCredit="Illustrations from https://www.ls.graphics/"
          title="Collection Successfully Deleted"
          message="You will be redirected to the Collections page momentarily."
          imageSrc={imgPeopleCelebrating}
          imageHeight={347}
          imageWidth={306}
        />
      </Box>
    )

  function handlePublishDatesUpdate(newPublishDates) {
    setNeedsSave(true)
    setPublishDates(newPublishDates)
  }

  const isFeaturedCollection = Boolean(getFeaturedCollectionById(collection.id))

  // Delete All Languages Code: Removed, but code is kept intentionally just in case we want to use it again.
  // let deleteBtnTooltipText =
  //   'Delete this collection in all available languages.'
  // if (isFeaturedCollection) {
  //   deleteBtnTooltipText = 'Cannot delete a Featured Collection'
  // } else if (collection.videos.length > 0) {
  //   deleteBtnTooltipText = 'Remove all videos before deleting the collection.'
  // }

  const actionComponents = (
    <Box display="flex" alignItems="center">
      <Box mr={1}>
        {/* Delete All Languages Code: Removed, but code is kept intentionally just in case we want to use it again. */}
        {/* <ButtonDialog
          dialogPrompt="Delete this collection in all available languages?"
          dialogConfirmText="Delete"
          dialogDenyText="Cancel"
          handleDialogConfirm={handleCollectionDeleteAll}
          handleDialogDeny={() => {}} // do nothing, just close the dialog
          actionComponent={
            <Tooltip title={deleteBtnTooltipText}>
              <span>
                <IconButton
                  aria-label="delete all collection languages"
                  className={classes.margin}
                  disabled={Boolean(collection.videos.length > 0)}
                >
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </span>
            </Tooltip>
          }
          disabled={
            isFeaturedCollection || Boolean(collection.videos.length > 0)
          }
        /> */}
        <Tooltip title="To delete this Collection, delete each language one by one.">
          <span>
            <IconButton
              aria-label="delete all collection languages"
              className={classes.margin}
              disabled={true}
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          </span>
        </Tooltip>
      </Box>
      <ButtonDialog
        dialogPrompt="Would you like to save this collection?"
        dialogConfirmText="Save"
        dialogDenyText="Cancel"
        handleDialogConfirm={handleUpdateDatesAndOrder}
        handleDialogDeny={() => {}} // do nothing, just close the dialog
        actionComponent={
          <Button
            aria-label="Save collection updates"
            variant="contained"
            color="primary"
          >
            Save
          </Button>
        }
        disabled={!needsSave}
      />
    </Box>
  )

  return (
    <>
      <SecondaryAppBar
        title="Edit Collection"
        backComponent={
          <IconButton
            onClick={history.goBack}
            component={Link}
            to={`/collections/${collectionId}?lang=${parentLanguage}`}
            aria-label="Go back to collection"
          >
            <ArrowBackIcon />
          </IconButton>
        }
        actionComponent={actionComponents}
        subtitle={
          isFeaturedCollection ? (
            <Typography variant="caption" className={classes.subtitle}>
              <StarIcon fontSize="inherit" />
              Featured Collection
            </Typography>
          ) : null
        }
      />
      <Grid className={classes.content}>
        <DetailCard>
          <Box className={classes.tabs} width="100%">
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
              scrollButtons="on"
            >
              {/* eslint-disable-next-line react/jsx-props-no-spreading */}
              <Tab label="Display Dates" {...a11yProps(0)} />
              <Tab
                label={`Videos List - (${parentLanguage?.toUpperCase()})`}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...a11yProps(1)}
              />
            </Tabs>
          </Box>

          <TabPanel
            value={value}
            index={0}
            data-testid="displayDates"
            className={classes.w100}
          >
            <Grid container={true}>
              <Grid item={true} xs={12}>
                <CollectionDatePicker
                  onChange={handlePublishDatesUpdate}
                  value={publishDates}
                />
              </Grid>
            </Grid>
          </TabPanel>

          <TabPanel
            value={value}
            index={1}
            data-testid="videosList"
            className={classes.w100}
          >
            <Grid item={true} xs={12}>
              <VideoSelector
                onCheck={handleVideoItemCheck}
                onSortEnd={handleCurrentVideosSortEnd}
                currentVideos={collection.videos}
                language={parentLanguage}
                currentCollectionId={parseInt(collectionId, 10)}
              />
            </Grid>
          </TabPanel>
        </DetailCard>
      </Grid>
      <Grid className={classes.content}>
        <LanguageAddCard
          onSave={handleSaveNewLanguage}
          languages={languages}
          type="collection"
        />
        {languages.length > 0
          ? languages.map((language) => {
              return (
                <LanguageEditCard
                  language={language}
                  type="collection"
                  id={collectionId}
                  key={`${collectionId}_${language}`}
                  onSave={handleUpdateLanguage}
                  saveButtonLabel="Update"
                  onDelete={() => handleDeleteLanguage(language)}
                  expandable={true}
                  disableDelete={Boolean(
                    languages.length === 1 && collection.videos.length > 0,
                  )}
                />
              )
            })
          : null}
      </Grid>
    </>
  )
}

function CollectionEdit(props) {
  return (
    <AlertProvider>
      <AlertBar />
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <CollectionEditPage {...props} />
    </AlertProvider>
  )
}

export default CollectionEdit
