import PropTypes from 'prop-types'

const featuredCollections = {
  staging: [
    { id: 461, name: 'Featured Videos', orientation: 'landscape' },
    { id: 460, name: 'Featured Teaching Clips', orientation: 'portrait' },
  ],
  production: [
    { id: 130, name: 'Featured Videos', orientation: 'landscape' },
    { id: 131, name: 'Featured Teaching Clips', orientation: 'portrait' },
  ],
}

/**
 * Gets the requested featured collection or an array of all featured collections.
 *
 * @example
 * const portraitFeaturedCollection = getHardCodedFeaturedCollections('portrait')
 * const allFeaturedCollections = getHardCodedFeaturedCollections()
 *
 * @param {string} orientationFilter Optional filter to get a specific orientation-based featured collection
 * @returns {(object[]|object)} If `orientationFilter` is provided it will return a singular object. Otherwise it will return an array of featured collection objects.
 */
export function getHardCodedFeaturedCollections(orientationFilter) {
  if (
    orientationFilter &&
    ['portrait', 'landscape'].includes(orientationFilter)
  ) {
    // filtering it this way just in case we add more featured
    // collections in the future or if someone accidentally
    // changes the order of the featuredCollections array.
    return featuredCollections[process.env.REACT_APP_API_ENV].find(
      (collection) => collection.orientation === orientationFilter,
    )
  }

  return featuredCollections[process.env.REACT_APP_API_ENV]
}

getHardCodedFeaturedCollections.propTypes = {
  orientationFilter: PropTypes.string,
}

getHardCodedFeaturedCollections.default = {
  orientationFilter: '',
}

/**
 * Gets featured collection by id.
 *
 * @example
 * const featuredCollection = getFeaturedCollectionById(12)
 * const invalidCollection = getFeaturedCollectionById(-1) // will return false
 *
 * @param {number} id Collection id
 * @returns {(object|boolean)} returns collection or returns false if id is not found.
 */
export function getFeaturedCollectionById(id) {
  if (!id) return false

  let featuredCollection
  getHardCodedFeaturedCollections().forEach((collection) => {
    if (id === collection.id) {
      featuredCollection = collection
    }
  })

  return featuredCollection || false
}

getFeaturedCollectionById.propTypes = {
  id: PropTypes.number.isRequired,
}
