/**
 * @module sanitizeUrl
 */

/**
 * Sanitizes a url.
 *
 * @alias module:sanitizeUrl
 *
 * @param {string} url - The url to test.
 *
 * @returns {string|boolean} - A valid url string or false.
 */
export function sanitizeUrl(url) {
  const validProtocols = ['https:', 'http:']
  try {
    const sanitizedUrl = new URL(url)

    if (!validProtocols.includes(sanitizedUrl.protocol)) {
      return false
    }
    return sanitizedUrl.href
  } catch (error) {
    return false
  }
}
