import React from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core'

/**
 * Button that when clicked contains a dialog.
 * */
export default function ButtonDialog({
  actionComponent,
  dialogConfirmText,
  dialogContentText,
  dialogDenyText,
  dialogPrompt,
  disabled,
  handleDialogConfirm,
  handleDialogDeny,
  skipDialog,
}) {
  const [open, setOpen] = React.useState(false)

  let actionOnClick = () => setOpen(true)
  if (disabled === true) {
    actionOnClick = () => {}
  }
  const actionButton = React.cloneElement(actionComponent, {
    onClick: actionOnClick,
    disabled,
  })

  const handleDeny = () => {
    handleDialogDeny()
    setOpen(false)
  }

  const handleConfirm = () => {
    handleDialogConfirm()
    setOpen(false)
  }

  if (skipDialog) {
    return (
      <Box clone={true} onClick={handleDialogConfirm}>
        {actionButton}
      </Box>
    )
  }

  return (
    <>
      {actionButton}
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>{dialogPrompt}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogContentText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeny} color="primary">
            {dialogDenyText}
          </Button>
          <Button onClick={handleConfirm} color="primary" variant="contained">
            {dialogConfirmText}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

ButtonDialog.propTypes = {
  actionComponent: PropTypes.node.isRequired,
  dialogConfirmText: PropTypes.string.isRequired,
  dialogContentText: PropTypes.string,
  dialogDenyText: PropTypes.string.isRequired,
  dialogPrompt: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  handleDialogConfirm: PropTypes.func.isRequired,
  handleDialogDeny: PropTypes.func.isRequired,
  skipDialog: PropTypes.bool,
}

ButtonDialog.defaultProps = {
  dialogContentText: '',
  disabled: false,
  skipDialog: false,
}
