/* eslint-disable import/prefer-default-export */
export const statusTypes = {
  IDLE: 'idle',
  PENDING: 'pending',
  RESOLVED: 'resolved',
  REJECTED: 'rejected',
  ERROR: 'error',
  SUCCESS: 'success',
  WARNING: 'warning',
  INFO: 'info',
}

export const videoStatusTypes = {
  UNREADY: 'unready',
  READY: 'ready',
  PUBLISHED: 'published',
  FAILED: 'failed',
}

export const fieldVariants = {
  CREATE: 'create',
  UPDATE: 'update',
  ADD_LANGUAGE: 'add-language',
}

export const imageDimensions = {
  PUBLISHER_BANNER: {
    width: 915,
    height: 305,
  },
  PUBLISHER_LOGO: {
    size: 150,
  },
}

export const AIR_TABLE_REQUEST_ACCESS_FORM_URL =
  'https://airtable.com/shrQ2G2V5qx74va34'

export const COLLECTION_CAROUSEL = {
  PAGE: 1,
  PAGE_SIZE: 10,
  THUMBNAIL_SIZE: {
    height: 100,
    width: 175,
  },
}

export const LOCAL_STORAGE = {
  KEYS: {
    PUBLISHERS: 'YV:BB:PublishersData',
    VIDEOS: 'YV:BB:VideosData',
  },
  TYPES: {
    ARRAY: 'array',
    NUMBER: 'number',
    OBJECT: 'object',
    STRING: 'string',
  },
}
