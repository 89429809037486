/**
 * @module SearchVideo
 */
import { Api4Client } from '@youversion/api/4.0/core'
import Languages from 'tupos/models/languages'

/**
 * Search for a video.
 *
 * @param {object} params - Search Video params.
 * @param {string} params.query - The query to make the search.
 * @param {string} params.token - The token required for authorization.
 * @param {string} params.urlLanguage - The language the search should be based on.
 * @param {string} params.urlPage - The page number.
 *
 * @throws {Error} - Throws an error if there's a problem with the API response.
 *
 * @returns {Promise<object>} - The search response.
 */
export async function searchVideo({ query, token, urlLanguage, urlPage }) {
  const environment =
    process.env.REACT_APP_API_ENV === 'staging' ? 'staging' : ''

  try {
    const searchResponse = await fetch(
      `https://search.youversionapi${environment}.com/4.0/videos?query=${query}&user_intent=text&language_tag=${urlLanguage}&page=${urlPage}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
        },
      },
    )
    const parsedSearch = await searchResponse.json()
    const videosResponse = await Promise.all(
      parsedSearch.data.map((video) => {
        return Api4Client.get({
          token,
          subDomain: 'movies',
          path: `videos/${video.id}`,
          options: {
            headers: {
              'Accept-Language': urlLanguage,
            },
            cache: 'no-cache',
          },
        })
      }),
    )

    parsedSearch.rows = videosResponse.map((videoResponse, index) => {
      return {
        ...videoResponse.$data,
        createdDt: videoResponse.$data.created_dt.$value,
        description: videoResponse.$data.description.$value,
        id: parsedSearch.data[index].id,
        language: videoResponse.$data.language.$value,
        orientation: videoResponse.$data.orientation.$value,
        publisherId: videoResponse.$data.publisher_id.$value,
        references: videoResponse.$data.references.$value,
        runtime: videoResponse.$data.runtime.$value,
        status: videoResponse.$data.status.$value,
        title: videoResponse.$data.title.$value,
        trackingId: videoResponse.$data.tracking_id.$value,
        type: videoResponse.$data.type.$value,
      }
    })

    parsedSearch.nextPage = parsedSearch.next_page
    parsedSearch.pageSize = parsedSearch.page_size

    return parsedSearch
  } catch (error) {
    throw new Error(error.message)
  }
}

/**
 * Fetch languages.
 *
 * @throws {Error} - Throws an error if there's a problem with the API response.
 *
 * @returns {Promise<object>} - The search response.
 */
export async function fetchLanguages() {
  // Initialize languages.
  try {
    // We don't pass an ID here, because we want languages for ALL videos.
    const languagesResponse = await Languages.get('video')

    return languagesResponse
  } catch (error) {
    return new Error(
      JSON.stringify({
        id: 'language_fetch_error',
        message: 'Error fetching videos for this language',
        type: 'error',
      }),
    )
  }
}
