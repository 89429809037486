/**
 * @module YVAppBar
 */

import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import classNames from 'classnames'
import AddContentDropDown from 'components/ui/drop-down'
import { makeStyles } from '@material-ui/core/styles'
import {
  AppBar,
  Avatar,
  Box,
  Button,
  Divider,
  Hidden,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Tooltip,
  Typography,
} from '@material-ui/core'
import AccountCircle from '@material-ui/icons/AccountCircle'
import DashboardIcon from '@material-ui/icons/Dashboard'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import MoreIcon from '@material-ui/icons/MoreVert'
import MovieFilterIcon from '@material-ui/icons/MovieFilter'
import PeopleIcon from '@material-ui/icons/People'
import SubscriptionsIcon from '@material-ui/icons/Subscriptions'
import bibleAppIcon from '@youversion/react/assets/images/bible-app-icon.png'
import { ReactComponent as YouVersionLogo } from '@youversion/react/assets/images/youversion-logo.svg'
import PropTypes from 'prop-types'
import urlReplacer from 'utils/url-replacer'

const isStaging = window.API_ENV !== 'production'

const useStyles = makeStyles((theme) => ({
  bibleAppLogo: {
    marginRight: theme.spacing(1),
  },
  grow: {
    flexGrow: 1,
  },
  title: {
    lineHeight: 'normal',
    margin: 0,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: theme.palette.grey['900'],
  },
  sectionDesktop: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  sectionMobile: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  subTitle: {
    marginTop: theme.spacing(-1),
    marginRight: theme.spacing(-1),
    display: 'block',
  },
  staging: {
    fontWeight: 'bold',
    opacity: 0.15,
    [theme.breakpoints.down('xs')]: {
      fontSize: '1rem',
    },
  },
  navSection: {
    flexBasis: '33.33%',
    [theme.breakpoints.down('sm')]: {
      flexBasis: '50%',
    },
  },
  navIconTab: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    color: theme.palette.grey[700],
    '&:after': {
      background: 'inherit',
      borderRadius: 99,
      bottom: -2,
      content: "' '",
      height: 3,
      position: 'absolute',
      width: '100%',
    },
  },
  activeTab: {
    color: theme.palette.common.white,
    '&:after': {
      background: theme.palette.common.white,
    },
  },
  verticalDivider: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    height: '60%',
    alignSelf: 'center',
    backgroundColor: theme.palette.grey[700],
  },
  yvLogo: {
    '& path': {
      fill: 'white',
    },
  },
}))

/**
 * YVAppBar navigation bar to redirect pages or add contents such as video, publisher, or collection.
 *
 * @alias module:YVAppBar
 *
 * @param {object} props - The Component props object.
 * @param {object} props.user - The signed in user object.
 * @param {Function} props.signOut - The user sign out function.
 *
 * @returns {React.ReactElement} - The YVAppBar component.
 *
 * @example
 * <YVAppBar user={user} signOut={signOut} />
 */

function YVAppBar({ user, signOut }) {
  const classes = useStyles()
  const { pathname } = useLocation()

  const [anchorEl, setAnchorEl] = React.useState(null)
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null)

  const isMenuOpen = Boolean(anchorEl)
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl)

  function handleMobileMenuOpen(event) {
    setMobileMoreAnchorEl(event.currentTarget)
  }

  function handleMobileMenuClose() {
    setMobileMoreAnchorEl(null)
  }

  function handleMenuClose() {
    setAnchorEl(null)
    handleMobileMenuClose()
  }

  function handleProfileMenuOpen(event) {
    setAnchorEl(event.currentTarget)
  }

  function handleSignout() {
    signOut()
    handleMenuClose()
  }

  function isActiveTab(tabName) {
    return pathname.includes(tabName)
  }

  let avatar = <AccountCircle />
  if (user.avatar_url?.$value) {
    const avatarUrl = urlReplacer(user.avatar_url.$value, {
      width: '60',
      height: '60',
    })
    avatar = <Avatar alt={user.first_name.$value} src={avatarUrl} />
  }

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem>
        {`${user.first_name.$value} ${user.last_name.$value}`}
      </MenuItem>
      <Divider />
      <MenuItem onClick={handleSignout}>
        <ListItemIcon>
          <ExitToAppIcon />
        </ListItemIcon>
        <ListItemText primary="Sign Out" />
      </MenuItem>
    </Menu>
  )

  const renderMobileMenu = (
    <Menu
      aria-label="popup menu navigation"
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem component={Link} to="/" onClick={handleMenuClose}>
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Dashboard" />
      </MenuItem>
      <MenuItem component={Link} to="/videos" onClick={handleMenuClose}>
        <ListItemIcon>
          <SubscriptionsIcon />
        </ListItemIcon>
        <ListItemText primary="All Videos" />
      </MenuItem>
      <MenuItem component={Link} to="/publishers" onClick={handleMenuClose}>
        <ListItemIcon>
          <PeopleIcon />
        </ListItemIcon>
        <ListItemText primary="All Publishers" />
      </MenuItem>
      <MenuItem component={Link} to="/collections" onClick={handleMenuClose}>
        <ListItemIcon>
          <MovieFilterIcon />
        </ListItemIcon>
        <ListItemText primary="All Collections" />
      </MenuItem>
      <Divider />
      <MenuItem onClick={handleSignout}>
        <ListItemIcon>
          <ExitToAppIcon />
        </ListItemIcon>
        <ListItemText primary="Sign Out" />
      </MenuItem>
    </Menu>
  )

  const renderDesktopNavIcons = (
    <>
      <Box position="relative">
        <IconButton
          component={Link}
          to="/"
          className={classNames(classes.navIconTab, {
            [classes.activeTab]: pathname === '/',
          })}
          aria-label="Dashboard Page"
        >
          <Tooltip title="Dashboard">
            <DashboardIcon fontSize="large" />
          </Tooltip>
        </IconButton>
      </Box>
      <Box position="relative">
        <IconButton
          component={Link}
          to="/videos"
          className={classNames(classes.navIconTab, {
            [classes.activeTab]: isActiveTab('videos'),
          })}
          aria-label="Videos Page"
        >
          <Tooltip title="All Videos">
            <SubscriptionsIcon fontSize="large" />
          </Tooltip>
        </IconButton>
      </Box>
      <Box position="relative">
        <IconButton
          component={Link}
          to="/publishers"
          className={classNames(classes.navIconTab, {
            [classes.activeTab]: isActiveTab('publisher'),
          })}
          aria-label="Publishers Page"
        >
          <Tooltip title="All Publishers">
            <PeopleIcon fontSize="large" />
          </Tooltip>
        </IconButton>
      </Box>
      <Box position="relative">
        <IconButton
          component={Link}
          to="/collections"
          className={classNames(classes.navIconTab, {
            [classes.activeTab]: isActiveTab('collections'),
          })}
          aria-label="Collections Page"
        >
          <Tooltip title="All Collections">
            <MovieFilterIcon fontSize="large" />
          </Tooltip>
        </IconButton>
      </Box>
    </>
  )

  return (
    <AppBar position="fixed" className={classNames(classes.appBar)}>
      <Toolbar disableGutters={true}>
        <Box display="flex" width="100%" alignItems="center">
          <Box
            alignItems="center"
            className={classes.navSection}
            display="flex"
            justifyContent="flex-start"
          >
            <Button
              className={classes.logoButton}
              color="secondary"
              component={Link}
              disableRipple={true}
              to="/"
              variant="text"
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="flex-start"
              >
                <img
                  className={classes.bibleAppLogo}
                  alt="Bible App Icon"
                  height={32}
                  src={bibleAppIcon}
                />

                <Box
                  alignItems="flex-start"
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                >
                  <YouVersionLogo
                    className={classes.yvLogo}
                    height="9.5px"
                    title="YouVersion Brand Logo"
                    width="61.36px"
                  />
                  <Typography
                    className={classes.title}
                    color="inherit"
                    noWrap={true}
                    variant="h3"
                  >
                    Blockbuster
                  </Typography>
                </Box>
              </Box>
            </Button>
            {isStaging ? (
              <Hidden smDown={false}>
                <Typography className={classes.staging} variant="h4">
                  STAGING
                </Typography>
              </Hidden>
            ) : null}
          </Box>
          <Box
            className={`${classes.sectionDesktop} ${classes.navSection}`}
            display="flex"
            justifyContent="center"
            alignItems="center"
            aria-label="navbar tab icons"
          >
            {renderDesktopNavIcons}
          </Box>
          <Box
            display="flex"
            className={classes.navSection}
            justifyContent="flex-end"
          >
            <Box
              className={classes.sectionDesktop}
              display="flex"
              flexDirection="row"
              alignItems="center"
              aria-label="desktop navbar buttons"
            >
              <Box ml={1} mr={1}>
                <AddContentDropDown />
              </Box>
              <Divider
                className={classes.verticalDivider}
                orientation="vertical"
                flexItem={true}
              />
              <IconButton
                aria-owns={isMenuOpen ? 'material-appbar' : null}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                color="inherit"
              >
                {avatar}
              </IconButton>
            </Box>
            <Box
              className={classes.sectionMobile}
              display="flex"
              flexDirection="row"
              alignItems="center"
              aria-label="mobile navbar buttons"
            >
              <AddContentDropDown />
              <IconButton
                aria-haspopup="true"
                aria-label="more options"
                onClick={handleMobileMenuOpen}
                color="inherit"
              >
                <MoreIcon />
              </IconButton>
            </Box>
          </Box>
        </Box>
      </Toolbar>
      {renderMenu}
      {renderMobileMenu}
    </AppBar>
  )
}

YVAppBar.propTypes = {
  signOut: PropTypes.func.isRequired,
  user: PropTypes.any.isRequired,
}

export default YVAppBar
