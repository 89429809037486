import React from 'react'
import PropTypes from 'prop-types'
import { Box, makeStyles, Tooltip } from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check'
import CircularProgress from '@material-ui/core/CircularProgress'

const useStyles = makeStyles((theme) => ({
  dot: {
    verticalAlign: 'middle',
    height: 16,
    width: 16,
    display: 'inline-block',
    borderRadius: '100%',
  },
  unready: {
    backgroundColor: theme.palette.warning.main,
  },
  unknown_status: {
    backgroundColor: theme.palette.warning.main,
  },
  ready: {
    backgroundColor: theme.palette.success.main,
  },
  failed: {
    backgroundColor: theme.palette.error.light,
  },
  published: {
    backgroundColor: theme.palette.grey[500],
  },
  rejected: {
    backgroundColor: theme.palette.error.light,
  },
  resolved: {
    backgroundColor: theme.palette.grey[500],
  },
  idle: {
    backgroundColor: theme.palette.grey[500],
  },
}))

function getStatusComponent(status, classes) {
  switch (status) {
    case 'success':
    case 'published':
      return <CheckIcon fontSize="small" />
    case 'unready':
      return <CircularProgress size="1rem" />
    default:
      return (
        <Box
          alignSelf="center"
          className={`${classes.dot} ${classes[status]}`}
        />
      )
  }
}

/**
 * # Video Status
 *
 * Component to indicate the status of a video.
 *
 * @param {Object} params
 * @param {string} params.status - Status of the video.
 */
function VideoStatus({ status }) {
  const classes = useStyles()

  if (!status) return null

  const statusComponent = getStatusComponent(status, classes)
  const title = status === 'unready' ? 'processing...' : status.toUpperCase()

  return (
    <Tooltip title={title} placement="top">
      {statusComponent}
    </Tooltip>
  )
}

VideoStatus.propTypes = {
  status: PropTypes.string,
}

VideoStatus.defaultProps = {
  status: undefined,
}

export default VideoStatus
