/* eslint-disable no-underscore-dangle */
import { TuposModel } from '@youversion/tupos-base'
import setString from '@youversion/tupos-base/dist/setters/string'
import api4 from '@youversion/tupos-base/dist/fetchers/api4'
import ImageUrls from './image_urls'
import VideoUrls from './video_urls'

let configResult

/** *
 * Configuration model.
 *
 * @augments TuposModel
 */
class Configuration extends TuposModel {
  constructor(json) {
    super(json)
    if (!json || typeof json !== 'object') return
    this.videoUrls = json.video_urls
    this.shareUrl = json.share_url
    this.imageUrls = json.image_urls
  }

  /** Convert Configuration to simple object */
  toObject() {
    return {
      video_urls: this.videoUrls.toObject(),
      share_url: this.shareUrl,
      image_urls: this.imageUrls.toObject(),
    }
  }

  /** Fetch Configuration Resource */
  static async get() {
    let result
    if (typeof configResult === 'undefined') {
      configResult = TuposModel.get(
        api4({
          endpoint: 'movies',
          method: 'configuration',
          version: '4.0',
          auth: true,
          parseJson: true,
        }),
      )
      result = await configResult
      if (typeof configResult !== 'object') throw new Error()
      if (result && result.message)
        throw new Error(`Configuration: ${result.message}`)
      configResult = result
    } else if (typeof configResult.then === 'function') {
      result = await configResult
    } else {
      result = configResult
    }
    return new Configuration(result)
  }

  /** @type {VideoUrls} */
  get videoUrls() {
    return this._videoUrls
  }

  set videoUrls(videoUrls) {
    this._videoUrls = new VideoUrls(videoUrls, 'videoUrls')
  }

  /** @type {string} */
  get shareUrl() {
    return this._shareUrl
  }

  set shareUrl(shareUrl) {
    this._shareUrl = setString(shareUrl, 'shareUrl')
  }

  /** @type {ImageUrls} */
  get imageUrls() {
    return this._imageUrls
  }

  set imageUrls(imageUrls) {
    this._imageUrls = new ImageUrls(imageUrls, 'imageUrls')
  }
}

export default Configuration
