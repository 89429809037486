/* eslint-disable */
import * as React from 'react'
import { TableCell, TableRow } from '@material-ui/core'
import { statusTypes } from '@youversion/utils'
import PropTypes from 'prop-types'
import { Link, useLocation } from 'react-router-dom'
import VideoStatus from 'components/ui/video-status'
import * as Sentry from '@sentry/react'
import Publisher from '../../../../tupos/models/publisher'
import urlReplacer from '../../../../utils/url-replacer'
import Configuration from '../../../../tupos/models/configuration'
import getParamsFromProps from '../../../../utils/get-params-from-props'

/**
 * Collection Detail Row.
 *
 * @param {object} params - Params for component.
 * @param {object} params.data - Video object.
 * @param {string} params.lang - Current language.
 * @returns {React.ReactElement} - Returns jsx.
 */
function CollectionDetailRow({ data, lang }) {
  const location = useLocation()
  const [loadingStatus, setLoadingStatus] = React.useState(statusTypes.IDLE)
  const [publisher, setPublisher] = React.useState(null)
  const [config, setConfig] = React.useState(null)

  async function loadData() {
    setLoadingStatus(statusTypes.PENDING)
    const language = getParamsFromProps({ location }, 'lang', 'en')
    try {
      const publisherResponse = await Publisher.get(
        data.publisherId,
        false,
        language,
      )
      const configResponse = await Configuration.get()

      setPublisher(publisherResponse)
      setConfig(configResponse)

      setLoadingStatus(statusTypes.RESOLVED)
    } catch (error) {
      setLoadingStatus(statusTypes.REJECTED)
      Sentry.captureException(error)
    }
  }

  const imageUrl = config?.imageUrls.video
  const videoImageUrl = urlReplacer(imageUrl, {
    height: '45',
    id: data.id,
    language: lang,
    width: '87',
  })

  React.useEffect(() => {
    loadData()
  }, [])

  const isReady = loadingStatus === statusTypes.RESOLVED

  if (!isReady) return null

  return (
    <TableRow component="div">
      <TableCell component="div">
        <img alt={data.title} src={videoImageUrl} />
      </TableCell>
      <TableCell align="left" component="div">
        <Link
          style={{
            color: 'rgba(0, 0, 0, 0.87)',
          }}
          to={`/videos/${data.id}?lang=${lang}`}
        >
          {data.title}
        </Link>
      </TableCell>
      <TableCell align="right" component="div">
        <VideoStatus status={data.status} />
      </TableCell>
      {publisher ? (
        <TableCell align="right" component="div">
          {publisher.name}
        </TableCell>
      ) : null}
    </TableRow>
  )
}

CollectionDetailRow.propTypes = {
  data: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.shape({}),
      PropTypes.arrayOf(PropTypes.string),
    ]),
  ).isRequired,
  lang: PropTypes.string.isRequired,
}

export default CollectionDetailRow
