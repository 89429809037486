/**
 * @module getParamsFromRouterHook
 */

/* eslint-disable no-console */

/**
 * GetParamsFromRouterHook returns the value of the paramName in the location object.
 *
 * @alias module:getParamsFromRouterHook
 *
 * @param {object} params - The params object.
 * @param {string} params.defaultValue - The default value if the paramName is false in the location object.
 * @param {object} params.location - The location object from the useLocation react-router-dom hook.
 * @param {string} params.paramName - The desired key from the location object.
 *
 * @throws {Error} - Throws an error if location is not an object.
 * @throws {Error} - Throws an error if paramName is not a string.
 * @throws {Error} - Throws an error if defaultValue is not a string.
 *
 * @returns {string} - The value of paramName in the location object.
 *
 * @example
 * const location = useLocation()
 * getParamsFromRouterHook({location, 'lang', 'en'})
 */
export default function getParamsFromRouterHook({
  defaultValue,
  location,
  paramName,
}) {
  if (typeof location !== 'object' || location === null) {
    throw new Error('Param location must be an object')
  }
  if (typeof paramName !== 'string') {
    throw new Error('Param paramName must be a string')
  }
  if (typeof defaultValue !== 'string') {
    throw new Error('Param defaultValue must be a string')
  }

  const { search } = location
  const params = new URLSearchParams(search)
  return params.get(paramName) || defaultValue
}
