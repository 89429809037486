/* eslint-disable no-underscore-dangle */
import setString from '@youversion/tupos-base/dist/setters/string'

/** *
 * Configuration model.
 */
class ImageUrls {
  constructor(json) {
    if (!json || typeof json !== 'object') return
    this.publisherBanner = json.publisher_banner
    this.collection = json.collection
    this.video = json.video
    this.publisherLogo = json.publisher_logo
  }

  /** Convert ImageUrls to simple object */
  toObject() {
    return {
      publisher_banner: this.publisherBanner,
      collection: this.collection,
      video: this.video,
      publisher_logo: this.publisherLogo,
    }
  }

  /** @type {string} */
  get publisherBanner() {
    return this._publisherBanner
  }

  set publisherBanner(publisherBanner) {
    this._publisherBanner = setString(publisherBanner, 'publisherBanner')
  }

  /** @type {string} */
  get collection() {
    return this._collection
  }

  set collection(collection) {
    this._collection = setString(collection, 'collection')
  }

  /** @type {string} */
  get video() {
    return this._video
  }

  set video(video) {
    this._video = setString(video, 'video')
  }

  /** @type {string} */
  get publisherLogo() {
    return this._publisherLogo
  }

  set publisherLogo(publisherLogo) {
    this._publisherLogo = setString(publisherLogo, 'publisherLogo')
  }
}

export default ImageUrls
