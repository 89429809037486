import React from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Chip,
  Collapse,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
  makeStyles,
} from '@material-ui/core'
import LinkIcon from '@material-ui/icons/Link'
import { getReferencesTitle } from '@youversion/utils'
import BibleReferenceTextField from 'components/ui/bible-reference-selector'
import { bibleESVBooksData } from 'utils/bible-book-data'
import { validateUrl } from '../../../../utils/get-video-details'
import Configuration from '../../../../tupos/models/configuration'
import urlReplacer from '../../../../utils/url-replacer'
import LazyImage from '../../../ui/lazy-image'
import { fieldVariants } from '../../../../utils/constants'
import { dropboxSetDownloadable } from '../../../../utils/query-string'

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
    width: '100%',
  },
  selectPublish: {
    marginRight: theme.spacing(2),
  },
  w100: {
    width: '100%',
  },
  mw100: {
    maxWidth: '100%',
  },
  chip: {
    width: 'fit-content',
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  videoPreview: {
    width: '100%',
    '&:focus': {
      outline: 'none',
    },
  },
  videoWrapper: {
    width: '100%',
    flexGrow: 1,
  },
  iconLink: {
    verticalAlign: 'bottom',
    marginRight: theme.spacing(0.5),
  },
  chipBibleReference: {
    marginRight: theme.spacing(0.5),
  },
  bibleChipContainer: {
    overflowX: 'auto',
  },
}))

const validateField = (key, value, onValidate, variant) => {
  const requiredFieldMessages = {
    publisher_id: 'A Publisher is required.',
    title: 'A title is required.',
    orientation: 'Video orientation is required.',
    type: 'Video type is required.',
    source_url: 'A valid video URL is required.',
    language: 'Video language is required.',
  }

  // validate each field
  const hasError = !value
  if (key in requiredFieldMessages) {
    // validate url
    if (key.includes('url')) {
      if (variant === fieldVariants.UPDATE) return // Update URLS come from config
      const isValid = validateUrl(value)
      onValidate(!isValid, key, requiredFieldMessages[key])
    } else {
      onValidate(hasError, key, requiredFieldMessages[key])
    }
  }
}

// This is a fix that allows videos to work on Safari.
const isBrowserSafari = /apple/i.test(navigator.vendor)

const getReferenceWithTitle = (reference) => ({
  title: getReferencesTitle({
    bookList: bibleESVBooksData,
    usfmList: reference,
  }).title,
  usfm: reference,
})

/**
 * # Video Language Fields.
 *
 * Component that contains all related fields to a video object necessary for
 * read, update, and creation of videos.
 *
 * @param {object} props
 * @param {object} props.video - Object of Video class instance.
 * @param {string} props.variant - Determines which variant of the VideoLangaugeFields to display. ('create', 'add_language', 'update').
 * @param {Function} props.onInputChange - Function to handle input changes.
 * @param {Function} props.onValidate - Function to handle validation of field values.
 */
function VideoLanguageFields({
  variant,
  video,
  onInputChange,
  onValidate,
  showReferences,
}) {
  const videoRef = React.useRef(null)

  const [config, setConfig] = React.useState()
  const [videoUrlHls, setVideoUrlHls] = React.useState('')
  const [videoUrlWebm, setVideoUrlWebm] = React.useState('')
  const [thumbnailImageUrl, setThumbnailImageUrl] = React.useState('')

  const [videoError, setVideoError] = React.useState('')
  const [thumnailUrlError, setThumbnailUrlError] = React.useState('')
  const [videoCanPlay, setVideoCanPlay] = React.useState(false)
  const [videoIsLoading, setVideoIsLoading] = React.useState(false)

  const readableBibleReferences = React.useMemo(
    () =>
      video?.references?.map((reference) => getReferenceWithTitle(reference)) ||
      [],
    [video.references],
  )

  const classes = useStyles()

  const debounceTimer = React.useRef(false)

  const { CREATE, UPDATE, ADD_LANGUAGE } = fieldVariants

  // handle url input, adding dropbox downloadable params if needed
  function handleUrlInputChange(event) {
    const updatedEvent = event
    const {
      target: { value, name },
    } = event
    const isUrlValid = validateUrl(value)

    if (name === 'source_url') {
      // clear video orientation
      const orientationEvent = { target: { value: '', name: 'orientation' } }
      onInputChange(orientationEvent)
      setVideoError(
        updatedEvent.target.value === '' || isUrlValid ? '' : 'Url is invalid.',
      )
    } else if (name === 'thumbnail_source_url') {
      setThumbnailUrlError(
        updatedEvent.target.value === '' || isUrlValid ? '' : 'Url is invalid.',
      )
    }

    updatedEvent.target.value = dropboxSetDownloadable(value)
    onInputChange(updatedEvent)
  }

  // Validates all form fields on any field change, awaiting a
  // debounce timer to encourage only validating when needed
  React.useEffect(() => {
    // clear previous timeout if needed
    clearTimeout(debounceTimer.current)

    // save the module order after 1 seconds
    debounceTimer.current = setTimeout(() => {
      Object.keys(video).forEach((key) => {
        validateField(key, video[key], onValidate, variant)
      })
    }, 1000)
  }, [video, onValidate, variant])

  // Get the video urls configuration if variant is UPDATE
  React.useEffect(() => {
    const getConfig = async () => {
      const configResponse = await Configuration.get()
      setConfig(configResponse)
    }

    if (variant === UPDATE) {
      getConfig()
    }
  }, [variant, UPDATE])

  // Get video & thumbnail urls. Only for existing videos.
  React.useEffect(() => {
    if (config && video.id && video.language) {
      const hls = urlReplacer(config.videoUrls.hls, {
        id: video.id,
        language: video.language,
      })
      const webm = urlReplacer(config.videoUrls.webm, {
        id: video.id,
        language: video.language,
      })

      setVideoUrlHls(hls)
      setVideoUrlWebm(webm)

      const thumbnailUrl = urlReplacer(config.imageUrls.video, {
        width: '385',
        height: '216',
        id: video.id,
        language: video.language,
      })
      setThumbnailImageUrl(thumbnailUrl)
    }
  }, [config, video.id, video.language])

  function handleAddReference(reference) {
    const newReferences = []

    // check if is verse or chapter
    const firstOfUsfm = reference.split('+')[0]
    const isChapterRegex = /^[1-3]?[A-z]{2,3}.\d+$/
    if (reference.includes('+') && isChapterRegex.test(firstOfUsfm)) {
      // chapters do split on + delimiters
      newReferences.push(...reference.split('+'))
    } else {
      // verses don't split on + delimiters
      newReferences.push(reference)
    }
    const addReferenceEvent = {
      target: {
        value: [...new Set([...newReferences, ...video?.references])],
        name: 'references',
      },
    }
    onInputChange(addReferenceEvent)
  }

  function handleDeleteReference(index) {
    const updatedReferences = [...video?.references]
    updatedReferences.splice(index, 1)
    const deleteReferenceEvent = {
      target: {
        value: updatedReferences,
        name: 'references',
      },
    }
    onInputChange(deleteReferenceEvent)
  }

  const videoTypeSelectInput = (
    <Tooltip
      title={
        (variant === UPDATE || variant === ADD_LANGUAGE) &&
        'Video type is shown for reference and was set when the initial video was created.'
      }
      disableHoverListener={variant === CREATE}
      placement="top"
    >
      <FormControl
        variant="outlined"
        className={classes.formControl}
        disabled={Boolean(variant === UPDATE || variant === ADD_LANGUAGE)}
      >
        <InputLabel id="typeLabel" className={classes.formControlLabel}>
          Video Type
        </InputLabel>
        <Select
          labelId="typeLabel"
          id="type"
          name="type"
          value={video.type}
          onChange={onInputChange}
          displayEmpty={true}
        >
          <MenuItem value="influencer_selfie">Verse of the Day Story</MenuItem>
          <MenuItem value="reenactment">Reenactment Video</MenuItem>
          <MenuItem value="artistic_explanation">
            Artistic Explanation Video
          </MenuItem>
          <MenuItem value="worship">Worship</MenuItem>
          <Divider />
          <MenuItem value="tween_head">KBE / Bible Loop</MenuItem>
          <MenuItem value="tween_song">KBE Worship</MenuItem>
        </Select>
      </FormControl>
    </Tooltip>
  )

  if (variant === UPDATE && !config) return null

  const hasVideoUrlError = Boolean(
    videoError && (variant === ADD_LANGUAGE || variant === CREATE),
  )

  const hasThumbnailError = Boolean(
    thumnailUrlError && (variant === ADD_LANGUAGE || variant === CREATE),
  )

  return (
    <Grid container={true} spacing={4} direction="row">
      <Grid
        item={true}
        container={true}
        alignItems="flex-start"
        justify="space-between"
      >
        <Grid item={true} xs={12} sm={6} container={true} spacing={2}>
          <Grid container={true} item={true} spacing={2}>
            <Grid item={true} xs={12}>
              <TextField
                className={`${classes.formControl} ${classes.w100}`}
                variant="outlined"
                value={video.title}
                id="title"
                label="Title"
                name="title"
                onChange={onInputChange}
              />
            </Grid>
            <Grid item={true} xs={12}>
              <TextField
                className={`${classes.formControl} ${classes.w100}`}
                variant="outlined"
                value={video.tracking_id}
                id="tracking_id"
                label="Tracking Id"
                name="tracking_id"
                onChange={onInputChange}
              />
            </Grid>

            <Grid item={true} xs={12}>
              <TextField
                className={`${classes.formControl} ${classes.w100}`}
                variant="outlined"
                value={video.description}
                id="description"
                label="Description"
                name="description"
                multiline={true}
                rows={5}
                onChange={onInputChange}
              />
            </Grid>
          </Grid>
          <Grid item={true} container={true} spacing={2}>
            <Grid item={true}>
              <Typography variant="h3">Thumbnail Preview</Typography>
              {variant === ADD_LANGUAGE || variant === CREATE ? (
                <Typography
                  variant="body2"
                  color="textSecondary"
                  display="block"
                >
                  Add a video thumbnail now while you can. Ability to update a
                  thumbnail is coming soon!
                </Typography>
              ) : null}
              <Collapse
                in={validateUrl(
                  thumbnailImageUrl || video.thumbnail_source_url,
                )}
              >
                <Box mt={1}>
                  <LazyImage
                    src={
                      thumbnailImageUrl ||
                      dropboxSetDownloadable(video.thumbnail_source_url)
                    }
                    alt="thumbnail"
                    height={216}
                    width={385}
                    key={thumbnailImageUrl || video.thumbnail_source_url}
                    className={classes.mw100}
                  />
                </Box>
              </Collapse>
            </Grid>
            <Grid item={true} xs={12}>
              {variant === ADD_LANGUAGE || variant === CREATE ? (
                <TextField
                  className={`${classes.formControl} ${classes.w100}`}
                  variant="outlined"
                  value={video.thumbnail_source_url}
                  label="Thumbnail URL"
                  id="thumbnail_source_url"
                  name="thumbnail_source_url"
                  type="text"
                  onChange={handleUrlInputChange}
                  helperText={hasThumbnailError ? thumnailUrlError : ''}
                  error={hasThumbnailError}
                />
              ) : null}
              {variant === UPDATE ? (
                <Typography
                  component="a"
                  target="_blank"
                  variant="body2"
                  color="textPrimary"
                  display="block"
                  noWrap={true}
                  href={thumbnailImageUrl}
                >
                  <LinkIcon className={classes.iconLink} fontSize="small" />
                  Visit Thumbnail URL
                </Typography>
              ) : null}
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item={true}
          xs={12}
          sm={6}
          container={true}
          spacing={2}
          justify="flex-start"
          direction="column"
        >
          {video.status && variant === UPDATE ? (
            <Grid item={true} container={true} direction="row">
              <Typography variant="h3">Video Preview</Typography>
              {videoIsLoading ? (
                <LinearProgress className={classes.w100} />
              ) : null}
              <Collapse
                in={Boolean(
                  videoCanPlay && !videoError && videoUrlHls && videoUrlWebm,
                )}
                className={classes.videoWrapper}
              >
                {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                <video
                  ref={videoRef}
                  className={classes.videoPreview}
                  key={video.id}
                  controls={true}
                  onCanPlay={() => {
                    setVideoCanPlay(true)
                    setVideoIsLoading(false)
                  }}
                  src={isBrowserSafari ? videoUrlHls : videoUrlWebm}
                  onError={(e) => setVideoError(e)}
                >
                  <source src={videoUrlHls} key="hls" type="video/mp4" />
                  <source src={videoUrlWebm} key="webm" type="video/webm" />
                </video>
              </Collapse>
            </Grid>
          ) : null}
          <Grid item={true} className={classes.w100}>
            {variant === ADD_LANGUAGE || variant === CREATE ? (
              <TextField
                className={`${classes.formControl} ${classes.w100}`}
                variant="outlined"
                value={video.source_url}
                label="Video URL - Must be a link to a video file, not a web page."
                name="source_url"
                id="video_source_url"
                type="text"
                onChange={handleUrlInputChange}
                helperText={hasVideoUrlError ? videoError : ''}
                error={hasVideoUrlError}
              />
            ) : null}
            {variant === UPDATE ? (
              <Typography
                component="a"
                target="_blank"
                variant="body2"
                color="textPrimary"
                noWrap={true}
                display="block"
                href={videoUrlWebm}
              >
                <LinkIcon className={classes.iconLink} fontSize="small" />
                Visit Video URL
              </Typography>
            ) : null}
          </Grid>
          <Grid item={true} className={classes.w100}>
            {videoTypeSelectInput}
          </Grid>
          <Grid item={true} className={classes.w100}>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel
                id="orientationLabel"
                className={classes.formControlLabel}
              >
                Video Orientation
              </InputLabel>
              <Select
                labelId="orientationLabel"
                id="orientation"
                name="orientation"
                value={video.orientation}
                onChange={onInputChange}
                displayEmpty={true}
              >
                <MenuItem value="landscape">Landscape</MenuItem>
                <MenuItem value="portrait">Portrait</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          {showReferences ? (
            <Grid item={true} className={classes.w100}>
              <BibleReferenceTextField
                className={classes.w100}
                variant="outlined"
                onEnter={handleAddReference}
                autoFocus={false}
              />
              <Box className={classes.bibleChipContainer} display="flex" mt={2}>
                {readableBibleReferences.map((reference, index) => (
                  <Chip
                    variant="outlined"
                    className={classes.chipBibleReference}
                    key={reference.title}
                    label={reference.title}
                    onDelete={() => handleDeleteReference(index)}
                  />
                ))}
              </Box>
            </Grid>
          ) : null}
        </Grid>
      </Grid>
    </Grid>
  )
}

VideoLanguageFields.propTypes = {
  variant: PropTypes.string.isRequired,
  video: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
      PropTypes.shape({}),
      PropTypes.number,
      PropTypes.arrayOf(PropTypes.string),
    ]),
  ),
  onInputChange: PropTypes.func.isRequired,
  onValidate: PropTypes.func.isRequired,
  showReferences: PropTypes.bool,
}

VideoLanguageFields.defaultProps = {
  video: undefined,
  showReferences: false,
}

export default VideoLanguageFields
