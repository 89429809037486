import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { TextField, InputAdornment, IconButton } from '@material-ui/core'
import CancelIcon from '@material-ui/icons/Cancel'
import PropTypes from 'prop-types'

const useStyles = makeStyles(() => ({
  root: {
    '& > *': {
      width: 200,
    },
  },
}))

const SearchBar = (props) => {
  const { onSearchEnter, onInputChange, onClear, query, fieldLabel } = props
  const classes = useStyles()

  return (
    <form
      className={classes.root}
      method="get"
      action=""
      onSubmit={onSearchEnter}
    >
      <TextField
        id="search-field"
        value={query}
        label={fieldLabel}
        onChange={onInputChange}
        InputProps={
          query
            ? {
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton aria-label="clear search" onClick={onClear}>
                      <CancelIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }
            : null
        }
      />
    </form>
  )
}

SearchBar.propTypes = {
  onSearchEnter: PropTypes.func.isRequired,
  onInputChange: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  fieldLabel: PropTypes.string,
  query: PropTypes.string,
}

SearchBar.defaultProps = {
  fieldLabel: 'Search',
  query: '',
}

export default SearchBar
