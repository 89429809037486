/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { Box, makeStyles } from '@material-ui/core'
import { Alerts } from '../../ui/alerts'

const useStyles = makeStyles(() => ({
  alertBar: {
    position: 'fixed',
    left: 0,
    top: '64px',
    display: 'block',
    width: '100%',
    zIndex: '9999',
  },
}))

export function AlertBar(props) {
  const classes = useStyles()

  return (
    <Box className={classes.alertBar}>
      <Alerts {...props} />
    </Box>
  )
}

export * from '../../ui/alerts'
