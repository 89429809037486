/* eslint-disable no-param-reassign */

import splitOnFirst from './split-on-first'

const decodeComponent = require('decode-uri-component')

const strictUriEncode = (str) =>
  encodeURIComponent(str).replace(
    /[!'()*]/g,
    (x) => `%${x.charCodeAt(0).toString(16).toUpperCase()}`,
  )

function encode(value, options) {
  if (options.encode) {
    return options.strict ? strictUriEncode(value) : encodeURIComponent(value)
  }
  return value
}

function encoderForArrayFormat(options) {
  switch (options.arrayFormat) {
    case 'index':
      return (key) => (result, value) => {
        const index = result.length
        if (value === undefined) {
          return result
        }

        if (value === null) {
          return [...result, [encode(key, options), '[', index, ']'].join('')]
        }

        return [
          ...result,
          [
            encode(key, options),
            '[',
            encode(index, options),
            ']=',
            encode(value, options),
          ].join(''),
        ]
      }

    case 'bracket':
      return (key) => (result, value) => {
        if (value === undefined) {
          return result
        }

        if (value === null) {
          return [...result, [encode(key, options), '[]'].join('')]
        }

        return [
          ...result,
          [encode(key, options), '[]=', encode(value, options)].join(''),
        ]
      }

    case 'comma':
      return (key) => (result, value, index) => {
        if (!value) {
          return result
        }

        if (index === 0) {
          return [[encode(key, options), '=', encode(value, options)].join('')]
        }

        return [[result, encode(value, options)].join(',')]
      }

    default:
      return (key) => (result, value) => {
        if (value === undefined) {
          return result
        }

        if (value === null) {
          return [...result, encode(key, options)]
        }

        return [
          ...result,
          [encode(key, options), '=', encode(value, options)].join(''),
        ]
      }
  }
}

function parserForArrayFormat(options) {
  let result

  switch (options.arrayFormat) {
    case 'index':
      return (key, value, accumulator) => {
        result = /\[(\d*)\]$/.exec(key)

        key = key.replace(/\[\d*\]$/, '')

        if (!result) {
          accumulator[key] = value
          return
        }

        if (accumulator[key] === undefined) {
          accumulator[key] = {}
        }

        accumulator[key][result[1]] = value
      }

    case 'bracket':
      return (key, value, accumulator) => {
        result = /(\[\])$/.exec(key)
        key = key.replace(/\[\]$/, '')

        if (!result) {
          accumulator[key] = value
          return
        }

        if (accumulator[key] === undefined) {
          accumulator[key] = [value]
          return
        }

        accumulator[key] = [].concat(accumulator[key], value)
      }

    case 'comma':
      return (key, value, accumulator) => {
        const isArray =
          typeof value === 'string' && value.split('').indexOf(',') > -1
        const newValue = isArray ? value.split(',') : value
        accumulator[key] = newValue
      }

    default:
      return (key, value, accumulator) => {
        if (accumulator[key] === undefined) {
          accumulator[key] = value
          return
        }

        accumulator[key] = [].concat(accumulator[key], value)
      }
  }
}

function decode(value, options) {
  if (options.decode) {
    return decodeComponent(value)
  }

  return value
}

function keysSorter(input) {
  if (Array.isArray(input)) {
    return input.sort()
  }

  if (typeof input === 'object') {
    return keysSorter(Object.keys(input))
      .sort((a, b) => Number(a) - Number(b))
      .map((key) => input[key])
  }

  return input
}

function extract(input) {
  const queryStart = input.indexOf('?')
  if (queryStart === -1) {
    return ''
  }

  return input.slice(queryStart + 1)
}

function parse(input, options) {
  options = { decode: true, arrayFormat: 'none', ...options }

  const formatter = parserForArrayFormat(options)

  // Create an object with no prototype
  const ret = Object.create(null)

  if (typeof input !== 'string') {
    return ret
  }

  input = input.trim().replace(/^[?#&]/, '')

  if (!input) {
    return ret
  }

  // eslint-disable-next-line no-restricted-syntax
  for (const param of input.split('&')) {
    // eslint-disable-next-line prefer-const
    let [key, value] = splitOnFirst(param.replace(/\+/g, ' '), '=')

    // Missing `=` should be `null`:
    // http://w3.org/TR/2012/WD-url-20120524/#collect-url-parameters
    value = value === undefined ? null : decode(value, options)

    formatter(decode(key, options), value, ret)
  }

  return Object.keys(ret)
    .sort()
    .reduce((result, key) => {
      const value = ret[key]
      if (
        Boolean(value) &&
        typeof value === 'object' &&
        !Array.isArray(value)
      ) {
        // Sort object keys, not values
        result[key] = keysSorter(value)
      } else {
        result[key] = value
      }

      return result
    }, Object.create(null))
}

// exports.extract = extract
// exports.parse = parse

const stringify = (object, options) => {
  if (!object) {
    return ''
  }

  options = { encode: true, strict: true, arrayFormat: 'none', ...options }

  const formatter = encoderForArrayFormat(options)
  const keys = Object.keys(object)

  if (options.sort !== false) {
    keys.sort(options.sort)
  }

  return keys
    .map((key) => {
      const value = object[key]

      if (value === undefined) {
        return ''
      }

      if (value === null) {
        return encode(key, options)
      }

      if (Array.isArray(value)) {
        return value.reduce(formatter(key), []).join('&')
      }

      return `${encode(key, options)}=${encode(value, options)}`
    })
    .filter((x) => x.length > 0)
    .join('&')
}

const parseUrl = (input, options) => {
  const hashStart = input.indexOf('#')
  if (hashStart !== -1) {
    input = input.slice(0, hashStart)
  }

  return {
    url: input.split('?')[0] || '',
    query: parse(extract(input), options),
  }
}

const dropboxSetDownloadable = (url) => {
  if (/dropbox\.com/.test(url)) {
    const urlObject = new URL(url)
    const searchParams = new URLSearchParams(urlObject.search)
    searchParams.set('dl', '1')
    urlObject.search = searchParams.toString()
    return urlObject.toString()
  }
  return url
}

export { extract, parse, stringify, parseUrl, dropboxSetDownloadable }
