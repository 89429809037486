/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import PropTypes from 'prop-types'
import { Fade, Box, Tooltip } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import ErrorIcon from '@material-ui/icons/Error'
import useAsyncImage from '../../../hooks/use-async-image'

/**
 * Lazy load an image with Material UI Skeleton.
 * It requires both width and height to correctly generate a placeholder size before the image has loaded.
 *
 * @example
 * <LazyImage src="https://example.com/image.jpg" width={150} height={150} alt="Example image" fallback={<img src="https://example.com/error.jpg" alt="Error image" />} />
 *
 * @param {Object} props
 * @param {String} props.src The image source URL
 * @param {String} props.alt The image alt tag
 * @param {Number|String} props.width The image width (i.e. width={150} or width="100%")
 * @param {Number|String} props.height The image height (i.e. height={150} or height="100%")
 * @param {React.Element|Node} props.loadingComponent A component used instead of the default loading skeleton.
 * @param {React.Element|Node} [props.fallback] The fallback element for errors. Can be React element or node.
 */
export default function LazyImage({
  src,
  alt,
  width,
  height,
  fallback,
  loadingComponent,
  className,
  ...props
}) {
  const { pending, ready, error } = useAsyncImage(src)

  if (pending) {
    return (
      loadingComponent || (
        <Skeleton
          variant="rect"
          height={height}
          width={width}
          className={className}
        />
      )
    )
  }

  if (error) {
    return (
      <>
        {fallback || (
          <Fade in={true}>
            <Box
              border={1}
              borderColor="grey.300"
              width={width}
              height={height}
              display="flex"
              justifyContent="center"
              alignItems="center"
              className={className}
            >
              <Tooltip title="Failed to load image">
                <ErrorIcon width="50%" height="50%" color="action" />
              </Tooltip>
            </Box>
          </Fade>
        )}
      </>
    )
  }

  if (ready) {
    return (
      <Fade in={true}>
        <img
          src={src}
          alt={alt}
          width={width}
          height={height}
          className={className}
          {...props}
        />
      </Fade>
    )
  }

  return null
}

LazyImage.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  fallback: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
  loadingComponent: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
  className: PropTypes.string,
}

LazyImage.defaultProps = {
  fallback: '',
  className: '',
  loadingComponent: '',
}
