import React from 'react'
import PropTypes from 'prop-types'
import { Box, Paper, makeStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles((theme) => ({
  navBar: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.grey[300],
    boxShadow: 'none',
    borderRadius: '0px',
    flexDirection: 'row',
    alignItems: 'center',
    minHeight: 64,
  },
}))

function SecondaryAppBar({ title, subtitle, actionComponent, backComponent }) {
  const classes = useStyles()

  return (
    <Paper className={classes.navBar}>
      <Box
        display="flex"
        margin={1}
        flexBasis="33.33%"
        justifyContent="flex-start"
      >
        {backComponent}
      </Box>
      <Box
        flexBasis="33.33%"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Typography variant="h3" color="textPrimary" align="center">
          {title}
        </Typography>
        {subtitle && (
          <Typography
            variant="caption"
            color="textSecondary"
            align="center"
            noWrap={true}
          >
            {subtitle}
          </Typography>
        )}
      </Box>
      <Box
        marginRight={2}
        display="flex"
        flexBasis="33.33%"
        justifyContent="flex-end"
      >
        {actionComponent}
      </Box>
    </Paper>
  )
}

SecondaryAppBar.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  actionComponent: PropTypes.node,
  backComponent: PropTypes.node,
}

SecondaryAppBar.defaultProps = {
  subtitle: '',
  backComponent: null,
  actionComponent: null,
}

export default SecondaryAppBar
