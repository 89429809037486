import { isVerseOrChapter } from '@youversion/utils'

/**
 * @typedef {Object} UsfmData
 * @property {string} usfm - USFM of the book
 * @property {string} title - Human readable name of the book
 */

/**
 * @typedef {Object} BibleBookData
 * @property {string} usfm - USFM of the book
 * @property {string} human - Human readable name of the book
 * @property {number} max_num_of_chapters - Amount of chapters in a given book
 * @property {Array} max_num_of_verses - Array containing number of verses in a given chapter. (e.g. book.max_num_of_verses[chapterNum - 1]) Remember, this is a zero-indexed array.
 */

/**
 * Takes an array of Bible objects, containing the usfm and title, and combines into a single string related Bible book chapters.
 * @param {Array<BibleBookData>} bibleData - Array of Bible Book Data objects for the entire Bible.
 * @param {Array<UsfmData>} references - An array of Bible objects that each contain a usfm and title.
 * @returns {Array<string>} An array of related chapters combined into a singular, human-readable string per chapter along with the verses.
 */
export function compressRelatedBibleReferences(references = [], bibleData) {
  // separate verses from chapters
  const verses = []
  const chaptersObject = {}

  // loop over each chapter and create an object with key is USFM and has an array of chapters
  function addToChapterObject(chapter) {
    const [chapterUsfm, chapterNum] = chapter.usfm.split('.')
    if (chaptersObject[chapterUsfm]) {
      chaptersObject[chapterUsfm].push(parseInt(chapterNum, 10))
    } else {
      chaptersObject[chapterUsfm] = [parseInt(chapterNum, 10)]
    }
  }

  references.forEach((reference) => {
    if (isVerseOrChapter(reference.usfm).isChapter) {
      addToChapterObject(reference)
      return true
    }
    if (isVerseOrChapter(reference.usfm).isVerse) {
      verses.push(reference.title)
    }
    return false
  })

  // idea from https://stackoverflow.com/a/13628257
  function addCompressedReferenceToChaptersObject(usfm) {
    // sort just in case it's not
    chaptersObject[usfm].sort((a, b) => a - b)

    let start
    let end // track start and end

    const arr = chaptersObject[usfm]

    let result = ''

    // eslint-disable-next-line prefer-destructuring
    start = arr[0]
    end = start

    for (let i = 1; i < arr.length; i += 1) {
      // as long as entries are consecutive, move end forward
      if (arr[i] === arr[i - 1] + 1) {
        end = arr[i]
      } else {
        // when no longer consecutive, add group to result
        // depending on whether start=end (single item) or not
        if (start === end) result += `${start}, `
        else if (end === start + 1) result += `${start}, ${end}, `
        else result += `${start}-${end}, `

        end = arr[i]
        start = end
      }
    }

    // handle the final group
    if (start === end) result += start
    else result += `${start}-${end}`

    chaptersObject[usfm].title = `${bibleData[usfm].human} ${result}`
  }

  // compress the book chapters together
  Object.keys(chaptersObject).forEach((usfm) => {
    addCompressedReferenceToChaptersObject(usfm)
  })

  const getCompressedChapters = () =>
    Object.keys(chaptersObject).map((chapter) => chaptersObject[chapter].title)

  return [...getCompressedChapters(), ...verses]
}
