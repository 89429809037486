/**
 * @module PinnableCollecionCarousel
 */
import React from 'react'
import PropTypes from 'prop-types'
import { Box, IconButton, makeStyles } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import DragIndicatorIcon from '@material-ui/icons/DragIndicator'
import { blue, gray, white } from '@youversion/react/styles/colors-v3'
import clsx from 'clsx'
import CollectionCarousel from './index'
import PushPinIcon from '../../../ui/icons/push-pin'

/**
 * @typedef AudioUrl
 * @property {number} bitrate - The audio bitrate.
 * @property {string} format - The audio format (e.g. "mp3").
 * @property {number} sampleRate - The audio sample rate.
 * @property {string} url - The audio file URL.
 */

/**
 * @typedef ImageUrl
 * @property {number} collection - The URL of the video's parent collection.
 * @property {string} publisherBanner - The video Publisher's banner image URL.
 * @property {number} publisherLogo - The video Publisher's logo URL.
 * @property {string} video - The video file URL.
 * @property {string} videoBlue - The video blur URL.
 */

/**
 * @typedef VideoUrl
 * @property {number} hls - The URL of the video's hls format.
 * @property {string} webm - The URL of the video's webm format.
 */

/**
 * @typedef Configuration
 * @property {Array} audioUrls - Array of AudioUrl objects.
 * @property {ImageUrl} imageUrls - The ImageUrl object.
 * @property {string} shareUrl - The share URL value.
 * @property {VideoUrl} videoUrls - The VideoUrl object.
 */

/**
 * @typedef Collections
 * @property {string} description - The description of the collection.
 * @property {number} end - The number of the day of the year representing the end day of the collection.
 * @property {number} id - The unique collection id.
 * @property {string} language - The language tag of the collection.
 * @property {number} start - The number of the day of the year representing the start day of the collection.
 * @property {string} title - The title of the collection.
 * @property {Array} videos - The array of collection videos.
 */

/**
 * @typedef SimpleCollection
 * @property {number} id - The unique collection id.
 */

const dragWidth = 48
const pinWidth = 40

const useStyles = makeStyles((theme) => ({
  carouselContainer: {
    '& > div': {
      display: 'grid',
    },
  },
  dragHandle: {
    cursor: 'move',
    userSelect: 'none',
  },
  hidden: {
    visibility: 'hidden',
  },
  icon: {
    transition: `opacity ${theme.transitions.duration.standard}ms ${theme.transitions.easing.easeInOut}`,
  },
  pushPinSelected: {
    border: 'none',
    color: blue[30],
  },
  pushPinUnselected: {
    border: `1px solid ${gray[25]}`,
    color: white,
  },
}))

/**
 * The drag handle component.
 *
 * @param {object} props - Props object.
 * @param {string} [props.className] - Optional additional className string.
 * @param {boolean} [props.disableDrag] - Optionally disable the drag IconButton.
 * @param {object} [props.dragHandleProps] - Object provided by React Beautiful DND to create a compatible drag handle.
 *
 * @returns {React.ReactElement} - The drag handle component.
 */
const DragHandle = ({ className, disableDrag, dragHandleProps }) => {
  const classes = useStyles()

  return (
    <div
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...dragHandleProps}
      className={clsx(className, 'collection-drag-handle')}
    >
      <IconButton
        aria-label="Move this collection"
        className={clsx(classes.dragHandle, disableDrag ? classes.hidden : '')}
        disabled={disableDrag}
      >
        <DragIndicatorIcon />
      </IconButton>
    </div>
  )
}

DragHandle.propTypes = {
  className: PropTypes.string,
  disableDrag: PropTypes.bool,
  dragHandleProps: PropTypes.object,
}

DragHandle.defaultProps = {
  className: '',
  disableDrag: false,
  dragHandleProps: {},
}

/**
 * Represents a pinnable carousel component for collection videos.
 *
 * @param {object} props - The component's props object.
 * @param {Collections} props.collection - The Collection data object used to populate the CollectionCarousel component.
 * @param {Configuration} props.config - The configuration data object.
 * @param {string} props.className - Optional additional className string.
 * @param {boolean} [props.disableDrag] - Optionally disable the drag IconButton.
 * @param {object} props.dragHandleProps - Object provided by React Beautiful DND to create a compatible drag handle.
 * @param {boolean} props.innerRef - Provided ref by React Beautiful DND to assist in drag and drop functionality.
 * @param {boolean} props.isDragging - Provided by React Beautiful DND to indicate the block is being dragged.
 * @param {boolean} [props.isPinned] - Boolean flag denoting whether or not the carousel is pinned.
 * @param {string} props.language - The language to use when retrieving Collection data.
 * @param {Function} [props.onPinClick] - Handler function fired when pin icon button is clicked. A SimpleCollection data object passed as the argument.
 *
 * @returns {React.ReactElement} - The PinnableCollecionCarousel Component.
 */
export default function PinnableCollecionCarousel({
  collection,
  config,
  className,
  disableDrag,
  dragHandleProps,
  innerRef,
  isDragging,
  isPinned,
  language,
  onPinClick,
  ...props
}) {
  const classes = useStyles()

  return (
    <>
      <Box
        className={clsx(className)}
        display="flex"
        position="relative"
        ref={innerRef}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      >
        <Box
          display="flex"
          left={isPinned ? -(dragWidth + pinWidth) : -pinWidth}
          position="absolute"
          top={0}
        >
          {isPinned ? (
            <Box>
              <DragHandle
                className={classes.icon}
                disableDrag={disableDrag}
                dragHandleProps={dragHandleProps}
              />
            </Box>
          ) : null}
          <Box mt={0.5}>
            <IconButton
              aria-label="Toggle the pinned status of this collection."
              onClick={() =>
                onPinClick({
                  id: collection.id,
                })
              }
            >
              <PushPinIcon
                borderColor={isPinned ? null : gray[25]}
                fillColor={isPinned ? blue[30] : white}
              />
            </IconButton>
          </Box>
        </Box>
        <Box
          className={classes.carouselContainer}
          flexGrow={1}
          mt={1.5}
          px={1.5}
          py={0}
        >
          {collection ? (
            <CollectionCarousel
              collection={collection}
              config={config}
              language={language}
            />
          ) : (
            <Skeleton
              className={className}
              height={4}
              variant="rect"
              width={4}
            />
          )}
        </Box>
      </Box>
    </>
  )
}

PinnableCollecionCarousel.propTypes = {
  className: PropTypes.string,
  collection: PropTypes.shape({
    description: PropTypes.string,
    end: PropTypes.number,
    id: PropTypes.number,
    language: PropTypes.string,
    start: PropTypes.number,
    title: PropTypes.string,
    videos: PropTypes.array,
  }).isRequired,
  config: PropTypes.shape({
    audioUrls: PropTypes.arrayOf(
      PropTypes.shape({
        bitrate: PropTypes.number,
        format: PropTypes.string,
        sampleRate: PropTypes.number,
        type: PropTypes.string,
      }),
    ),
    imageUrls: PropTypes.shape({
      collection: PropTypes.string,
      publisherBanner: PropTypes.string,
      publisherLogo: PropTypes.string,
      video: PropTypes.string,
      videoBlur: PropTypes.string,
    }),
    shareUrl: PropTypes.string,
    videoUrls: PropTypes.shape({
      hls: PropTypes.string,
      webm: PropTypes.string,
    }),
  }).isRequired,
  disableDrag: PropTypes.bool,
  dragHandleProps: PropTypes.object,
  innerRef: PropTypes.node,
  isDragging: PropTypes.bool.isRequired,
  isPinned: PropTypes.bool,
  language: PropTypes.string.isRequired,
  onPinClick: PropTypes.func,
}

PinnableCollecionCarousel.defaultProps = {
  className: null,
  disableDrag: false,
  dragHandleProps: {},
  innerRef: {},
  isPinned: false,
  onPinClick: () => {},
}
