export function validateUrl(url) {
  const expression = /https?:\/{2}(?:[-\w.]|(?:%[\da-fA-F]{2}))+/ // TODO - I found this in the set URL function, but I don't think this is correct...
  const regex = new RegExp(expression)
  return regex.test(url)
}

/**
 * Returns the dimensions of a video asynchrounsly.
 * @param {String} url Url of the video to get dimensions from.
 * @return {Promise} Promise which returns the dimensions of the video in 'width' and 'height' properties.
 *
 * Retrieved from https://stackoverflow.com/a/45355068
 * */
export async function getVideoDimensions(url) {
  return new Promise((resolve) => {
    // create the video element
    const video = document.createElement('video')

    // place a listener on it
    video.addEventListener(
      'loadedmetadata',
      // eslint-disable-next-line func-names
      function () {
        // retrieve dimensions
        const height = this.videoHeight
        const width = this.videoWidth

        // send back result
        if (height && width) {
          resolve({
            height,
            width,
          })
        } else {
          resolve({
            error:
              'Failed to load video. Only mp4 videos (without the HEVC codec) are supported.',
          })
        }
      },
      false,
    )

    video.addEventListener(
      'error',
      () => {
        resolve({ error: 'The url provided is invalid.' })
      },
      false,
    )

    // start download meta-datas
    video.src = url
  })
}

/** Very simple way to get video orientation based on video height and width */
const getVideoOrientation = ({ width, height }) =>
  height > width ? 'portrait' : 'landscape'

/** Get aspect ratio from height and width
 *
 * Retrieved from https://github.com/ryanhefner/calculate-aspect-ratio/blob/master/src/index.js
 */
function getAspectRatio({ width, height }) {
  function gcd(a, b) {
    return b ? gcd(b, a % b) : a
  }

  const divisor = gcd(width, height)

  return `${width / divisor}:${height / divisor}`
}

/**
 * # Get Video Details
 *
 * Returns the aspectRatio, width, height, and orientation of a video from the url.
 *
 * @param {string} url - video url
 * @return {Object} - Object that contains aspectRatio, width, height, and orientation of a video
 */
export async function getVideoDetails(url) {
  const emptyVideoDetails = {
    height: 0,
    width: 0,
    orientation: '',
    aspectRatio: '',
    isValid: false,
    error: 'The url provided is invalid.',
  }

  const isUrlValid = validateUrl(url)

  if (!isUrlValid) return emptyVideoDetails

  const { width, height, error } = await getVideoDimensions(url)

  if (error) {
    return {
      ...emptyVideoDetails,
      error,
    }
  }

  if (!width || !height) {
    return {
      ...emptyVideoDetails,
      error: 'Failed to load video. Only mp4 videos are supported.',
    }
  }

  const orientation = getVideoOrientation({ width, height })
  const aspectRatio = getAspectRatio({ width, height })

  return {
    height,
    width,
    orientation,
    aspectRatio,
    isValid: true,
  }
}
