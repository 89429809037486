import React from 'react'
import PropTypes from 'prop-types'
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
  Collapse,
  makeStyles,
  IconButton,
  TextField,
  Box,
  Typography,
  Paper,
  Button,
  LinearProgress,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import { FriendlyError } from 'components/ui/friendly-error-page'
import airTableSvg from 'assets/img/airtable.svg'
import VideoLanguageFields from 'components/views/video/video-language-fields'
import { fieldVariants, statusTypes, urlReplacer } from 'utils'
import imgPeopleCelebrating from 'assets/img/people-celebrating.png'
import imgPermission from 'assets/img/permission.png'
import LanguageSelector from 'components/ui/language-selector'

const useStyles = makeStyles(() => ({
  tableRow: {
    '& > *': {
      borderBottom: 'unset',
    },
  },

  tableCellOpenIcon: {
    width: '1px',
    paddingRight: 0,
  },

  videoTitle: {
    minWidth: 250,
    maxWidth: 250,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  table: {
    overflow: 'hidden',
  },
  tableContainer: {
    width: '100%',
    tableLayout: 'fixed',
  },
}))

export default function AirTableVideosTable({
  publisherId,
  publisherName,
  publisherLanguages,
  videos,
  setVideos,
  airTableIsConfigured,
  isEligibleForBulkUpload,
  onUpload,
}) {
  const [selectAll, setSelectAll] = React.useState(false)
  const [showIndeterminateCheck, setShowIndeterminateCheck] = React.useState(
    false,
  )
  const classes = useStyles()

  // Set checkboxes
  React.useEffect(() => {
    if (videos.length > 0) {
      if (videos.every((vid) => vid.is_selected.$value === true)) {
        setSelectAll(true)
        setShowIndeterminateCheck(false)
      } else if (videos.every((vid) => vid.is_selected.$value === false)) {
        setSelectAll(false)
        setShowIndeterminateCheck(false)
      } else {
        setShowIndeterminateCheck(true)
      }
    }
  }, [videos])

  const handleSelectAll = () => {
    setVideos((prevVideos) =>
      prevVideos.map((video) => {
        // if errors exist on the video, don't allow it to be selected
        if (video.$error?.length > 0) {
          return video.$update('is_selected', false)
        }

        // don't update if lang selected is not supported by the publisher
        if (!publisherLanguages?.includes(video.language.$value)) {
          return video.$update('is_selected', false)
        }

        return video.$update('is_selected', !selectAll)
      }),
    )
    setSelectAll((prevSelection) => !prevSelection)
  }

  const updateVideo = (videoId, propertyName, updatedValue) => {
    setVideos((prevVideos) =>
      prevVideos.map((vid) => {
        if (vid.$id === videoId) {
          // Reset video selection if a property is updated to fix bug where
          // user update could create an error while video was selected.
          // One cannot upload a video with errors.
          if (propertyName !== 'is_selected') {
            const updatedVideo = vid.$update(propertyName, updatedValue)
            return updatedVideo.$update('is_selected', false)
          }
          return vid.$update(propertyName, updatedValue)
        }
        return vid
      }),
    )
  }

  if (isEligibleForBulkUpload !== undefined && !isEligibleForBulkUpload)
    return (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        mt={4}
        mb={4}
      >
        <FriendlyError
          title={`${publisherName} does not have permission to bulk upload through AirTable`}
          imageSrc={imgPermission}
          imageHeight={306}
          imageWidth={258}
          imageCredit="Illustrations from https://www.ls.graphics/"
        />
        <Box mt={2} alignSelf="center">
          <Button
            color="primary"
            variant="outlined"
            href={urlReplacer(
              process.env.REACT_APP_AIRTABLE_PUBLISHER_PERMISSIONS_URL,
              {
                publisherId,
                publisherName,
              },
            )}
            target="_blank"
          >
            Enable Permission
          </Button>
        </Box>
      </Box>
    )

  if (!airTableIsConfigured) return <LinearProgress />

  const publisherAirTableFormUrl = urlReplacer(
    process.env.REACT_APP_AIRTABLE_NEW_VIDEO_URL,
    { publisherId },
  )

  if (airTableIsConfigured && !videos.length)
    return (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        mt={4}
        mb={4}
      >
        <FriendlyError
          title="Looks like there's nothing to review!"
          imageSrc={imgPeopleCelebrating}
          imageHeight={347}
          imageWidth={306}
          imageCredit="Illustrations from https://www.ls.graphics/"
        />
        <Box mt={2} alignSelf="center">
          <Button
            color="primary"
            variant="outlined"
            href={publisherAirTableFormUrl}
            target="_blank"
          >
            AirTable Video Upload Form
          </Button>
        </Box>
      </Box>
    )

  const selectedVideos = videos.filter((vid) => vid.is_selected.$value === true)

  return (
    <TableContainer component={Paper} classes={{ root: classes.table }}>
      <Box
        ml={2}
        mr={2}
        mt={2}
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
      >
        <Box display="flex" alignItems="center">
          <Typography variant="h3">
            {selectedVideos.length < 1
              ? 'Upload Videos from AirTable'
              : `${selectedVideos.length} Video${
                  selectedVideos.length > 1 ? 's' : ''
                } Selected`}
          </Typography>
          <Box ml={1}>
            <a
              href={publisherAirTableFormUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={airTableSvg} height="24px" alt="AirTable logo" />
            </a>
          </Box>
        </Box>
        <Button
          onClick={onUpload}
          variant="contained"
          color="primary"
          disabled={selectedVideos.length < 1}
          aria-label="upload one or more videos from AirTable"
        >
          Upload
        </Button>
      </Box>
      <Table
        aria-label="AirTable video submissions"
        className={classes.tableContainer}
      >
        <TableHead>
          <AirTableVideoHead
            handleSelectAll={handleSelectAll}
            selectAll={selectAll}
            showIndeterminateCheck={showIndeterminateCheck}
          />
        </TableHead>
        <TableBody>
          {videos.map((video) => (
            <AirTableVideoRow
              publisherLanguages={publisherLanguages}
              key={video.airtable_row_id.$value}
              video={video}
              updateVideo={updateVideo}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

AirTableVideosTable.propTypes = {
  publisherId: PropTypes.number,
  publisherName: PropTypes.string,
  publisherLanguages: PropTypes.arrayOf(PropTypes.string).isRequired,
  videos: PropTypes.any.isRequired,
  setVideos: PropTypes.func.isRequired,
  airTableIsConfigured: PropTypes.bool.isRequired,
  isEligibleForBulkUpload: PropTypes.bool,
  onUpload: PropTypes.func.isRequired,
}

AirTableVideosTable.defaultProps = {
  publisherId: '',
  publisherName: '',
  isEligibleForBulkUpload: undefined,
}

export function AirTableVideoRow({ video, updateVideo, publisherLanguages }) {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)

  const hasLanguageError = !publisherLanguages?.includes(video.language.$value)

  return (
    <>
      <TableRow className={classes.tableRow}>
        <TableCell className={classes.tableCellOpenIcon}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            onClick={() =>
              updateVideo(video.$id, 'is_selected', !video.is_selected.$value)
            }
            disabled={video.$error?.length > 0 || hasLanguageError}
            checked={video.is_selected.$value}
            inputProps={{ 'aria-labelledby': 1 }}
          />
        </TableCell>
        <TableCell scope="row" padding="none" className={classes.videoTitle}>
          {video.title.$value}
        </TableCell>
        <TableCell align="left">{video.comment.$value}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          {video.$error?.map((error) => (
            <Box mb={2} key={error}>
              <Alert severity="error">{error}</Alert>
            </Box>
          ))}
          {hasLanguageError ? (
            <Box mb={2}>
              <Alert severity="error">{`Select a supported language or add ${video.language.$value.toUpperCase()} language to this publisher`}</Alert>
            </Box>
          ) : null}
          {video.upload_status.$value === statusTypes.REJECTED &&
          video.upload_errors.$value ? (
            <Box mb={2}>
              <Alert severity="info">{video.upload_errors.$value}</Alert>
            </Box>
          ) : null}
          <Collapse in={open} unmountOnExit={true}>
            <Box mb={3} mt={1}>
              <Box width="100%" mb={1} display="flex">
                {video.has_parent_video.$value &&
                video.parent_video_airtable_reference.$value.length <= 0 ? (
                  <Box mr={2}>
                    <TextField
                      className={`${classes.formControl} ${classes.w100}`}
                      variant="outlined"
                      value={
                        video.parent_video_id.$value
                          ? video.parent_video_id.$value
                          : undefined
                      }
                      label="Parent Video ID"
                      id="parent_video_id"
                      name="parent_video_id"
                      type="text"
                      onChange={({ target: { value, name } }) => {
                        updateVideo(video.$id, name, value)
                      }}
                    />
                  </Box>
                ) : null}
                {publisherLanguages?.length > 0 ? (
                  <Box mb={1}>
                    <LanguageSelector
                      langs={publisherLanguages}
                      current={video.language.$value} // TODO - if current is not in langs, then put in empty.
                      changed={(value) => {
                        updateVideo(video.$id, 'language', value)
                      }}
                    />
                  </Box>
                ) : null}
              </Box>
              <VideoLanguageFields
                variant={fieldVariants.CREATE}
                video={video.$object}
                onInputChange={({ target: { value, name } }) =>
                  updateVideo(video.$id, name, value)
                }
                onValidate={() => {}}
                showReferences={!video.has_parent_video.$value}
              />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

AirTableVideoRow.propTypes = {
  // TODO figure out how to properly create proptypes for video model
  video: PropTypes.any.isRequired,
  updateVideo: PropTypes.func.isRequired,
  publisherLanguages: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export function AirTableVideoHead({
  handleSelectAll,
  selectAll,
  showIndeterminateCheck,
}) {
  return (
    <TableRow>
      <TableCell width="48px" />
      <TableCell padding="checkbox">
        <Checkbox
          color="primary"
          indeterminate={showIndeterminateCheck}
          checked={selectAll}
          onChange={handleSelectAll}
          inputProps={{ 'aria-label': 'select all rows' }}
        />
      </TableCell>
      <TableCell width="35%" padding="none">
        Video Title
      </TableCell>
      <TableCell width="65%" align="left">
        Publisher Comments
      </TableCell>
    </TableRow>
  )
}

AirTableVideoHead.propTypes = {
  handleSelectAll: PropTypes.func.isRequired,
  selectAll: PropTypes.bool.isRequired,
  showIndeterminateCheck: PropTypes.bool.isRequired,
}
