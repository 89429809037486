import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import {
  Box,
  Typography,
  makeStyles,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { urlReplacer } from 'utils'
import useAsync from 'hooks/useAsync'
import Languages from 'tupos/models/languages'
import Publisher from 'tupos/models/publisher'
import LazyImage from 'components/ui/lazy-image'

const avatarImgSize = 56
const useStyles = makeStyles((theme) => ({
  publisherLogoImg: {
    borderRadius: theme.spacing(1),
  },
  avatarlarge: {
    width: `${avatarImgSize}px`,
    height: `${avatarImgSize}px`,
    marginRight: theme.spacing(2),
    borderRadius: '50%',
  },
}))

export default function PublisherAndDetails({
  publisherId,
  numberOfVideos,
  config,
}) {
  const classes = useStyles()

  const getPublisherName = React.useCallback(async () => {
    // Get publisher languages to grab first available language
    // so we can get the publisher name. Not all publishers
    // include the 'en' language.
    const publisherLangsResponse = await Languages.get('publisher', publisherId)
    const nextAvailableLang = publisherLangsResponse.includes('en')
      ? 'en'
      : publisherLangsResponse[0]
    const publisherResponse = await Publisher.get(
      publisherId,
      false,
      nextAvailableLang,
    )

    return {
      name: publisherResponse.name,
      language: nextAvailableLang,
    }
  }, [publisherId])

  const {
    idle: isIdle,
    pending: isLoading,
    execute: getPublisherData,
    value: publisher,
    error: failedToGetPublisher,
  } = useAsync(getPublisherName, false)

  React.useEffect(() => {
    if (publisherId && getPublisherData) {
      getPublisherData()
    }
  }, [getPublisherData, publisherId])

  if (failedToGetPublisher) return null

  const isLoaded = Boolean(config && publisher && !isLoading)
  const publisherPageUrl = isLoaded
    ? `/publishers/${publisherId}?lang=${publisher.language}&tab=airtable`
    : ''

  return (
    <ListItem button={true} component={Link} to={publisherPageUrl}>
      <ListItemAvatar>
        <>
          {isLoading || isIdle ? (
            <Skeleton variant="circle" className={classes.avatarlarge} />
          ) : null}
          {isLoaded ? (
            <Box height={avatarImgSize}>
              <LazyImage
                className={classes.avatarlarge}
                src={urlReplacer(config.imageUrls.publisherLogo, {
                  size: avatarImgSize,
                  id: publisherId,
                  language: publisher.language,
                })}
                height={avatarImgSize}
                width={avatarImgSize}
                alt={`${publisher.name} Publisher Logo`}
              />
            </Box>
          ) : null}
        </>
      </ListItemAvatar>
      {isLoading || isIdle ? (
        <Box width="100%" mt="6px" mb="6px">
          <Skeleton>
            <Typography variant="body1">Publisher Name</Typography>
          </Skeleton>
          <Skeleton>
            <Typography variant="body2">5 Videos</Typography>
          </Skeleton>
        </Box>
      ) : null}
      {isLoaded ? (
        <ListItemText
          primary={publisher.name}
          secondary={`${numberOfVideos} Video${numberOfVideos > 1 ? 's' : ''}`}
        />
      ) : null}
    </ListItem>
  )
}

// None of these props are required so I can have the ability to
// render this component as a loading/skeleton component
PublisherAndDetails.propTypes = {
  publisherId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  numberOfVideos: PropTypes.number,
  config: PropTypes.shape({
    imageUrls: PropTypes.objectOf(PropTypes.string),
  }),
}

PublisherAndDetails.defaultProps = {
  publisherId: undefined,
  numberOfVideos: undefined,
  config: undefined,
}
