/* eslint-disable jsx-a11y/alt-text, jsx-a11y/media-has-caption */
import React from 'react'
import Button from '@material-ui/core/Button'
import {
  AppBar,
  Box,
  Fade,
  Grid,
  IconButton,
  Tabs,
  Tab,
  TextField,
  Tooltip,
  Typography,
  makeStyles,
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import { Skeleton } from '@material-ui/lab'
import PropTypes from 'prop-types'
import { Link, useHistory, useLocation, useParams } from 'react-router-dom'
import { sanitizeUrl } from 'utils'
import imgPeopleCelebrating from 'assets/img/people-celebrating.png'
import { FriendlyError } from 'components/ui/friendly-error-page'
import {
  AlertBar,
  AlertProvider,
  useAlert,
} from '../../../components/layout/alert-bar'
import Publisher from '../../../tupos/models/publisher'
import Languages from '../../../tupos/models/languages'
import SecondaryAppBar from '../../../components/layout/secondary-app-bar'
import DetailCard from '../../../components/layout/detail-card'
import getParamsFromProps from '../../../utils/get-params-from-props'
import {
  LanguageAddCard,
  LanguageEditCard,
} from '../../../components/ui/language-cards'
import handlePresignedUpload from '../../../utils/handle-presigned-upload'
import { useFriendlyErrorPage } from '../../../contexts/friendly-error-message-context'
import ButtonDialog from '../../../components/ui/button-dialog'

const useStyles = makeStyles((theme) => ({
  content: {
    maxWidth: 960,
    margin: 'auto',
    padding: theme.spacing(2),
  },

  divMargin: {
    margin: '10px',
  },

  // Detailed Card width
  card: {
    width: '100%',
  },

  textField: {
    width: '100%',
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      width: '49%', // to add space between inputs
    },
  },

  textArea: {
    width: '100%',
  },

  btnNewPublisher: {
    color: theme.palette.text.secondary,
  },

  // Hyperlinks
  link: {
    color: 'rgba(0, 0, 0, 0.54)',
    margin: 'auto',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },

  // Tabs
  tabPanel: {
    width: '100%',
  },

  // Skeleton Loading Content
  loadingContent: {
    width: '100%',
    height: 328, // height on mobile
    [theme.breakpoints.up('md')]: {
      height: 244, // height on desktop
    },
  },

  loadingSecondaryAppBar: {
    width: '100%',
    height: 56, // height on mobile
    [theme.breakpoints.up('md')]: {
      height: 64, // height on desktop
    },
  },
}))

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
}
TabPanel.defaultProps = {
  children: null,
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

function PublisherEditPage() {
  const location = useLocation()
  const history = useHistory()
  const { id: publisherId } = useParams()

  const { throwAlert } = useAlert()
  const [publisher, setPublisher] = React.useState()
  const [languages, setLanguages] = React.useState()
  const [currentTabIndex, setCurrentTabIndex] = React.useState(0)
  const [hasDeletedAllLanguages, setHasDeletedAllLanguages] = React.useState(
    false,
  )

  const { throwFriendlyErrorPage } = useFriendlyErrorPage()

  const classes = useStyles()

  const publisherLanguage = React.useMemo(
    () => getParamsFromProps({ location }, 'lang', 'en'),
    [location],
  )

  // Get all necessary data
  const loadData = React.useCallback(async () => {
    if (publisherId && publisherLanguage) {
      try {
        const publisherResponse = await Publisher.get(
          publisherId,
          true,
          publisherLanguage,
        )

        // getting this helps us filter remaining languages when offering to create a new language.
        const languagesResponse = await Languages.get('publisher', publisherId)

        // set the data in state
        setPublisher(
          new Publisher(publisherResponse, publisherLanguage).toObject(),
        )
        setLanguages(languagesResponse)
      } catch (e) {
        throwFriendlyErrorPage({
          message: `Failed to retrieve publisher at id (${publisherId}) and language (${publisherLanguage}).`,
        })
      }
    }
  }, [publisherId, publisherLanguage, throwFriendlyErrorPage])

  React.useEffect(() => {
    loadData()
  }, [loadData])

  // Change Tabs
  const handleTabChange = (event, nextTab) => {
    setCurrentTabIndex(nextTab)
  }

  // Updating Name, URL, and Tracking ID
  const handleInputChange = (e) => {
    const { name: inputType, value } = e.target
    setPublisher({ ...publisher, [inputType]: value })
  }

  // Save Publisher language-agnostic information
  const handleUpdatePublisherInfo = async () => {
    const sanitizedUrl = sanitizeUrl(publisher.url)
    if (!sanitizedUrl) {
      throwAlert({
        type: 'error',
        id: 'publisher_url_error',
        message: `Publisher url ${encodeURI(
          publisher.url,
        )} is not a valid url.`,
      })
      return
    }
    try {
      const publisherClass = Publisher.toClass({
        json: { ...publisher, url: sanitizedUrl },
        language: publisherLanguage,
      })
      await publisherClass.updateNameUrl()
      await loadData()
    } catch (error) {
      throwAlert({
        type: 'error',
        id: 'publisher_info_update_error',
        message: `There was an error updating the publisher information.`,
      })
    }
  }

  async function handleDeleteLanguage(language) {
    const publisherClass = Publisher.toClass({ json: publisher, language })
    try {
      await publisherClass.delete(language)
      const languagesArray = [...languages]
      const filteredLanguages = languagesArray.filter(
        (lang) => lang !== language,
      )
      setLanguages([...filteredLanguages])

      // if last video was deleted, let the admin know
      if (filteredLanguages.length === 0) {
        setHasDeletedAllLanguages(true)
        setTimeout(() => history.replace('/'), 2500)
      } else {
        throwAlert({
          type: 'success',
          id: 'language_delete_success',
          message: `Language ${language.toUpperCase()} successfully deleted.${
            filteredLanguages.length === 0 ? ' Redirecting home.' : ''
          }`,
          enableFuse: true,
        })
      }
    } catch (error) {
      throwAlert({
        type: 'error',
        id: 'language_delete_error',
        message: error.message,
      })
    }
  }

  async function handleUpdateLanguage({ object, language }) {
    const publisherClass = Publisher.toClass({
      json: object,
      language,
    })
    try {
      if (object.logoFile) {
        // Upload logo
        await publisherClass
          .getPresignedRequest(language, 'logo')
          .then((response) => {
            // On success, actually attempt our S3 file upload!
            const logoUploadResponse = handlePresignedUpload(
              object.logoFile,
              response.upload_url,
              response.params,
            )

            return logoUploadResponse
          })
      }
      if (object.bannerFile) {
        // Upload logo
        await publisherClass
          .getPresignedRequest(language, 'banner')
          .then((response) => {
            // On success, actually attempt our S3 file upload!
            const bannerUploadResponse = handlePresignedUpload(
              object.bannerFile,
              response.upload_url,
              response.params,
            )

            return bannerUploadResponse
          })
      }

      await publisherClass.updateDescriptionLanguage(language)
      throwAlert({
        type: 'success',
        id: `language_update_success_${language}`,
        message: `Language ${language.toUpperCase()} successfully updated.`,
        enableFuse: true,
      })
    } catch (error) {
      throwAlert({
        type: 'error',
        id: 'language_update_error',
        message: `There was an error updating language ${language.toUpperCase()}`,
      })
    }
  }

  async function handleSaveNewLanguage({ object, language }) {
    const publisherClass = Publisher.toClass({
      json: {
        ...object,
        id: publisher.id,
      },
      language,
    })
    try {
      if (object.logoFile) {
        // Upload logo
        await publisherClass
          .getPresignedRequest(language, 'logo')
          .then((response) => {
            // On success, actually attempt our S3 file upload!
            const logoUploadResponse = handlePresignedUpload(
              object.logoFile,
              response.upload_url,
              response.params,
            )

            return logoUploadResponse
          })
      }
      if (object.bannerFile) {
        // Upload logo
        await publisherClass
          .getPresignedRequest(language, 'banner')
          .then((response) => {
            // On success, actually attempt our S3 file upload!
            const bannerUploadResponse = handlePresignedUpload(
              object.bannerFile,
              response.upload_url,
              response.params,
            )

            return bannerUploadResponse
          })
      }

      await publisherClass.addLanguage(language, object.description)

      setLanguages([...languages, language].sort())
      throwAlert({
        type: 'success',
        id: 'new_language_save_success',
        message: `Language ${language.toUpperCase()} added successfully. It may take a few minutes for it to display correctly.`,
        enableFuse: true,
      })
    } catch (error) {
      throwAlert({
        type: 'error',
        id: 'new_language_save_error',
        message: `Error! The new language ${language.toUpperCase()} failed to save.`,
      })
    }
  }

  if (hasDeletedAllLanguages)
    return (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        height="calc(100vh - 64px)"
        width="100%"
      >
        <FriendlyError
          imageCredit="Illustrations from https://www.ls.graphics/"
          title="Publisher Successfully Deleted"
          message="You will be redirected to the Home Page momentarily."
          imageSrc={imgPeopleCelebrating}
          imageHeight={347}
          imageWidth={306}
        />
      </Box>
    )

  const isReady = Boolean(publisher && languages)

  // if not ready, render loading component
  if (!isReady) {
    return (
      <>
        <Skeleton variant="rect" className={classes.loadingSecondaryAppBar} />
        <Grid container={true} className={classes.content}>
          <Skeleton variant="rect" className={classes.loadingContent} />
        </Grid>
      </>
    )
  }

  return (
    <>
      <SecondaryAppBar
        title="Edit Publisher"
        actionComponent={
          <Box alignItems="center" display="flex">
            <Box mr={2}>
              <Tooltip title="Sorry, before you can delete this Publisher, you must first delete every language associated with it.">
                <span>
                  <IconButton
                    aria-label="delete all publisher languages"
                    disabled={true}
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </span>
              </Tooltip>
            </Box>
            <Box>
              <Button
                variant="contained"
                color="primary"
                component={Link}
                to={`/publishers/${publisher.id}?lang=${publisherLanguage}`} // location.search will append lang param
                replace={true}
              >
                DONE
              </Button>
            </Box>
          </Box>
        }
        passItBack={true}
      />

      <Fade in={true}>
        <>
          <Grid className={classes.content}>
            <DetailCard position="fixed">
              <AppBar position="static" color="default">
                <Tabs
                  value={currentTabIndex}
                  onChange={handleTabChange}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="fullWidth"
                  scrollButtons="on"
                >
                  {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                  <Tab label="Name & URL" {...a11yProps(0)} />
                </Tabs>
              </AppBar>

              {/* Name & URL Tab */}
              <TabPanel
                value={currentTabIndex}
                index={0}
                className={classes.tabPanel}
                data-testid="nameUrl"
              >
                <Box
                  display="flex"
                  flexDirection="row"
                  flexWrap="wrap"
                  justifyContent="space-between"
                >
                  <TextField
                    id="name"
                    label="NAME"
                    name="name"
                    value={publisher.name}
                    className={classes.textField}
                    onChange={handleInputChange}
                  />
                  <TextField
                    id="url"
                    label="URL"
                    name="url"
                    value={publisher.url}
                    className={classes.textField}
                    onChange={handleInputChange}
                  />
                  <TextField
                    id="trackingId"
                    label="Tracking ID (Optional)"
                    name="trackingId"
                    value={publisher.trackingId}
                    className={classes.textField}
                    onChange={handleInputChange}
                  />
                </Box>

                <Box marginTop={2}>
                  <ButtonDialog
                    actionComponent={<Button variant="outlined">Save</Button>}
                    dialogPrompt="Are you ready to save this publisher?"
                    dialogConfirmText="Save"
                    dialogDenyText="Don't Save"
                    handleDialogConfirm={handleUpdatePublisherInfo}
                    handleDialogDeny={() => null}
                  />
                </Box>
              </TabPanel>
            </DetailCard>
          </Grid>

          <Grid className={classes.content}>
            <LanguageAddCard
              onSave={handleSaveNewLanguage}
              languages={languages}
              type="publisher"
            />
            {languages.length > 0
              ? languages.map((language) => {
                  return (
                    <LanguageEditCard
                      language={language}
                      type="publisher"
                      id={publisherId}
                      key={`${publisherId}_${language}`}
                      onSave={handleUpdateLanguage}
                      saveButtonLabel="Update"
                      onDelete={() => handleDeleteLanguage(language)}
                      expandable={true}
                    />
                  )
                })
              : null}
          </Grid>
        </>
      </Fade>
    </>
  )
}

function PublisherEdit(props) {
  return (
    <AlertProvider>
      <AlertBar />
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <PublisherEditPage {...props} />
    </AlertProvider>
  )
}

export default PublisherEdit
