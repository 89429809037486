/**
 * @module CollectionCarousel
 */
import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import VisibilitySensor from 'react-visibility-sensor'
import {
  Button,
  Card,
  CardActionArea,
  CardContent,
  Typography,
  makeStyles,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import { ContentCard } from '@youversion/react'
import { gray } from '@youversion/react/styles/colors-v3'
import { urlReplacer } from '@youversion/utils'
import clsx from 'clsx'
import Publisher from 'tupos/models/publisher'
import Video from 'tupos/models/video'
import { COLLECTION_CAROUSEL, LOCAL_STORAGE } from 'utils/constants'
import { updateLocalStorageItem } from 'utils'

/**
 * @typedef AudioUrl
 * @property {number} bitrate - The audio bitrate.
 * @property {string} format - The audio format (e.g. "mp3").
 * @property {number} sampleRate - The audio sample rate.
 * @property {string} url - The audio file URL.
 */

/**
 * @typedef ImageUrl
 * @property {number} collection - The URL of the video's parent collection.
 * @property {string} publisherBanner - The video Publisher's banner image URL.
 * @property {number} publisherLogo - The video Publisher's logo URL.
 * @property {string} video - The video file URL.
 * @property {string} videoBlue - The video blur URL.
 */

/**
 * @typedef VideoUrl
 * @property {number} hls - The URL of the video's hls format.
 * @property {string} webm - The URL of the video's webm format.
 */

/**
 * @typedef Configuration
 * @property {Array} audioUrls - Array of AudioUrl objects.
 * @property {ImageUrl} imageUrls - The ImageUrl object.
 * @property {string} shareUrl - The share URL value.
 * @property {VideoUrl} videoUrls - The VideoUrl object.
 */

/**
 * @typedef Collections
 * @property {string} description - The description of the collection.
 * @property {number} end - The number of the day of the year representing the end day of the collection.
 * @property {number} id - The unique collection id.
 * @property {string} language - The language tag of the collection.
 * @property {number} start - The number of the day of the year representing the start day of the collection.
 * @property {string} title - The title of the collection.
 * @property {Array} videos - The array of collection videos.
 */

const useStyles = makeStyles((theme) => ({
  carouselCard: {
    '&:first-of-type': {
      '& div:first-child': {
        paddingLeft: '0',
      },
    },
    '&:last-of-type': {
      '& div:first-child': {
        paddingRight: '0',
      },
    },
    boxShadow: 'none',
    boxSizing: 'content-box',
    overflow: 'visible',
    width: '100%',
  },
  carouselCardEmpty: {
    '&:last-child': {
      paddingBottom: theme.spacing(2),
    },
    border: `2px dashed ${gray[15]}`,
    borderRadius: theme.spacing(1),
    color: gray[25],
    height: `calc(125px - ${theme.spacing(2) * 2}px)`,
    marginBottom: theme.spacing(3),
    position: 'relative',
    textAlign: 'center',
    width: `calc(125px * 1.75)`,
  },
  carouselCardEmptyBox: {
    left: '1px',
    margin: '0',
    padding: `${theme.spacing(3)}px !important`,
    position: 'absolute',
    right: '1px',
    top: '50%',
    transform: 'translateY(-50%)',
    width: 'auto',
  },
  carouselContainer: {
    display: 'flex',
    overflow: 'auto',
    width: '100%',
  },
  carouselWrapper: {
    width: '100%',
  },
  collectionLink: {
    '&:hover': {
      background: 'none',
      textDecoration: 'underline',
    },
    color: 'inherit',
    paddingTop: theme.spacing(0.5),
    textDecoration: 'none',
  },
  titleWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}))

/**
 * Convenience component function to return placeholder ContentCard components.
 *
 * @param {number} numCards - The number of ContentCard components to generate and return.
 * @param {string} className - The class name(s) to be applied to each placeholder card.
 *
 * @returns {React.ReactElement} - The specified number of ContentCard components.
 */
function PlaceholderContentCards(numCards, className) {
  const cards = []
  for (let i = 0; i < numCards; i += 1) {
    cards.push(
      <ContentCard
        caption={`\u00a0`}
        className={className}
        imageArtworkUrl={``}
        imageAspectRatio={1.75 / 1}
        isLoading={true}
        key={`placeholder-content-card--${Math.floor(
          Math.random() * 100000,
        )}-${i}`}
        title={`\u00a0`}
      />,
    )
  }
  return cards
}

/**
 * Represents a carousel component for collection videos.
 *
 * @param {object} props - The component's props object.
 * @param {Collections} props.collection - The Collection data object.
 * @param {Configuration} props.config - The configuration data object.
 * @param {string} props.language - The language to use when retrieving Collection data (Default: 'en').
 *
 * @returns {React.ReactElement} - The CollectionCarousel Component.
 */
export default function CollectionCarousel({
  collection,
  config,
  language = 'en',
}) {
  const classes = useStyles()
  const collectionLink = collection?.id
    ? {
        href: collection?.id
          ? `/collections/${collection.id}?lang=${language || 'en'}`
          : null,
        label: 'View All',
      }
    : null
  const [componentIsVisible, setComponentIsVisible] = React.useState(false)
  const [isInitialized, setIsInitialized] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(false)
  const [publishersData, setPublishersData] = React.useState(null)
  const [videosData, setVideosData] = React.useState(null)
  const placeholderCards = React.useMemo(() => {
    return PlaceholderContentCards(4, classes.carouselCard)
  }, [classes.carouselCard])

  /**
   * Convenience function to generate and return a URL for the specified video.
   *
   * @param {string} videoId - The video id of the image to be used.
   *
   * @returns {string} URL for the video image.
   */
  function getVideoImage(videoId) {
    const imageUrl = config?.imageUrls?.video || ''
    return imageUrl === ''
      ? imageUrl
      : urlReplacer(imageUrl, {
          height: COLLECTION_CAROUSEL.THUMBNAIL_SIZE.height,
          id: videoId,
          language,
          width: COLLECTION_CAROUSEL.THUMBNAIL_SIZE.width,
        })
  }

  /**
   * Handler function for the VisibilitySensor `onChange` event.
   *
   * @param {boolean} isVisible - Boolean flag for whether or not the element is in the visible viewport.
   *
   * @see {@link https://www.npmjs.com/package/react-visibility-sensor}.
   */
  function handleVisibilitySensorChange(isVisible) {
    if (isVisible && !componentIsVisible) {
      setComponentIsVisible(isVisible)
    }
  }

  /**
   * Effect to kick off one-time data retrieval after the component's visibility
   * is true, and there is a collection set.
   */
  React.useEffect(() => {
    const publisherIdsToLoad = []

    /**
     * Load data for Publishers in this Collection.
     */
    async function loadPublishersData() {
      setIsLoading(true)
      setIsInitialized(false)

      try {
        // Retrieve stored Publishers data, which will be used below to
        // conditionally add Publisher id to the array so there will not be
        // multiple API calls made for the same Publisher.
        const allPublishersData =
          JSON.parse(
            window.localStorage.getItem(LOCAL_STORAGE.KEYS.PUBLISHERS),
          ) || {}

        // Populate list of Publisher data needed to be retrieved as needed.
        const publisherPromises = []
        publisherIdsToLoad.forEach((id) => {
          if (!allPublishersData[id]) {
            publisherPromises.push(Publisher.get(id, 'false', language))
          }
        })

        // If no promises exist, set states and move on. Otherwise, fetch all
        // necessary Publisher data.
        if (!publisherPromises.length) {
          setPublishersData(allPublishersData)
          setIsInitialized(true)
          setIsLoading(false)
        } else {
          Promise.all(publisherPromises)
            .then((responseData) => {
              if (responseData?.length) {
                responseData.forEach((publisher) => {
                  allPublishersData[publisher.id] = publisher
                  updateLocalStorageItem({
                    key: LOCAL_STORAGE.KEYS.PUBLISHERS,
                    type: LOCAL_STORAGE.TYPES.OBJECT,
                    value: allPublishersData,
                  })
                })
              }
              setPublishersData(allPublishersData)
              setIsInitialized(true)
              setIsLoading(false)
            })
            .catch(() => {
              setIsInitialized(false)
              setIsLoading(false)
            })
        }
      } catch (error) {
        setIsInitialized(false)
        setIsLoading(false)
      }
    }

    /**
     * Load data for Videos in this Collection.
     *
     * @param {Function} callback - Optional callback function to trigger after function logic is complete.
     */
    async function loadVideosData(callback) {
      setIsLoading(true)

      try {
        const collectionVideosData =
          window.localStorage.getItem(LOCAL_STORAGE.KEYS.VIDEOS) || {}

        const {
          rows: videoCollectionRows,
        } = await Video.getVideosForCollection({
          collection: collection.id,
          page: COLLECTION_CAROUSEL.PAGE,
          pageSize: COLLECTION_CAROUSEL.PAGE_SIZE,
          language,
        })
        videoCollectionRows.forEach((video) => {
          // Add video to video data object.
          collectionVideosData[video.id] = video

          // If the array of Publisher ids doesn't include this video's publisher,
          // add it so its data can be retrieved next.
          if (!publisherIdsToLoad.includes(video.publisherId)) {
            publisherIdsToLoad.push(video.publisherId)
          }
        })

        // Set videos data state and load needed Publishers.
        setVideosData(collectionVideosData)
        if (callback && typeof callback === 'function') {
          callback()
        }
      } catch (error) {
        setIsLoading(false)
        if (callback && typeof callback === 'function') {
          callback()
        }
      }
    }
    if (collection.id && componentIsVisible) {
      loadVideosData(loadPublishersData)
    }
  }, [collection.id, componentIsVisible, language])

  return (
    <VisibilitySensor
      onChange={handleVisibilitySensorChange}
      partialVisibility={true}
    >
      <div className={clsx(classes.carouselWrapper, 'carousel-wrapper')}>
        {collection.title || collectionLink ? (
          <div className={classes.titleWrapper}>
            {collection.title ? (
              <Typography color="textPrimary" variant="h3">
                {collection.title}
              </Typography>
            ) : null}
            {collectionLink.href ? (
              <Button
                className={classes.collectionLink}
                component={Link}
                endIcon={<ArrowForwardIcon />}
                to={collectionLink.href}
              >
                {collectionLink.label || 'View Collection'}
              </Button>
            ) : null}
          </div>
        ) : null}
        <div className={classes.carouselContainer}>
          {isLoading && !isInitialized ? (
            placeholderCards
          ) : (
            <>
              {publishersData && videosData ? (
                <>
                  {Object.keys(videosData)?.length ? (
                    <>
                      {Object.values(videosData).map((video) => {
                        return (
                          <ContentCard
                            caption={
                              // eslint-disable-next-line no-underscore-dangle
                              publishersData[video.publisherId.toString()]
                                ._name || ''
                            }
                            className={classes.carouselCard}
                            imageArtworkUrl={getVideoImage(video.id)}
                            imageAspectRatio={1.75 / 1}
                            key={video.title.replace(/ /g, '-')}
                            linkComponent={Link}
                            title={video.title}
                            to={`/videos/${video.id}?lang=${language || 'en'}`}
                          />
                        )
                      })}
                    </>
                  ) : (
                    <Card className={classes.carouselCard}>
                      <CardContent className={classes.carouselCardEmpty}>
                        <CardActionArea
                          className={classes.carouselCardEmptyBox}
                          component={Link}
                          href={collectionLink.href}
                          to={collectionLink.href}
                        >
                          <AddIcon />
                          <Typography color="textPrimary" variant="body2">
                            Add a Video to this Collection
                          </Typography>
                        </CardActionArea>
                      </CardContent>
                    </Card>
                  )}
                </>
              ) : null}
            </>
          )}
        </div>
      </div>
    </VisibilitySensor>
  )
}

CollectionCarousel.propTypes = {
  collection: PropTypes.shape({
    description: PropTypes.string,
    end: PropTypes.number,
    id: PropTypes.number,
    language: PropTypes.string,
    start: PropTypes.number,
    title: PropTypes.string,
    videos: PropTypes.array,
  }).isRequired,
  config: PropTypes.shape({
    audioUrls: PropTypes.arrayOf(
      PropTypes.shape({
        bitrate: PropTypes.number,
        format: PropTypes.string,
        sampleRate: PropTypes.number,
        type: PropTypes.string,
      }),
    ),
    imageUrls: PropTypes.shape({
      collection: PropTypes.string,
      publisherBanner: PropTypes.string,
      publisherLogo: PropTypes.string,
      video: PropTypes.string,
      videoBlur: PropTypes.string,
    }),
    shareUrl: PropTypes.string,
    videoUrls: PropTypes.shape({
      hls: PropTypes.string,
      webm: PropTypes.string,
    }),
  }).isRequired,
  language: PropTypes.string.isRequired,
}
