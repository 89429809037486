/* eslint-disable import/prefer-default-export */
import React from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Typography,
  Tooltip,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  LinearProgress,
  CircularProgress,
  makeStyles,
} from '@material-ui/core'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import { Alert } from '@material-ui/lab'
import { Link } from 'react-router-dom'
import { statusTypes } from 'utils'
import VideoStatus from 'components/ui/video-status'

const useStyles = makeStyles((theme) => ({
  table: {
    width: '100%',
  },
  title: {
    // flexGrow: 1,
  },
  progressBar: {
    marginLeft: theme.spacing(2),
    flexGrow: 1,
  },
  tableRow: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  selectedRow: {
    backgroundColor: theme.palette.action.hover,
  },
  btnUploadComplete: {
    verticalAlign: 'text-top',
    marginRight: theme.spacing(1),
    color: theme.palette.success.main,
    '&.btn-upload-warning': {
      color: theme.palette.warning.main,
    },
  },
}))

export default function ProcessingList({ status, rows, currentUploadIndex }) {
  const classes = useStyles()

  const savingStatus = (
    <Tooltip title="Saving...">
      <CircularProgress size="1rem" />
    </Tooltip>
  )

  return (
    <>
      <TableContainer component={Paper}>
        <Box ml={2} mr={2} mt={2} display="flex" flexDirection="column">
          <Box display="flex" alignItems="center">
            <Typography className={classes.title} variant="h3">
              {status === statusTypes.PENDING
                ? `Uploading video ${currentUploadIndex + 1} of ${rows.length}`
                : null}
              {status === statusTypes.WARNING ||
              status === statusTypes.RESOLVED ? (
                <>
                  <CheckCircleOutlineIcon
                    className={`${classes.btnUploadComplete} btn-upload-${status}`}
                  />
                  Bulk Video Upload Complete!
                </>
              ) : null}
            </Typography>
            {status === statusTypes.PENDING ? (
              <LinearProgress className={classes.progressBar} />
            ) : null}
          </Box>
          <Typography
            className={classes.subtitle}
            variant="subtitle1"
            color="textSecondary"
          >
            {status === statusTypes.PENDING
              ? 'Do not leave this page until uploading is completed.'
              : null}
            {status === statusTypes.WARNING
              ? 'Check the errors, correct them, and try to re-upload those when fixed.'
              : null}
            {status === statusTypes.RESOLVED
              ? 'Videos are saved and will be processed one by one. It is safe to leave this page.'
              : null}
          </Typography>
        </Box>
        <Table
          className={classes.table}
          aria-label="table of videos to be uploaded to the api"
        >
          <TableHead>
            <TableRow>
              <TableCell>AirTable Row #</TableCell>
              <TableCell align="left">Title</TableCell>
              <TableCell align="right">Language</TableCell>
              <TableCell align="right">Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <React.Fragment key={`row_${row.object.airtable_row_id}`}>
                <TableRow
                  className={`${classes.tableRow} ${
                    row.uploadStatus === statusTypes.PENDING
                      ? classes.selectedRow
                      : ''
                  }`}
                >
                  <TableCell scope="row">
                    {row.object.airtable_row_id}
                  </TableCell>
                  <TableCell align="left">{row.object.title}</TableCell>
                  <TableCell align="right">{row.object.language}</TableCell>
                  <TableCell align="right">
                    {row.uploadStatus === statusTypes.PENDING ? (
                      savingStatus
                    ) : (
                      <VideoStatus status={row.uploadStatus} />
                    )}
                  </TableCell>
                </TableRow>
                <TableRow
                  className={
                    row.uploadStatus === statusTypes.PENDING
                      ? classes.selectedRow
                      : ''
                  }
                >
                  <TableCell colSpan={6} style={{ padding: 0 }}>
                    {row.errors?.map((error) => (
                      <Box pl={2} pr={2} mb={2} key={error}>
                        <Alert severity="error">{error}</Alert>
                      </Box>
                    ))}
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {status === statusTypes.RESOLVED || status === statusTypes.WARNING ? (
        <Box display="flex" mt={2} justifyContent="center" width="100%">
          <Typography
            variant="body1"
            color="textSecondary"
            component={Link}
            to="/"
          >
            Go Home
          </Typography>
          <Box ml={1} mr={1}>
            <Typography color="textSecondary">or</Typography>
          </Box>
          <Typography
            variant="body1"
            color="textSecondary"
            component={Link}
            to="/videos"
          >
            Visit the Videos Page
          </Typography>
        </Box>
      ) : null}
    </>
  )
}
ProcessingList.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  status: PropTypes.string.isRequired,
  currentUploadIndex: PropTypes.number.isRequired,
}
