import React from 'react'
import AirTable from 'airtable'
import * as yup from 'yup'
import { v4 as uuidv4 } from 'uuid'
import { makeModel } from '@youversion/api/core'
import { dropboxSetDownloadable, statusTypes } from 'utils'
import Video from 'tupos/models/video'
import * as Sentry from '@sentry/react'

const videoSchema = yup.object().shape({
  title: yup.string().required('Video title is required.').default(''),
  description: yup.string().default(''),
  language: yup
    .string()
    .test('length', 'Must be at least 2 characters', (val) => val.length >= 2)
    .required("Video language is required. (i.e. 'en')")
    .default(''),
  source_url: yup
    .string()
    .url('Invalid URL')
    .required('Video source URL is required.')
    .default(''),
  video_attachment: yup.array(yup.object().shape({
    url:  yup
    .string()
    .url('Invalid URL')
    .required('Video source URL is required.')
    .default(''),
  })).default([]),
  image_attachment: yup.array(yup.object().shape({
    url:  yup
    .string()
    .url('Invalid URL')
    .default(''),
  })).default([]),
  thumbnail_source_url: yup.string().url().default(''),
  type: yup
    .string()
    .transform((value) => value.toLowerCase())
    .required('Video type is required.') // TODO, should we let publishers choose what type of video it is??
    .default(''),
  has_parent_video: yup.bool().default(false),
  parent_video_airtable_reference: yup.array().default([]),
  parent_video_id: yup
    .number()
    .when(['has_parent_video', 'parent_video_airtable_reference'], {
      is: (hasParentVideo, parentVideoAirTableRef) =>
        hasParentVideo && parentVideoAirTableRef.length <= 0,
      then: yup
        .number()
        .positive('Update the parent video ID')
        .required(
          "This video is a child of an existing video. The Parent video's BlockBuster id is required.",
        ),
    })
    .transform((value, originalValue) =>
      typeof originalValue === 'string' && originalValue === '' ? null : value,
    )
    .default(0),
  publisher_id: yup
    .number()
    .required('Publisher id is required.')
    .positive()
    .integer(),
  comment: yup.string().default(''),
  is_selected: yup.bool().default(false),
  orientation: yup
    .string()
    .oneOf(['landscape', 'portrait'])
    .required("Select this video's orientation")
    .default('landscape'),
  upload_status: yup.string().default('idle'),
  upload_errors: yup.string().default(''),
  references: yup.array().default([]),
})

const base = new AirTable({
  apiKey: process.env.REACT_APP_EXPOSED_AIRTABLE_API_KEY,
}).base(process.env.REACT_APP_EXPOSED_AIRTABLE_BASE_KEY)

// Airtable announced on February 8, 2023 the deprecation of getting Attachment URL's from a
// formula field. And, that's what we've been doing for a couple of years now. This is a
// simple function to correct that behvaior by getting the actual video URL directly from the
// Attachment, instead. This is also known as a "monkeypatch" :).
function transformVideoObjectSourceUrl(videoObject) {
  // This video URL will expire after 2 hours of access, but since we're not storing it,
  // we are at no loss. Every time we fetch the video object, it will be a usable URL.
  const attachmentUrl = videoObject?.['video_attachment']?.[0]?.url || ''
  const thumbnailUrl = videoObject?.['image_attachment']?.[0]?.url || ''

  return {
    ...videoObject,
    source_url: attachmentUrl,
    thumbnail_source_url: thumbnailUrl,
  }
}

/**
 * If a video is a dropbox url, this will convert the url to have the correct params to be
 * downloadable.
 * */
const convertVideoObjectUrls = (videoObject) => {
  const videoUrl = dropboxSetDownloadable(videoObject.source_url)
  let thumbnailUrl
  if (videoObject.thumbnail_source_url) {
    thumbnailUrl = dropboxSetDownloadable(videoObject.thumbnail_source_url)
  }

  return {
    ...videoObject,
    source_url: videoUrl,
    thumbnail_source_url: thumbnailUrl,
  }
}

const createVideoUploadObject = (video) => ({
  object: convertVideoObjectUrls(video.$object),
  uploadStatus: statusTypes.IDLE,
  blockBusterId: undefined,
  airTableId: video.$id,
  errors: [],
})

export function useAirTableVideosFromPublisher(id) {
  const [videos, setVideos] = React.useState([])
  const [isReady, setIsReady] = React.useState(false)
  const [hasError, setHasError] = React.useState(false)
  const [isEligible, setIsEligible] = React.useState()

  const [videosProcessingList, setVideosProcessingList] = React.useState([])
  const [bulkUploadStatus, setBulkUploadStatus] = React.useState(
    statusTypes.IDLE,
  )
  const [currentUploadIndex, setCurrentUploadIndex] = React.useState(0)

  const validateBulkUploadPermission = React.useCallback((pubId) => {
    base(process.env.REACT_APP_AIRTABLE_BASE_PUBLISHERS)
      .select({
        filterByFormula: `{publisher_id} = ${pubId}`,
        maxRecords: 1,
        view: 'Grid view',
      })
      .eachPage(
        (records) => setIsEligible(records?.length > 0), // run on each page
        (error) => (error ? setIsEligible(false) : null), // run when each page is called, when it's done
      )
  }, [])

  const startBulkUpload = async (videosToUpload) => {
    // get initial video list
    setVideosProcessingList(
      videosToUpload.map((vid) => createVideoUploadObject(vid)),
    )

    // rest is handled with hook below
  }

  // Uploads videos to BlockBuster and updates AirTable accordingly
  React.useEffect(() => {
    if (
      bulkUploadStatus === statusTypes.IDLE &&
      videosProcessingList.length > 0
    ) {
      const uploadVideos = async () => {
        const updateVideoInAirTable = (airTableId, fieldsToUpdate) => {
          if (fieldsToUpdate.uploadStatus === 'success') {
            // Update the row with the published video id from BlockBuster and set upload status to success.
            const updateOptions = {
              id: airTableId,
              fields: {
                upload_status: fieldsToUpdate.uploadStatus,
                blockbuster_video_id: fieldsToUpdate.blockBusterId,
                video_attachment: '', // Clear out the video attachment
                image_attachment: '', // Clear out the image attachment
              },
            }
            base(process.env.REACT_APP_AIRTABLE_BASE_BULK_UPLOAD).update(
              [updateOptions],
              (error) => {
                if (error) {
                  Sentry.captureException(error)
                }
              },
            )
          } else {
            const { uploadStatus, errors } = fieldsToUpdate
            const updateOptions = {
              id: airTableId,
              fields: {
                upload_status: uploadStatus,
                upload_errors: errors?.join(', '),
              },
            }
            base(process.env.REACT_APP_AIRTABLE_BASE_BULK_UPLOAD).update(
              [updateOptions],
              (error) => {
                if (error) {
                  Sentry.captureException(error)
                }
              },
            )
          }
        }

        const updateVideo = (videoIndex, fieldsToUpdate) => {
          setVideosProcessingList((prevState) => {
            const updatedList = [...prevState]
            Object.keys(fieldsToUpdate).forEach((key) => {
              if (
                key === 'uploadStatus' &&
                (fieldsToUpdate[key] === statusTypes.SUCCESS ||
                  fieldsToUpdate[key] === statusTypes.REJECTED)
              ) {
                updateVideoInAirTable(
                  videosProcessingList[videoIndex].airTableId,
                  fieldsToUpdate,
                )
              }
              updatedList[videoIndex][key] = fieldsToUpdate[key]
            })
            return updatedList
          })
        }

        const uploadVideo = async (video, videoIndex) => {
          updateVideo(videoIndex, { uploadStatus: statusTypes.PENDING })

          const videoClass = new Video(video.object, video.object.language)

          let uploadResponse
          if (video.object.has_parent_video) {
            if (video.object.parent_video_id) {
              videoClass.id = video.object.parent_video_id

              try {
                uploadResponse = await videoClass.createVideoLanguage()
              } catch {
                updateVideo(videoIndex, {
                  uploadStatus: statusTypes.REJECTED,
                  errors: [
                    'Video failed to upload',
                    'Make sure parent video and child video are not the same language',
                  ],
                })
                return
              }
            } else if (
              video.object.parent_video_airtable_reference.length > 0
            ) {
              // grab the parent video
              const parentVideo = videosProcessingList.filter(
                (filterVid) =>
                  filterVid.airTableId ===
                  video.object.parent_video_airtable_reference[0],
              )?.[0]

              if (!parentVideo) {
                updateVideo(videoIndex, {
                  uploadStatus: statusTypes.REJECTED,
                  errors: [
                    'The parent video either does not exist or has failed when uploading.',
                  ],
                })
                return
              }

              if (parentVideo.object.language === video.object.language) {
                updateVideo(videoIndex, {
                  uploadStatus: statusTypes.REJECTED,
                  errors: [
                    'Child video cannot be the same language as parent video.',
                  ],
                })
                return
              }

              videoClass.id = parentVideo.blockBusterId

              try {
                uploadResponse = await videoClass.createVideoLanguage()
              } catch {
                updateVideo(videoIndex, {
                  uploadStatus: statusTypes.REJECTED,
                  errors: ['Video failed to upload'],
                })
                return
              }
            }
          } else {
            // this is a brand new video
            try {
              uploadResponse = await videoClass.createVideo(uuidv4())
            } catch (e) {
              updateVideo(videoIndex, {
                uploadStatus: statusTypes.REJECTED,
                errors: [e.message],
              })
              return
            }
          }
          updateVideo(videoIndex, {
            object: { ...video.object, ...uploadResponse },
            uploadStatus: statusTypes.SUCCESS,
            blockBusterId: uploadResponse.id,
          })
        }

        setBulkUploadStatus(statusTypes.PENDING)
        let hasUploadErrors = false

        // upload each video one by one
        for (let i = 0; i < videosProcessingList.length; i += 1) {
          setCurrentUploadIndex(i)

          // eslint-disable-next-line no-await-in-loop
          await uploadVideo(videosProcessingList[i], i)

          // check for errors
          if (!hasUploadErrors && videosProcessingList[i].errors.length > 0)
            hasUploadErrors = true
        }

        setBulkUploadStatus(
          hasUploadErrors ? statusTypes.WARNING : statusTypes.RESOLVED,
        )
      }

      uploadVideos()
    }
  }, [bulkUploadStatus, videosProcessingList])

  React.useEffect(() => {
    if (id) {
      validateBulkUploadPermission(id)
    }
  }, [id, validateBulkUploadPermission])

  const getVideos = React.useCallback(() => {
    if (!id)
      throw new Error('getVideos requires the publisher ID to get videos')

    if (isEligible !== undefined && !isEligible) {
      return
    }

    base(process.env.REACT_APP_AIRTABLE_BASE_BULK_UPLOAD)
      .select({
        // Selecting the first 10 records in Grid view:
        filterByFormula: `{publisher_id} = ${id}`,
        sort: [{ field: 'has_parent_video', direction: 'asc' }], // moves these to the very end
        view: 'Grid view',
      })
      .eachPage(
        function page(records, fetchNextPage) {
          // This function (`page`) will get called for each page of records.
          records.forEach((record) => {
            setVideos((prevVideos) => {
              const videoObject = transformVideoObjectSourceUrl(record.fields)

              return [
                ...prevVideos,
                makeModel({
                  name: record.id,
                  id: record.id,
                  data: videoObject,
                  schema: videoSchema,
                }),
              ]
            })
          })

          // To fetch the next page of records, call `fetchNextPage`.
          // If there are more records, `page` will get called again.
          // If there are no more records, `done` will get called.
          fetchNextPage()
        },
        function done(error) {
          if (error) {
            setHasError(error)
          } else {
            setIsReady(true)
          }
        },
      )
  }, [id, isEligible])

  return {
    getVideos,
    setVideos,
    videos,
    isReady,
    hasError,
    isEligible,
    videosProcessingList,
    startBulkUpload,
    bulkUploadStatus,
    currentUploadIndex,
  }
}

export function useAirTableRecentSubmissions() {
  const [submissions, setSubmissions] = React.useState([])
  const [status, setStatus] = React.useState(statusTypes.IDLE)
  const [publishersSubmissions, setPublishersSubmissions] = React.useState({})

  React.useEffect(() => {
    // Retrieve all Airtable Entries that do not have a 'success' upload status.
    base(process.env.REACT_APP_AIRTABLE_BASE_BULK_UPLOAD)
      .select({
        sort: [{ field: 'publisher_id', direction: 'asc' }],
        view: 'Grid view',
        fields: ['publisher_id'],
        filterByFormula: "{upload_status} != 'success'",
      })
      .eachPage(
        function page(records, fetchNextPage) {
          setSubmissions((prevVideos) => [...prevVideos, ...records])
          fetchNextPage()
        },
        function done(error) {
          if (error) {
            setStatus(statusTypes.ERROR)
          } else {
            setStatus(statusTypes.RESOLVED)
          }
        },
      )
  }, [])

  React.useEffect(() => {
    if (status === statusTypes.RESOLVED && submissions.length > 0) {
      const publishersAndVideos = {}
      submissions.forEach((submission) => {
        const {
          // eslint-disable-next-line camelcase
          fields: { publisher_id },
        } = submission
        if (publishersAndVideos[publisher_id]) {
          publishersAndVideos[publisher_id] += 1
        } else {
          publishersAndVideos[publisher_id] = 1
        }
      })
      setPublishersSubmissions(publishersAndVideos)
    }
  }, [status, submissions])

  return {
    submissions,
    status,
    publishersSubmissions,
  }
}
