/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import PropTypes from 'prop-types'
import { Box, TextField, Typography } from '@material-ui/core'
import {
  DateRangePicker,
  DateRangeDelimiter,
  LocalizationProvider as PickerContext,
} from '@material-ui/pickers'
import MomentUtils from '@material-ui/pickers/adapter/moment'

/**
 * Date range picker to set collection visibility
 * @param {Object} props
 * @param {Function} props.onChange The onChange handler
 * @param {Array} props.value The date array
 */
export default function CollectionDatePicker({ onChange, value }) {
  return (
    <>
      <Box mb={2}>
        <Typography>
          Collection will be visible within this date range. If empty, the
          collection will always be visible.
        </Typography>
      </Box>
      <PickerContext dateAdapter={MomentUtils}>
        <DateRangePicker
          startText="Start Date"
          endText="End Date"
          inputFormat="MMMM D"
          value={value}
          onChange={(date) => onChange(date)}
          renderInput={(startProps, endProps) => (
            <>
              <TextField
                {...startProps}
                name="start"
                title="Start Date"
                placeholder="Immediately"
                helperText="Default: immediately"
              />
              <DateRangeDelimiter> to </DateRangeDelimiter>
              <TextField
                {...endProps}
                name="end"
                title="End Date"
                placeholder="Never"
                helperText="Default: never"
              />
            </>
          )}
        />
      </PickerContext>
    </>
  )
}

CollectionDatePicker.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  ).isRequired,
}
