/**
 * @module Collections
 */
/* eslint-disable no-underscore-dangle */
import { TuposModel } from '@youversion/tupos-base'
import setNumber from '@youversion/tupos-base/dist/setters/number'
import setString from '@youversion/tupos-base/dist/setters/string'
import api4 from '@youversion/tupos-base/dist/fetchers/api4'
import setArray from '@youversion/tupos-base/dist/setters/array'
import { getFeaturedCollectionById, preventLanguageAction } from 'utils'

/**
 * @typedef Collections
 * @property {string} description - The description of the collection.
 * @property {number} end - The number of the day of the year representing the end day of the collection.
 * @property {number} id - The unique collection id.
 * @property {string} language - The language tag of the collection.
 * @property {number} start - The number of the day of the year representing the start day of the collection.
 * @property {string} title - The title of the collection.
 * @property {Array} videos - The array of collection videos.
 */

/**
 * @typedef CollectionsResource
 * @property {number} nextPage - The number of the next page.
 * @property {number} pageSize - The page size of the results.
 * @property {Array<Collections>} rows - An array of collection objects.
 */

/**
 * Collections model.
 *
 * @augments TuposModel
 */
class Collections extends TuposModel {
  constructor(json, language) {
    super(json)
    if (!json || typeof json !== 'object') {
      return
    }
    this.description = json.description
    this.end = json.end
    this.id = json.id
    this.language = json.language || language
    this.start = json.start
    this.title = json.title
    this.videos = json.videos
  }

  /**
   * Convert Collections to simple object.
   *
   * @returns {Collections} The collections as an object.
   */
  toObject() {
    return {
      description: this.description,
      end: this.end,
      id: this.id,
      language: this.language,
      start: this.start,
      title: this.title,
      videos: this.videos,
    }
  }

  /**
   * Convert object to collection.
   *
   * @param {object} params - The toClass function params object.
   * @param {object} params.json - The JSON object of collection data.
   * @param {string} [params.language] - The language tag of the collection (default: 'en').
   *
   * @returns {Collections} A Collections object.
   */
  static toClass({ json, language = 'en' }) {
    return new Collections(json, language)
  }

  /**
   * Fetch a Collections Resource.
   *
   * @param {number} id - The id of the collection to retrieve.
   * @param {string} [language] - The language tag of the collection to retrieve (default: 'en').
   *
   * @returns {Collections} - A Collections object.
   *
   * @throws {Error}
   */
  static async get(id, language = 'en') {
    const json = await TuposModel.get(
      api4({
        additionalHeaders: {
          'Accept-Language': language,
        },
        auth: true,
        endpoint: 'movies',
        fetchArgs: { cache: 'no-cache' },
        method: 'collections/:id',
        parseJson: true,
        urlParams: {
          id,
        },
        version: '4.0',
      }),
    )

    if (typeof json !== 'object') {
      throw new Error()
    }
    if (json && json.message) {
      throw new Error(json.message)
    }

    return new Collections(json, language)
  }

  /**
   * Fetch a Collection of Collections Resources.
   *
   * @param {object} params - The function params object.
   * @param {string} [params.fields] - Comma separated fields to return (default: '*').
   * @param {string} [params.language] - The collection language (default: 'en').
   * @param {number} [params.page] - Page number of results (* is permitted with 1 or 2 fields) (default: 1).
   * @param {number} [params.pageSize] - Size of results page (default: 25).
   * @param {string} params.videoId - Video ID for which to retrieve collections.
   *
   * @returns {CollectionsResource} - A Collections object.
   *
   * @throws {Error}
   */
  static async getCollection({
    fields = '*',
    language = 'en',
    page = 1,
    pageSize = 25,
    videoId,
  }) {
    const json = await TuposModel.get(
      api4({
        additionalHeaders: {
          'Accept-Language': language,
        },
        auth: true,
        endpoint: 'movies',
        method: 'collections',
        params: {
          fields,
          page,
          page_size: pageSize,
          show_empty: true,
          show_seasonal: true,
          video_id: videoId,
        },
        parseJson: true,
        version: '4.0',
      }),
    )

    if (!Array.isArray(json.data)) {
      throw new Error(`Failed to fetch collections from language: ${language}`)
    }

    const rows = json.data.map((item) => new Collections(item))

    return {
      nextPage: json.next_page,
      pageSize: json.page_size,
      rows,
    }
  }

  /**
   * Fetch a Collection Playlist.
   *
   * @param {object} params - The function params object.
   * @param {string} [params.isOrdered] - Boolean flag to return ordered/pinned collections, if true, or non-ordered/pinned collections, if false (default: true).
   * @param {string} [params.language] - The collection language (default: 'en').
   *
   * @returns {Array<number>} - An array of Collection IDs.
   *
   * @see {@link https://docs.thewardro.be/swagger-ui/?urls.primaryName=Videos#/Auth%20Required/playlists_order.admin_resource_get|Playlists Order documentation}.
   *
   * @throws {Error}
   */
  static async getCollectionPlaylist({ isOrdered = true, language = 'en' }) {
    const json = await TuposModel.get(
      api4({
        additionalHeaders: {
          'Accept-Language': language,
        },
        auth: true,
        endpoint: 'videos',
        method: 'playlists_order',
        params: {
          is_ordered: isOrdered,
          language_tag: language,
        },
        parseJson: true,
        version: 'admin/4.1',
      }),
    )

    // Note: If this returns blank data, it's with a 204 response, meaning that
    // there is No Content (no ordered collections playlist items). As such,
    // the logic below ensures we have data and returns it or a blank Array as
    // a way to normalize the return so it will always return an Array.
    if (json.playlists_ids && !Array.isArray(json.playlists_ids)) {
      throw new Error(
        `Failed to fetch collection playlist for language: ${language}`,
      )
    }

    return json.playlists_ids || []
  }

  /**
   * Add a Collection Resource.
   *
   * @returns {Collections} - A Collections object.
   *
   * @throws {Error}
   */
  async addCollections() {
    // Prevent disallowed languages from submitting to the API
    preventLanguageAction(this.language)

    const json = await TuposModel.get(
      api4({
        auth: true,
        bodyParams: {
          created_dt: new Date().toISOString(),
          description: this.description || undefined,
          end: this.end || undefined,
          id: this.id,
          language: this.language,
          start: this.start || undefined,
          title: this.title,
        },
        endpoint: 'movies',
        fetchArgs: { method: 'POST' },
        method: 'collections',
        parseJson: undefined,
        version: '4.0',
      }),
    )

    if (typeof json !== 'object') {
      throw new Error()
    }

    return new Collections(json)
  }

  /**
   * Update a Collection Playlist.
   *
   * @param {object} params - The function params object.
   * @param {string} [params.language] - The collection language (default: 'en').
   * @param {Array<number>} [params.orderedCollectionIds] - Array of collection ids (default: []).
   *
   * @returns {Array<number>} - An array of Collection IDs.
   *
   * @see [Playlists Order documentation]{@link https://docs.thewardro.be/swagger-ui/?urls.primaryName=Videos#/Auth%20Required/playlists_order.admin_resource_put}.
   *
   * @throws {Error}
   */
  static async updateCollectionPlaylist({
    language = 'en',
    orderedCollectionIds = [],
  }) {
    const json = await TuposModel.get(
      api4({
        additionalHeaders: {
          'Accept-Language': language,
        },
        auth: true,
        bodyParams: {
          playlists_ids: orderedCollectionIds,
        },
        endpoint: 'videos',
        fetchArgs: { method: 'PUT' },
        method: 'playlists_order',
        params: {
          language_tag: language,
        },
        parseJson: true,
        version: 'admin/4.1',
      }),
    )

    if (json.playlists_ids && !Array.isArray(json.playlists_ids)) {
      throw new Error(
        `Failed to update collection playlist for language: ${language}`,
      )
    }

    return json.data
  }

  /**
   * Update a Collection Resource Title & Description.
   *
   * @returns {Collections} - A Collections object.
   *
   * @throws {Error}
   */
  async updateTitleDescriptionCollection() {
    const json = await TuposModel.get(
      api4({
        additionalHeaders: {
          'Accept-Language': this.language,
        },
        auth: true,
        bodyParams: {
          description: this.description,
          language: this.language,
          title: this.title,
        },
        endpoint: 'movies',
        fetchArgs: { method: 'PUT' },
        method: 'collections/:id',
        parseJson: undefined,
        urlParams: {
          id: this.id,
        },
        version: '4.0',
      }),
    )

    if (typeof json !== 'object') {
      throw new Error()
    }

    return new Collections(json)
  }

  /**
   * Update a Collection Resource Start Date & End Date and Order.
   *
   * @param {string} language - The collection language.
   *
   * @returns {Collections} - A Collections object.
   *
   * @throws {Error}
   */
  async updateDatesAndOrder(language) {
    const json = await TuposModel.get(
      api4({
        additionalHeaders: {
          'Accept-Language': this.language || language,
        },
        auth: true,
        bodyParams: {
          end: this.end,
          start: this.start,
          videos: this.videos,
        },
        endpoint: 'movies',
        fetchArgs: { method: 'PUT' },
        method: 'collections/:id',
        parseJson: undefined,
        urlParams: {
          id: this.id,
        },
        version: '4.0',
      }),
    )

    if (typeof json !== 'object') {
      throw new Error()
    }

    return new Collections(json)
  }

  /**
   * Update a Collection Resource Video Order.
   *
   * @param {string} language - The collection language.
   *
   * @returns {Collections} - A Collections object.
   *
   * @throws {Error}
   */
  async updateVideoOrder(language) {
    const json = await TuposModel.get(
      api4({
        additionalHeaders: {
          'Accept-language': language,
        },
        auth: true,
        bodyParams: {
          videos: this.videos,
        },
        endpoint: 'movies',
        fetchArgs: { method: 'PUT' },
        method: 'collections/:id',
        parseJson: undefined,
        urlParams: {
          id: this.id,
        },
        version: '4.0',
      }),
    )

    if (typeof json !== 'object') {
      throw new Error()
    }

    return new Collections(json)
  }

  /**
   * Reserve Collection ID.
   * Prior to saving a collection, the ID needs to be reserved, per the API spec.
   *
   * @returns {object} - The JSON response object.
   *
   * @throws {Error}
   */
  // eslint-disable-next-line class-methods-use-this
  async reserveCollectionId() {
    const json = await TuposModel.get(
      api4({
        additionalHeaders: {
          'Content-Type': 'application/json',
        },
        auth: true,
        endpoint: 'movies',
        fetchArgs: { method: 'POST' },
        method: 'collections:reserve',
        parseJson: undefined,
        version: '4.0',
      }),
    )
    if (typeof json !== 'object') {
      throw new Error()
    }

    return json
  }

  /**
   * Get Fields/Presigned-post Response for Uploading a Thumbnail Image for a Collection.
   * Note: This is copied from Tommy George's publisher model.
   *
   * @returns {object} - The JSON response object.
   *
   * @throws {Error}
   */
  async getPresignedRequest() {
    // Prevent disallowed languages from submitting to the API
    preventLanguageAction(this.language)

    const json = await TuposModel.get(
      api4({
        auth: true,
        endpoint: 'movies',
        method: `collections/:id:presign?language=:language`,
        parseJson: undefined,
        urlParams: {
          id: this.id,
          language: this.language,
        },
        version: '4.0',
      }),
    )
    if (typeof json !== 'object') {
      throw new Error()
    }
    if (json && json.message) {
      throw new Error(json.message)
    }

    return json
  }

  /**
   * Delete a collection.
   *
   * @param {string} language - The collection language.
   *
   * @returns {object} - The JSON response object.
   *
   * @throws {Error}
   */
  async delete(language) {
    if (getFeaturedCollectionById(this.id)) {
      throw new Error('Cannot delete a featured collection')
    }

    const json = await TuposModel.get(
      api4({
        additionalHeaders: {
          'Accept-Language': language,
        },
        auth: true,
        bodyParams: {
          language: language || this.language,
        },
        endpoint: 'movies',
        fetchArgs: { method: 'DELETE' },
        method: 'collections/:id',
        parseJson: undefined,
        urlParams: {
          id: this.id,
        },
        version: '4.0',
      }),
    )

    if (typeof json !== 'object') {
      throw new Error()
    }
    if (json.message && json.message === 'Conflict') {
      throw new Error('Error deleting collection')
    }

    return json
  }

  /**
   * The number of the day of the year representing the end day of the collection.
   *
   * @returns {number} The collection end value.
   */
  get end() {
    return this._end
  }

  set end(end) {
    this._end = setNumber(end, 'end')
  }

  /**
   * The description of the collection.
   *
   * @returns {string} The collection description value.
   */
  get description() {
    return this._description
  }

  set description(description) {
    this._description = setString(description, 'description')
  }

  /**
   * The unique collection id.
   *
   * @returns {number} The collection id value.
   */
  get id() {
    return this._id
  }

  set id(id) {
    this._id = setNumber(id, 'id')
  }

  /**
   * The language tag of the collection.
   *
   * @returns {string} The collection language value.
   */
  get language() {
    return this._language
  }

  set language(language) {
    this._language = setString(language, 'language')
  }

  /**
   * The number of the day of the year representing the start day of the collection.
   *
   * @returns {number} The collection start value.
   */
  get start() {
    return this._start
  }

  set start(start) {
    this._start = setNumber(start, 'start')
  }

  /**
   * The title of the collection.
   *
   * @returns {string} The collection title value.
   */
  get title() {
    return this._title
  }

  set title(title) {
    this._title = setString(title, 'title')
  }

  /**
   * The array of collection videos.
   *
   * @returns {Array} The collection videos value.
   */
  get videos() {
    return this._videos
  }

  set videos(videos) {
    this._videos = setArray(videos)
  }
}

export default Collections
