/**
 * @module Fuse
 */
import React from 'react'
import PropTypes from 'prop-types'
import { Box, CircularProgress, IconButton } from '@material-ui/core'
import ClearIcon from '@material-ui/icons/Clear'
import useTimeout from 'hooks/useTimeout'

/**
 * A countdown fuse that fires onDismiss when it gets to 0.
 *
 * @alias module:Fuse
 *
 * @param {object} props - The component props object.
 * @param {string} [props.color] - The color of the Fuse. Defaults to 'inherit'.
 * @param {number} [props.duration] - The amount of time that the fuse takes in milliseconds. Defaults to 10000.
 * @param {boolean} [props.dismissible] - Optionally display a clickable button to fire 'onDismiss'. Defaults to `true`.
 * @param {Function} props.onEnded - The function to fire once the fuse is done.
 * @param {('small'|'medium')} [props.size] - The icon size. Defaults to 'small'. Options are 'small|medium'.
 * @param {number} [props.thickness] - The circular progress thickness. Defaults to `2`.
 *
 * @returns {React.ReactElement} - The fuse component.
 *
 * @example
 * <Fuse color="inherit" dismissible={true} duration={5000} onEnded={() => setShowModal(false)} size="small" thickness={2} />
 */
export function Fuse({
  color,
  duration,
  dismissible,
  onEnded,
  size,
  thickness,
  ...props
}) {
  const { getRemainingTime } = useTimeout(onEnded, duration)

  const [progress, setProgress] = React.useState(100)

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress > 0) {
          const remainingProgress = (getRemainingTime() / duration) * 100
          return remainingProgress > 0 ? remainingProgress : 0
        }
        return 0
      })
    }, 50)

    if (progress <= 0) {
      clearInterval(timer)
    }
    return () => {
      clearInterval(timer)
    }
  }, [duration, getRemainingTime, progress])

  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress
        variant="static"
        value={progress}
        color={color}
        size={size === 'small' ? 33 : 51}
        thickness={thickness}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
      {dismissible ? (
        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <IconButton onClick={onEnded} color={color} size={size}>
            <ClearIcon />
          </IconButton>
        </Box>
      ) : null}
    </Box>
  )
}

Fuse.propTypes = {
  color: PropTypes.string,
  duration: PropTypes.number,
  dismissible: PropTypes.bool,
  onEnded: PropTypes.func.isRequired,
  size: PropTypes.string,
  thickness: PropTypes.number,
}

Fuse.defaultProps = {
  color: 'inherit',
  duration: 10000,
  dismissible: true,
  size: 'small',
  thickness: 2,
}
