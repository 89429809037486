import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles, TextField } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import appLanguagesJSON from 'tupos/models/languages/app-languages.json'

const useStyles = makeStyles(() => ({
  formControl: {
    minWidth: 200,
  },
}))

function getOptionLabel(langTag) {
  if (appLanguagesJSON[langTag]?.english) {
    return `(${langTag}) - ${appLanguagesJSON[langTag].english}`
  }
  return `(${langTag})`
}

const LanguageSelector = ({
  langs,
  changed,
  current,
  fullWidth,
  disabled,
  ...props
}) => {
  const classes = useStyles()

  const handleChange = (event, newValue) => {
    changed(newValue || current)
  }

  const isValueInList = langs?.includes(current)

  return (
    <Autocomplete
      options={langs}
      getOptionLabel={getOptionLabel}
      value={isValueInList ? current : null}
      onChange={handleChange}
      disabled={disabled}
      fullWidth={fullWidth}
      renderInput={(params) => (
        <TextField
          className={classes.formControl}
          variant="outlined"
          label="Language"
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...params}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
          fullWidth={fullWidth}
        />
      )}
    />
  )
}

LanguageSelector.propTypes = {
  langs: PropTypes.arrayOf(PropTypes.string),
  changed: PropTypes.func.isRequired,
  current: PropTypes.string,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
}

LanguageSelector.defaultProps = {
  current: null,
  langs: undefined,
  fullWidth: false,
  disabled: false,
}

export default LanguageSelector
