import React from 'react'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import FriendlyErrorPage from '../components/ui/friendly-error-page'

const FriendlyErrorPageContext = React.createContext()

function FriendlyErrorPageProvider({ children }) {
  const [errorTitle, setErrorTitle] = React.useState('')
  const [errorMessage, setErrorMessage] = React.useState('')

  /** Simple function that triggers the Error State UI to appear instead of Router-matched container.
   *
   * @param {object} props
   * @param {string} props.title - Concise title to inform the user of category/type of error.
   * @param {string} props.message - Concise message to inform the user of specifics of the error.
   * */
  function throwFriendlyErrorPage({
    message,
    title = 'Something Went Wrong...',
  }) {
    setErrorMessage(message)
    setErrorTitle(title)
  }
  const location = useLocation()

  // physically and metaphorically
  function clearTheError() {
    setErrorMessage('')
    setErrorTitle('')
  }

  // clear the error state on page change
  React.useEffect(() => {
    clearTheError()
  }, [location])

  const hasError = Boolean(errorTitle || errorMessage)
  const value = { throwFriendlyErrorPage, clearTheError }

  return (
    <FriendlyErrorPageContext.Provider value={value}>
      {hasError ? (
        <FriendlyErrorPage title={errorTitle} message={errorMessage} />
      ) : (
        children
      )}
    </FriendlyErrorPageContext.Provider>
  )
}

FriendlyErrorPageProvider.propTypes = {
  children: PropTypes.node,
}

function useFriendlyErrorPage() {
  const context = React.useContext(FriendlyErrorPageContext)
  if (context === undefined) {
    throw new Error(
      'useFriendlyErrorState must be used within a FriendlyErrorPageProvider',
    )
  }
  return context
}

/**
 * Higher-Order Component that enables class-based components to use the throwFriendlyErrorPage hook.
 *
 * @param {React.ReactElement} Component - The component element.
 *
 * @returns {React.ReactElement} - The modified component element.
 */
const withFriendlyErrorPage = (Component) => {
  // eslint-disable-next-line react/display-name
  return (props) => {
    const { throwFriendlyErrorPage } = useFriendlyErrorPage()

    return (
      <Component
        throwFriendlyErrorPage={throwFriendlyErrorPage}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
    )
  }
}

export default FriendlyErrorPageContext

export {
  FriendlyErrorPageProvider,
  FriendlyErrorPageContext,
  useFriendlyErrorPage,
  withFriendlyErrorPage,
}
