/**
 * @module localStorage
 */
import { LOCAL_STORAGE } from './constants'

/**
 * Function to retrieve and update the specified Local Storage item. When
 * updating an array or object, this assumes to override any existing values
 * found in the Local Storage item retrieved for the specified key rather than
 * using and updating it. For numbers and strings, any existing value will be
 * overwritten with the new value as well.
 *
 * @param {object} params - The function params object.
 * @param {string} params.key - The Local Storage item key.
 * @param {string} params.type - The valid and supported type object type of the item to store (e.g. 'object', 'string').
 * @param {Array|number|object|string} params.value - The value of the item to store.
 *
 * @see Supported types located in {@link src/utils/constants.js}.
 *
 * @throws {Error} - Throws an error if function params are incorrect or missing.
 *
 * @returns {object} - The updated Local Storage item.
 */
export function updateLocalStorageItem({
  key,
  type = LOCAL_STORAGE.TYPES.STRING,
  value,
}) {
  // Check for valid key and value.
  if (!key || !value) {
    throw new Error(`Invalid ${!key ? 'key' : 'value'} specified.`)
  }

  // Check for valid type.
  if (!Object.values(LOCAL_STORAGE.TYPES).includes(type)) {
    throw new Error(
      `Invalid type specified. Type must be one of type: ${Object.values(
        LOCAL_STORAGE.TYPES,
      )}.`,
    )
  }

  // If array or object, use JSON.parse/JSON.stringify to retrieve and set the
  // value when updating. Otherwise, simply set the item.
  if (
    type === LOCAL_STORAGE.TYPES.ARRAY ||
    type === LOCAL_STORAGE.TYPES.OBJECT
  ) {
    const newData =
      type === LOCAL_STORAGE.TYPES.ARRAY ? [...value] : { ...value }
    window.localStorage.setItem(key, JSON.stringify(newData))
    return newData
  }

  return window.localStorage.setItem(key, value)
}
