/**
 * @module PushPinIcon
 */
import React from 'react'
import PropTypes from 'prop-types'
import { gray } from '@youversion/react/styles/colors-v3'

/**
 * Represents a push pin icon.
 *
 * @param {object} props - The component props object.
 * @param {string} [props.borderColor] - The border color of the icon.
 * @param {string} [props.fillColor] - The fill color of the icon.
 * @param {object} [props.style] - A style object to apply to the icon.
 *
 * @returns {React.ReactElement} - The PushPinIcon element.
 */
export default function PushPinIcon({ borderColor, fillColor, style }) {
  return (
    <>
      <svg
        fill="none"
        height="16"
        role="img"
        style={style}
        viewBox="0 0 10 16"
        width="16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <title>Push Pin</title>
        <path
          d="M8 8V1.6H8.8V0H0.8V1.6H1.6V8L0 9.6V11.2H4.16V16H5.44V11.2H9.6V9.6L8 8Z"
          fill={fillColor}
        />
        <path
          d="M8.33333 8V1.6H9.16667V0H0.833333V1.6H1.66667V8L0 9.6V11.2H4.33333V16H5.66667V11.2H10V9.6L8.33333 8ZM2.33333 9.6L3.33333 8.64V1.6H6.66667V8.64L7.66667 9.6H2.33333Z"
          fill={borderColor}
        />
      </svg>
    </>
  )
}

PushPinIcon.propTypes = {
  borderColor: PropTypes.string,
  fillColor: PropTypes.string,
  style: PropTypes.objectOf(PropTypes.string),
}

PushPinIcon.defaultProps = {
  borderColor: null,
  fillColor: gray[10],
  style: null,
}
