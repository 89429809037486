import * as Sentry from '@sentry/react'

/**
 * Copied from Tommy's publisher-add-language.
 */
export default async function handlePresignedUpload(file, url, params) {
  const formData = new FormData()
  if (!file) {
    throw new Error('File not found. Please try again.')
  }
  Object.entries(params).forEach(([key, value]) => {
    formData.append(key, value)
  })

  formData.append('file', file)

  const response = fetch(url, { method: 'POST', body: formData })
    .then((fetchResponse) => {
      return fetchResponse
    })
    .catch((error) => {
      Sentry.captureException(error)
    })
  return response
}
