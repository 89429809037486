import React from 'react'
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  withStyles,
} from '@material-ui/core'
import PropTypes from 'prop-types'

const styles = {
  grow: {
    flexGrow: 1,
  },
}

function DataTable({
  dataSource,
  HeadComponent,
  RowComponent,
  pager,
  addNew,
  viewAll,
  onDelete,
  onEdit,
  onSelect,
  initialized,
  lang,
}) {
  return (
    <>
      <Table component="div">
        {HeadComponent && (
          <TableHead component="div">
            <HeadComponent />
          </TableHead>
        )}
        <TableBody component="div">
          {initialized && dataSource.length < 1 ? (
            <TableRow>
              <h1>Nothing to see here</h1>
            </TableRow>
          ) : null}
          {dataSource.map((data) => (
            <RowComponent
              data={data}
              key={`${data.id}${data.title}`}
              lang={lang}
              onDelete={onDelete}
              onEdit={onEdit}
              onSelect={onSelect}
            />
          ))}
        </TableBody>
      </Table>
      {addNew}
      {pager || viewAll}
    </>
  )
}

DataTable.propTypes = {
  addNew: PropTypes.node,
  dataSource: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  HeadComponent: PropTypes.func,
  initialized: PropTypes.bool,
  lang: PropTypes.string,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  onSelect: PropTypes.func,
  pager: PropTypes.node,
  RowComponent: PropTypes.func.isRequired,
  viewAll: PropTypes.node,
}

DataTable.defaultProps = {
  HeadComponent: null,
  addNew: null,
  viewAll: null,
  onDelete: null,
  onEdit: null,
  onSelect: null,
  pager: null,
  initialized: null,
  lang: null,
}

export default withStyles(styles)(DataTable)
