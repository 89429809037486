import React from 'react'
import PropTypes from 'prop-types'
import {
  Card,
  CardContent,
  CardActions,
  CircularProgress,
  Collapse,
  Button,
  Box,
  Tooltip,
  Typography,
  makeStyles,
} from '@material-ui/core'
import Collections from '../../../../tupos/models/collections'
import CollectionLanguageFields from '../../../views/collection/collection-language-fields'
import Publisher from '../../../../tupos/models/publisher'
import PublisherLanguageFields from '../../../views/publisher/publisher-language-fields'
import Video from '../../../../tupos/models/video'
import VideoLanguageFields from '../../../views/video/video-language-fields'
import LanguageSelector from '../../language-selector'
import Languages from '../../../../tupos/models/languages'
import { statusTypes } from '../../../../utils/constants'
import { useAlert } from '../../alerts'

const useStyles = makeStyles((theme) => ({
  cardTitle: {
    marginBottom: theme.spacing(2),
  },
  root: {
    backgroundColor: 'white',
    borderRadius: theme.spacing(2),
    padding: theme.spacing(1),
    marginTop: theme.spacing(2),
  },
  delete: {
    color: theme.danger,
  },
  textField: {
    marginBottom: 15,
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}))

function initializeObject(type, initialObject) {
  switch (type) {
    case 'collection': {
      const result = new Collections({ title: '', description: '' })
      const resultObject = result.toObject()
      return resultObject
    }
    case 'publisher': {
      const result = new Publisher({ name: '', description: '' })
      const resultObject = result.toObject()
      return resultObject
    }
    case 'video': {
      const {
        publisher_id: publisherId,
        orientation,
        type: videoType,
        id,
        references,
      } = initialObject
      const result = new Video({
        name: '',
        description: '',
        language: '',
        publisher_id: publisherId,
        orientation,
        type: videoType,
        id,
        references,
      })
      const resultObject = result.toObject()
      return resultObject
    }
    default:
      return null
  }
}

function LanguageFields({ type, object, onInputChange, onValidate }) {
  function renderTypeFields() {
    switch (type) {
      case 'collection':
        return (
          <CollectionLanguageFields
            collection={object}
            onInputChange={onInputChange}
            onValidate={onValidate}
          />
        )
      case 'publisher':
        return (
          <PublisherLanguageFields
            publisher={object}
            onInputChange={onInputChange}
            onValidate={onValidate}
          />
        )
      case 'video':
        return (
          <VideoLanguageFields
            variant="add-language"
            video={object}
            onInputChange={onInputChange}
            onValidate={onValidate}
          />
        )
      default:
        return null
    }
  }

  return <>{renderTypeFields()}</>
}

LanguageFields.propTypes = {
  type: PropTypes.string.isRequired,
  object: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool,
      PropTypes.shape({}),
      PropTypes.arrayOf(
        PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      ),
    ]),
  ).isRequired,
  onInputChange: PropTypes.func.isRequired,
  onValidate: PropTypes.func.isRequired,
}

function LanguageAddCard({
  type,
  onSave,
  languages,
  saveButtonLabel,
  // For video, I had to pass the initial object to get that video's data.
  // Adding a new video language requires some of the data from the original video.
  initialObject = {},
}) {
  const classes = useStyles()

  const { throwAlert } = useAlert()
  const [language, setLanguage] = React.useState('')
  const [object, setObject] = React.useState({
    ...initializeObject(type, initialObject),
  })
  const [saving, setSaving] = React.useState({ status: statusTypes.IDLE })
  const [languageList, setLanguageList] = React.useState([])
  const [displayCard, setDisplayCard] = React.useState(false)
  const [errorMessages, setErrorMessages] = React.useState([])

  const handleValidation = React.useCallback(
    (hasErrors, itemId, errorMessage) => {
      // If we pass in 'true'
      if (hasErrors) {
        setErrorMessages(
          (prevErrors) =>
            // check if error message already exists
            prevErrors.some((error) => error.itemId === itemId)
              ? prevErrors // error exists, don't add it to the list
              : [...prevErrors, { itemId, message: errorMessage }], // error does not exist, add it to the list
        )
      } else {
        // If false, filter out errors that match this item's id
        setErrorMessages((prevState) => {
          return prevState.filter((error) => error.itemId !== itemId)
        })
      }
    },
    [],
  )

  function handleLanguageChange(selectedLanguage) {
    setLanguage(selectedLanguage)
  }

  function handleInputChange(event) {
    const {
      target: { name, value, type: eventType, files },
    } = event
    setObject({
      ...object,
      [name]: eventType === 'file' ? files[0] : value,
    })
  }

  React.useEffect(() => {
    if (!language) {
      handleValidation(true, 'new_language_selector', 'Language is required')
    } else {
      handleValidation(false, 'new_language_selector')
    }
  }, [language, handleValidation])

  React.useEffect(() => {
    async function handleFetchLanguages() {
      try {
        let languageResponse = []
        if (type === 'video') {
          languageResponse = await Languages.get(
            'publisher',
            object.publisher_id,
          )
        } else {
          languageResponse = await Languages.get(type)
        }
        const availableLanguages = languageResponse.filter((lang) => {
          return !languages.includes(lang)
        })
        setLanguageList(availableLanguages)
      } catch {
        throwAlert({
          type: 'error',
          id: `${type}_language_fetch_error`,
          message: `There was an error fetching ${type} languages.`,
        })
      }
    }

    if (type === 'video') {
      if (object.publisher_id) {
        handleFetchLanguages()
      }
    } else {
      handleFetchLanguages()
    }
  }, [languages, type, throwAlert, object.publisher_id])

  async function handleSave() {
    try {
      setSaving({ status: statusTypes.PENDING })
      await onSave({ object, language })

      // Reset the form
      setSaving({ status: statusTypes.IDLE })
      setLanguage('')
      setObject({ ...initializeObject(type, initialObject) })
      setDisplayCard(false)
    } catch (error) {
      setSaving({ status: statusTypes.ERROR, error: { message: error } })
    }
  }

  return (
    <Box mt={2}>
      {!displayCard ? (
        <Box display="flex" justifyContent="flex-start" width="100%">
          <Tooltip
            title={`There are no more available languages to add to this ${type}.`}
            disableHoverListener={Boolean(languageList.length > 0)}
            placement="top"
          >
            <span>
              <Button
                disabled={Boolean(languageList.length === 0)}
                variant="outlined"
                color="default"
                onClick={() => setDisplayCard(!displayCard)}
              >
                Add a Language
              </Button>
            </span>
          </Tooltip>
        </Box>
      ) : null}
      <Collapse in={Boolean(displayCard && languages.length)}>
        <Card className={classes.root} elevation={0} variant="outlined">
          <CardContent>
            <Typography className={classes.cardTitle} variant="h2">
              Add a new language to this {type}
            </Typography>
            {languageList.length > 0 ? (
              <Box mb={2}>
                <LanguageSelector
                  langs={languageList}
                  current={language}
                  changed={handleLanguageChange}
                />
              </Box>
            ) : null}
            {object ? (
              <LanguageFields
                type={type}
                object={object}
                onInputChange={handleInputChange}
                onValidate={handleValidation}
              />
            ) : null}
          </CardContent>
          <CardActions>
            <Tooltip
              placement="right-end"
              disableHoverListener={Boolean(errorMessages.length === 0)}
              title={errorMessages.map((error) => {
                return <p key={error.itemId}>{error.message}</p>
              })}
            >
              <span>
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={handleSave}
                  disabled={Boolean(
                    errorMessages.length > 0 ||
                      saving.status === statusTypes.PENDING,
                  )}
                >
                  {saving.status === statusTypes.IDLE ? saveButtonLabel : null}
                  {saving.status === statusTypes.ERROR ? 'Error' : null}
                  {saving.status === statusTypes.SUCCESS ? 'Success!' : null}
                  {saving.status === statusTypes.PENDING ? (
                    <>
                      Saving
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    </>
                  ) : null}
                </Button>
              </span>
            </Tooltip>
            <Button
              variant="text"
              onClick={() => setDisplayCard((prevBool) => !prevBool)}
            >
              Cancel
            </Button>
          </CardActions>
        </Card>
      </Collapse>
    </Box>
  )
}

LanguageAddCard.propTypes = {
  type: PropTypes.string.isRequired,
  languages: PropTypes.arrayOf(PropTypes.string).isRequired,
  onSave: PropTypes.func.isRequired,
  saveButtonLabel: PropTypes.string,
  initialObject: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({}),
      PropTypes.number,
      PropTypes.arrayOf(PropTypes.string),
    ]),
  ),
}

LanguageAddCard.defaultProps = {
  saveButtonLabel: 'Save',
  initialObject: {},
}

export default LanguageAddCard
