import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  Checkbox,
  ListItemIcon,
  ListItemText,
  ListItemAvatar,
  Avatar,
} from '@material-ui/core'
import DragIndicatorIcon from '@material-ui/icons/DragIndicator'
import { sortableHandle } from 'react-sortable-hoc'
import Video from '../../../../../tupos/models/video'
import Publisher from '../../../../../tupos/models/publisher'
import urlReplacer from '../../../../../utils/url-replacer'
import Configuration from '../../../../../tupos/models/configuration'

const VideoSelectorItem = (props) => {
  const { videoId, video, isChecked, isDraggable, onCheck, language } = props

  const DragHandle = sortableHandle(() => (
    <ListItemIcon edge="end">
      <DragIndicatorIcon />
    </ListItemIcon>
  ))

  const [checked, setChecked] = useState(isChecked)
  const [thumbnailUrl, setThumbnailUrl] = useState()

  const handleCheck = (event) => {
    setChecked(event.target.checked)
    onCheck(event.target.checked, videoId)
  }

  let videoTitle = ''
  let publisherName = ''

  const [videoInfo, setVideoInfo] = useState()
  const [publisherInfo, setPublisherInfo] = useState()

  useEffect(() => {
    if (video) {
      setVideoInfo(video)
    } else {
      const videoRequest = async () => {
        const videoResult = await Video.get(videoId, language)
        setVideoInfo(videoResult)
      }
      videoRequest()
    }
  }, [video, language, videoId])

  // Get thumbnail

  useEffect(() => {
    const fetchThumbnail = async () => {
      // get the configuration
      const config = await Configuration.get()
      setThumbnailUrl(
        urlReplacer(config.imageUrls.video, {
          width: '87',
          height: '45',
          id: videoId,
          language,
        }),
      )
    }
    fetchThumbnail()
  }, [videoInfo, language, videoId])

  useEffect(() => {
    if (videoInfo) {
      const publisherRequest = async () => {
        const publisherResult = await Publisher.get(
          videoInfo.publisherId,
          undefined,
          language,
        )
        setPublisherInfo(publisherResult)
      }
      publisherRequest()
    }
  }, [videoInfo, language])

  if (videoInfo) {
    videoTitle = videoInfo.title
  }
  if (publisherInfo) {
    publisherName = publisherInfo.name
  }

  return (
    <>
      {isDraggable ? <DragHandle /> : null}

      <ListItemIcon>
        <Checkbox
          color="default"
          edge="start"
          checked={checked}
          onChange={handleCheck}
          value={videoId}
        />
      </ListItemIcon>
      <ListItemAvatar>
        <Avatar variant="square" alt={videoTitle} src={thumbnailUrl} />
      </ListItemAvatar>
      <ListItemText
        primary={videoTitle}
        secondary={`${publisherName} • ${videoId} • ${videoInfo?.orientation}`}
      />
    </>
  )
}

VideoSelectorItem.propTypes = {
  videoId: PropTypes.number.isRequired,
  isChecked: PropTypes.bool,
  isDraggable: PropTypes.bool,
  video: PropTypes.shape({}),
  onCheck: PropTypes.func.isRequired,
  language: PropTypes.string.isRequired,
}
VideoSelectorItem.defaultProps = {
  video: null,
  isChecked: false,
  isDraggable: false,
}
export default VideoSelectorItem
