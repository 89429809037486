/* eslint-disable no-console */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { Component } from 'react'
import { Box, Grid, withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import SecondaryAppBar from '../../components/layout/secondary-app-bar'
import DataTable from '../../components/layout/data-table'
import PublisherHead from '../../components/views/publisher/publisher-head'
import PublisherRow from '../../components/views/publisher/publisher-row'
import Pager from '../../components/ui/pager'
import getParamsFromProps from '../../utils/get-params-from-props'
import Publisher from '../../tupos/models/publisher'
import LanguageSelector from '../../components/ui/language-selector'
import Languages from '../../tupos/models/languages'

const styles = (theme) => ({
  content: {
    maxWidth: 960,
    margin: 'auto',
    padding: theme.spacing(2),
  },
})

class PublisherList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      initialized: false,
      publishers: [],
      nextPage: false,
      pageSize: '',
      languages: [],
    }
  }

  componentDidMount() {
    this.loadData()
  }

  componentDidUpdate(prevProps) {
    if (
      getParamsFromProps(this.props, 'page', '1') !==
        getParamsFromProps(prevProps, 'page', '1') ||
      getParamsFromProps(this.props, 'lang', 'en') !==
        getParamsFromProps(prevProps, 'lang', 'en')
    ) {
      this.loadData()
    }
  }

  loadData = async () => {
    await this.setState({
      loading: true,
    })

    const urlLanguage = getParamsFromProps(this.props, 'lang', 'en')
    const urlPage = getParamsFromProps(this.props, 'page', '1')

    try {
      const { rows, nextPage, pageSize } = await Publisher.getCollection(
        undefined,
        urlPage,
        undefined,
        urlLanguage,
      )

      let publishers = rows
      if (publishers.length > 0) {
        publishers = [
          ...publishers.map((publisher) => {
            const publisherObject = publisher.toObject()
            return Publisher.toClass({
              json: publisherObject,
              language: urlLanguage,
            })
          }),
        ]
      }

      this.setState(
        {
          publishers,
          nextPage,
          pageSize,
        },
        () => {
          setTimeout(() => {
            this.setState({
              loading: false,
              initialized: true,
            })
          }, 100)
        },
      )
    } catch (error) {
      Sentry.captureException(error)
      this.setState({
        publishers: [],
        nextPage: false,
        pageSize: [],
        loading: false,
        initialized: true,
      })
    }

    // Get available collection languages
    try {
      const languagesResponse = await Languages.get('collection')
      this.setState({
        languages: languagesResponse,
        currentLanguage: urlLanguage,
      })
    } catch (error) {
      Sentry.captureException(error)
    }
  }

  handleLanguageChange = (selectedLanguage) => {
    const { history } = this.props
    history.push(`/publishers?lang=${selectedLanguage}`)
  }

  render() {
    const { classes } = this.props
    const {
      publishers,
      loading,
      initialized,
      nextPage,
      pageSize,
      languages,
      currentLanguage,
    } = this.state

    return (
      <>
        <SecondaryAppBar title="Publishers" />
        <Grid className={classes.content} container={true}>
          <Grid container={true} item={true} justify="center" xs={12}>
            <Box mb={2}>
              <LanguageSelector
                changed={this.handleLanguageChange}
                current={currentLanguage}
                langs={languages}
                variant="standard"
              />
            </Box>
          </Grid>
          <DataTable
            dataSource={publishers}
            HeadComponent={PublisherHead}
            initialized={initialized}
            pager={
              <Pager
                dataLength={publishers.length}
                hasNextPage={nextPage}
                loading={loading}
                onNext={true}
                onPrevious={true}
                pageNumber={getParamsFromProps(this.props, 'page', '1')}
                pageSize={pageSize}
              />
            }
            RowComponent={PublisherRow}
          />
        </Grid>
      </>
    )
  }
}

PublisherList.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  history: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.shape({}),
      PropTypes.func,
    ]),
  ).isRequired,
}
export default withRouter(withStyles(styles)(PublisherList))
