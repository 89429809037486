/**
 * @module AddContentDropDown
 */

import React from 'react'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grow from '@material-ui/core/Grow'
import {
  Fab,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  makeStyles,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import { Link } from 'react-router-dom'

const useStyles = makeStyles({
  root: {
    display: 'flex',
  },
})

/**
 * AddContentDropDown button to add contents such as video, publisher, or collection.
 *
 * @alias module:AddContentDropdown
 *
 * @returns {React.ReactElement} - The AddContentDropdown component.
 *
 * @example
 * <AddContentDropdown />
 */
export default function AddContentDropDown() {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)
  const [placement, setPlacement] = React.useState(null)
  const [anchorEl, setAnchorEl] = React.useState(null)

  function handleToggle(position) {
    setOpen((prevState) => !prevState)
    setPlacement(position)
  }

  function handleClose(event) {
    if (anchorEl.contains(event.target)) {
      return
    }
    setOpen(false)
    setPlacement(null)
  }

  return (
    <div className={classes.root}>
      <Fab
        size="small"
        buttonRef={(node) => {
          setAnchorEl(node)
        }}
        color="default"
        aria-haspopup="true"
        aria-label="Add item button"
        onClick={() => handleToggle('bottom')}
      >
        <AddIcon />
      </Fab>
      <Popper
        open={open}
        anchorEl={anchorEl}
        placement={placement}
        transition={true}
        disablePortal={true}
      >
        {({ TransitionProps }) => (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <Grow {...TransitionProps} id="menu-list-grow">
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList>
                  <MenuItem
                    onClick={handleClose}
                    component={Link}
                    to="/videos/add"
                  >
                    New Video
                  </MenuItem>
                  <MenuItem
                    onClick={handleClose}
                    component={Link}
                    to="/publisher/add"
                  >
                    New Publisher
                  </MenuItem>
                  <MenuItem
                    onClick={handleClose}
                    component={Link}
                    to="/collections/add"
                  >
                    New Collection
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  )
}
