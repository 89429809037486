/**
 * @module CollectionList
 */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Collapse,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core'
import {
  getHardCodedFeaturedCollections,
  getParamsFromProps,
  replaceUrlParam,
} from 'utils'
import TranslateIcon from '@material-ui/icons/Translate'
import { SortableStack } from '@youversion/react'
import { gray } from '@youversion/react/styles/colors-v3'
import appLanguages from 'tupos/models/languages/app-languages.json'
import Configuration from 'tupos/models/configuration'
import CollectionCarousel from 'components/views/collection/collection-carousel'
import PinnableCollectionCarousel from 'components/views/collection/collection-carousel/pinnable-collection-carousel'
import SecondaryAppBar from '../../components/layout/secondary-app-bar'
import Collections from '../../tupos/models/collections'
import LanguageSelector from '../../components/ui/language-selector'
import Languages from '../../tupos/models/languages'
import { withFriendlyErrorPage } from '../../contexts/friendly-error-message-context'
import PushPinIcon from '../../components/ui/icons/push-pin'

/**
 * @typedef SimpleCollection
 * @property {number} id - The unique collection id.
 */

const styles = (theme) => ({
  backdrop: {
    color: 'white',
    zIndex: theme.zIndex.drawer + 1,
  },
  collectionHeader: {
    background: 'white',
    position: 'sticky',
    top: '64px', // 64px is the height of the App header.
    width: '100%',
    zIndex: '1200', // The App header has zIndex of 1201, so 1 level below keeps this under the drop shadow.
  },
  collectionHeaderButton: {
    '&:last-of-type': {
      marginRight: '0',
    },
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
  },
  content: {
    margin: 'auto',
    maxWidth: 960,
    padding: theme.spacing(2),
  },
})

const landscapeCollection = getHardCodedFeaturedCollections('landscape')

class CollectionList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      collections: [],
      featuredVideoCollections: [],
      initialized: false,
      languages: [],
      lastSavedPinnedAndOrderedCollections: null,
      loading: false,
      nextPage: false,
      openLanguagesModal: false,
      page: 1,
      pageSize: '',
      pinnedAndOrderedCollections: null,
      unorderedCollections: null,
    }
  }

  componentDidMount() {
    this.loadData()
  }

  componentDidUpdate(prevProps) {
    if (
      getParamsFromProps(this.props, 'lang', 'en') !==
      getParamsFromProps(prevProps, 'lang', 'en')
    ) {
      this.loadData()
    }
  }

  handleModalOpen = () => {
    this.setState({ openLanguagesModal: true })
  }

  handleModalClose = () => {
    this.setState({ openLanguagesModal: false })
  }

  loadData = async () => {
    await this.setState({
      loading: true,
    })

    const urlLanguage = getParamsFromProps(this.props, 'lang', 'en')
    const { page: urlPage } = this.state
    const { throwFriendlyErrorPage } = this.props

    try {
      const configResult = await Configuration.get()
      const { rows, nextPage } = await Collections.getCollection({
        language: urlLanguage,
        page: urlPage,
        pageSize: 50,
        showSeasonal: true,
      })

      const { collections: storedCollections, page: storedPage } = this.state
      let combinedCollections = storedCollections.concat(rows)
      this.setState({
        collections: combinedCollections,
        config: configResult,
        nextPage,
        page: parseInt(storedPage, 10) + 1,
        pageSize: 50,
      })

      // If there is a next page, trigger re-call of this function to get the
      // next set of pages to append to the data. Otherwise, proceed with the
      // data retrieval for featured and ordered collections, as well as the
      // logic to filter pinned and ordered collections and finally set state.
      if (nextPage) {
        this.loadData()
      } else {
        const featuredVideoCollections = await Collections.get(
          landscapeCollection.id,
        )
        const orderedCollectionIds = await Collections.getCollectionPlaylist({
          isOrdered: true,
          language: urlLanguage,
        })
        const filteredPinnedOrderedCollections = combinedCollections.filter(
          (item) => {
            return orderedCollectionIds.includes(item.id)
          },
        )

        // Important: Simply filtering the collections doesn't result in the
        // same order as is returned from `getCollectionPlaylist`. As such, the
        // need exists to iterate over the filtered collections and assign a
        // specific index slot for each collection based on the index slot of
        // the collection id from the ordered collection ids array.
        const pinnedAndOrderedCollections = new Array(
          filteredPinnedOrderedCollections.length,
        )
        filteredPinnedOrderedCollections.forEach((collection) => {
          pinnedAndOrderedCollections[
            orderedCollectionIds.indexOf(collection.id)
          ] = collection
        })

        this.setState({
          featuredVideoCollections,
          lastSavedPinnedAndOrderedCollections: pinnedAndOrderedCollections,
          pinnedAndOrderedCollections,
          unorderedCollections: combinedCollections.filter((item) => {
            return !orderedCollectionIds.includes(item.id)
          }),
        })
        if (combinedCollections.length > 0) {
          // Attach language to collections
          combinedCollections = [
            ...combinedCollections.map((collection) => {
              const collectionObject = collection.toObject()
              return Collections.toClass({
                json: collectionObject,
                language: urlLanguage,
              })
            }),
          ]
        }

        this.setState(
          {
            collections: combinedCollections,
          },
          () => {
            setTimeout(() => {
              this.setState({
                initialized: true,
                loading: false,
              })
            }, 100)
          },
        )
      }
    } catch (error) {
      throwFriendlyErrorPage({
        message: `Failed to load list of collections, based on (${urlLanguage}) language.`,
        title: 'Collections may not exist',
      })
      this.setState({
        collections: [],
        initialized: false,
        loading: false,
      })
    }

    // Get available collection languages
    try {
      const languagesResponse = await Languages.get('collection')
      this.setState({
        currentLanguage: urlLanguage,
        languages: languagesResponse,
      })
    } catch (error) {
      throwFriendlyErrorPage({
        message: `Failed to load collection languages, based on (${urlLanguage}) language.`,
        title: 'Collections may not exist',
      })
    }
  }

  handleLanguageChange = (selectedLanguage) => {
    const { history } = this.props
    const { currentLanguage } = this.state

    if (currentLanguage !== selectedLanguage) {
      // update url param for new lang
      history.push(replaceUrlParam(history.location, 'lang', selectedLanguage))
      this.handleModalClose()
      window.location.reload()
    }
  }

  /**
   * Handler function for collection order change, which updates state for
   * pinned collections.
   *
   * @param {Function} getNewCollectionOrder - Function to retrieve new collection order..
   */
  handleCollectionOrderChange = (getNewCollectionOrder) => {
    const { pinnedAndOrderedCollections } = this.state
    this.setState({
      pinnedAndOrderedCollections: getNewCollectionOrder(
        pinnedAndOrderedCollections,
      ),
    })
  }

  /**
   * Handler function for adding a pinned collection, which updates state for
   * pinned and unordered collections.
   *
   * @param {SimpleCollection} collectionData - The SimpleCollection data object.
   */
  handleAddPinnedCollection = (collectionData) => {
    const { pinnedAndOrderedCollections, unorderedCollections } = this.state
    this.setState({
      pinnedAndOrderedCollections: [
        ...pinnedAndOrderedCollections,
        unorderedCollections.find((item) => {
          return item.id === collectionData.id
        }),
      ],
      unorderedCollections: unorderedCollections.filter((item) => {
        return item.id !== collectionData.id
      }),
    })
  }

  /**
   * Handler function for removing a pinned collection, which updates state for
   * pinned and unordered collections.
   *
   * @param {SimpleCollection} collectionData - The SimpleCollection data object.
   */
  handleRemovePinnedCollection = (collectionData) => {
    const { pinnedAndOrderedCollections, unorderedCollections } = this.state
    this.setState({
      pinnedAndOrderedCollections: pinnedAndOrderedCollections.filter(
        (item) => {
          return item.id !== collectionData.id
        },
      ),
      unorderedCollections: [
        pinnedAndOrderedCollections.find((item) => {
          return item.id === collectionData.id
        }),
        ...unorderedCollections,
      ],
    })
  }

  /**
   * Handler function for canceling a change in the UI for (un)pinned and
   * ordered collections.
   */
  handleCancelPinnedOrderingChange = () => {
    const {
      collections,
      lastSavedPinnedAndOrderedCollections,
      unorderedCollections,
    } = this.state
    const filteredUnorderedCollections = collections.filter((item) => {
      const inLastSaved = Boolean(
        lastSavedPinnedAndOrderedCollections.filter((entry) => {
          return entry.id === item.id
        }).length,
      )
      return !inLastSaved
    })
    this.setState({
      pinnedAndOrderedCollections: lastSavedPinnedAndOrderedCollections,
      unorderedCollections: filteredUnorderedCollections?.length
        ? filteredUnorderedCollections
        : unorderedCollections,
    })
  }

  /**
   * Handler function for saving changes made in the UI for (un)pinned and
   * ordered collections.
   */
  handleUpdatePinnedOrderingChange = async () => {
    const { throwFriendlyErrorPage } = this.props
    const { collections, pinnedAndOrderedCollections } = this.state
    const urlLanguage = getParamsFromProps(this.props, 'lang', 'en')
    this.setState({
      loading: true,
    })
    try {
      const orderedCollectionIds = pinnedAndOrderedCollections.map((item) => {
        return item.id
      })
      await Collections.updateCollectionPlaylist({
        language: urlLanguage,
        orderedCollectionIds,
      })
      this.setState({
        lastSavedPinnedAndOrderedCollections: pinnedAndOrderedCollections,
        loading: false,
        pinnedAndOrderedCollections,
        unorderedCollections: collections.filter((item) => {
          return !orderedCollectionIds.includes(item.id)
        }),
      })
    } catch (error) {
      this.setState({
        loading: false,
      })
      throwFriendlyErrorPage({
        message: `Failed to update changes to pinned collection order.`,
      })
    }
  }

  render() {
    const { classes } = this.props
    const {
      config,
      currentLanguage,
      featuredVideoCollections,
      initialized,
      languages,
      loading,
      openLanguagesModal,
      lastSavedPinnedAndOrderedCollections,
      pinnedAndOrderedCollections,
      unorderedCollections,
    } = this.state

    if (!currentLanguage) return null

    return (
      <>
        <SecondaryAppBar
          actionComponent={
            <Tooltip title="Change Collection Language">
              <IconButton
                aria-label="Change Collection Language"
                onClick={this.handleModalOpen}
              >
                <TranslateIcon color="action" />
              </IconButton>
            </Tooltip>
          }
          subtitle={appLanguages[currentLanguage]?.english}
          title="Collections"
        />

        {/* Featured Collections */}
        <Grid className={classes.content} container={true} spacing={2}>
          <Grid className={classes.collectionHeader} item={true} xs={12}>
            <Typography color="textPrimary" variant="h2">
              Featured
            </Typography>
          </Grid>
          <Grid item={true} xs={12}>
            {featuredVideoCollections ? (
              <>
                <CollectionCarousel
                  collection={featuredVideoCollections}
                  config={config}
                  language={getParamsFromProps(this.props, 'lang', 'en')}
                />
              </>
            ) : (
              <Typography>No featured collections were found.</Typography>
            )}
          </Grid>
          <Grid item={true} xs={12}>
            <Divider />
          </Grid>
        </Grid>

        {/* Pinned Collections */}
        <Collapse
          in={Boolean(
            pinnedAndOrderedCollections?.length ||
              lastSavedPinnedAndOrderedCollections !==
                pinnedAndOrderedCollections,
          )}
        >
          <Grid className={classes.content} container={true} spacing={2}>
            <Grid className={classes.collectionHeader} item={true}>
              <Box
                alignItems="center"
                display="flex"
                justifyContent="space-between"
              >
                <Box position="relative">
                  <Typography color="textPrimary" variant="h2">
                    <PushPinIcon fillColor={gray[50]} /> Pinned
                  </Typography>
                </Box>
                {lastSavedPinnedAndOrderedCollections !==
                pinnedAndOrderedCollections ? (
                  <Box position="relative">
                    <Button
                      className={classes.collectionHeaderButton}
                      color="default"
                      onClick={this.handleCancelPinnedOrderingChange}
                      variant="outlined"
                    >
                      Cancel
                    </Button>
                    <Button
                      className={classes.collectionHeaderButton}
                      color="primary"
                      onClick={this.handleUpdatePinnedOrderingChange}
                      variant="contained"
                    >
                      Update
                    </Button>
                  </Box>
                ) : null}
              </Box>
            </Grid>
            <Grid item={true} xs={12}>
              {pinnedAndOrderedCollections?.length ? (
                <SortableStack
                  direction="vertical"
                  onChange={this.handleCollectionOrderChange}
                  useRenderProps={true}
                >
                  {pinnedAndOrderedCollections.map((item) => {
                    return (
                      <React.Fragment
                        key={item.title.toLowerCase().replace(/ /g, '-')}
                      >
                        {(provided, snapshot) => (
                          <PinnableCollectionCarousel
                            collection={item}
                            config={config}
                            dragHandleProps={provided.dragHandleProps}
                            innerRef={provided.innerRef}
                            isPinned={true}
                            language={getParamsFromProps(
                              this.props,
                              'lang',
                              'en',
                            )}
                            onPinClick={this.handleRemovePinnedCollection}
                            raised={Boolean(snapshot.isDragging)}
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...provided.draggableProps}
                          />
                        )}
                      </React.Fragment>
                    )
                  })}
                </SortableStack>
              ) : (
                <Box ml={2.5}>
                  <Typography>No Collections have been pinned yet.</Typography>
                </Box>
              )}
            </Grid>
            <Grid item={true} xs={12}>
              <Divider />
            </Grid>
          </Grid>
        </Collapse>

        {/* Remaining Collections */}
        <Grid className={classes.content} container={true} spacing={2}>
          <Grid className={classes.collectionHeader} item={true} xs={12}>
            <Typography color="textPrimary" variant="h2">
              Remaining
            </Typography>
          </Grid>
          <Grid item={true} xs={12}>
            {unorderedCollections?.length ? (
              <>
                {unorderedCollections.map((item) => {
                  return (
                    <PinnableCollectionCarousel
                      collection={item}
                      config={config}
                      disableDrag={true}
                      dragHandleProps={null}
                      innerRef={null}
                      isPinned={false}
                      key={`remaining-collection-${item.title
                        .toLowerCase()
                        .replace(/ /g, ' ')}`}
                      language={getParamsFromProps(this.props, 'lang', 'en')}
                      onPinClick={this.handleAddPinnedCollection}
                      raised={false}
                    />
                  )
                })}
              </>
            ) : (
              <Typography>There are no remaining collections found.</Typography>
            )}
          </Grid>
        </Grid>

        <Dialog
          aria-labelledby="change-publisher-language"
          onClose={this.handleModalClose}
          open={openLanguagesModal}
        >
          <DialogTitle id="change-publisher-language">
            Change Publisher Language
          </DialogTitle>
          <DialogContent>
            <Box mb={2}>
              <LanguageSelector
                autoFocus={true}
                changed={this.handleLanguageChange}
                current={currentLanguage}
                fullWidth={true}
                langs={languages}
              />
            </Box>
          </DialogContent>
        </Dialog>

        <Backdrop className={classes.backdrop} open={loading || !initialized}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </>
    )
  }
}

CollectionList.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  history: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.shape({}),
      PropTypes.func,
    ]),
  ).isRequired,
  throwFriendlyErrorPage: PropTypes.func.isRequired, // from withFriendlyErrorPage
}

export default withFriendlyErrorPage(
  withRouter(withStyles(styles)(CollectionList)),
)
