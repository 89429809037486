import React from 'react'
import { TableRow, TableCell } from '@material-ui/core'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

const PublisherRow = ({ data }) => (
  <TableRow
    component={Link}
    to={`/publishers/${data.id}?lang=${data.language}`}
  >
    <TableCell component="div">{data.name}</TableCell>
    <TableCell align="right" component="div">
      {data.trackingId}
    </TableCell>
  </TableRow>
)

PublisherRow.propTypes = {
  data: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.shape({}),
      PropTypes.arrayOf(PropTypes.string),
    ]),
  ).isRequired,
}

export default PublisherRow
