import React from 'react'
import PropTypes from 'prop-types'
import { List, Box, Typography, Paper } from '@material-ui/core'
import { statusTypes } from 'utils'
import { FriendlyError } from 'components/ui/friendly-error-page'
import imgPeopleCelebrating from 'assets/img/people-celebrating.png'
import PublisherAndDetails from 'components/ui/publisher-and-details'
import { useAirTableRecentSubmissions } from 'hooks/airtable'

export default function RecentAirTableSubmissions({ config }) {
  const { publishersSubmissions, status } = useAirTableRecentSubmissions()

  let contentComponent = (
    <List>
      {(!config || status !== statusTypes.RESOLVED
        ? Array.from(new Array(3)) // shows loading components while info is being fetched
        : Object.keys(publishersSubmissions)
      ).map((publisherId, index) => (
        <PublisherAndDetails
          key={`${publisherId || ''}-${index}`}
          publisherId={publisherId}
          numberOfVideos={publishersSubmissions[publisherId]}
          config={config}
        />
      ))}
    </List>
  )

  if (status === statusTypes.ERROR) {
    contentComponent = (
      <Box p={2}>
        <Typography color="textSecondary">
          <span role="img" aria-label="sad emoji">
            😕
          </span>{' '}
          Failed to load data from AirTable
        </Typography>
      </Box>
    )
  }

  if (
    status === statusTypes.RESOLVED &&
    Object.keys(publishersSubmissions).length === 0
  ) {
    contentComponent = (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        mt={6}
        mb={4}
      >
        <FriendlyError
          imageCredit="Illustrations from https://www.ls.graphics/"
          title="Looks like there's nothing to review!"
          imageSrc={imgPeopleCelebrating}
          imageHeight={227}
          imageWidth={200}
        />
      </Box>
    )
  }

  return (
    <Box
      borderRadius={16}
      component={Paper}
      display="flex"
      flexDirection="column"
      flexGrow={1}
      pt={2}
      variant="outlined"
    >
      <Box pl={2} pr={2} display="flex" alignItems="center">
        <Typography variant="h2" color="textPrimary" style={{ marginTop: 0 }}>
          Recent AirTable Submissions
        </Typography>
      </Box>
      {contentComponent}
    </Box>
  )
}

RecentAirTableSubmissions.propTypes = {
  config: PropTypes.shape({
    imageUrls: PropTypes.objectOf(PropTypes.string),
  }),
}

RecentAirTableSubmissions.defaultProps = {
  config: undefined,
}
