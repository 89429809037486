/**
 * @module VideoRow
 */

import React from 'react'
import Configuration from 'tupos/models/configuration'
import LazyImage from 'components/ui/lazy-image'
import PropTypes from 'prop-types'
import Publisher from 'tupos/models/publisher'
import urlReplacer from 'utils/url-replacer'
import VideoStatus from 'components/ui/video-status'

import { Link, Redirect, useLocation } from 'react-router-dom'
import { parse } from 'utils/query-string'
import { statusTypes } from 'utils/constants'
import { TableCell, TableRow } from '@material-ui/core'
import { useAlert } from 'components/layout/alert-bar'

/**
 * @typedef VideoObject
 * @param {number} id - The video ID.
 * @param {number} publisherId - The video's publisher ID.
 * @param {string} status - The status of whether or not the video was published, or ready to be published.
 * @param {string} title - The title of the video.
 */

/**
 * VideoRow displays each video details in each row in the all videos view.
 * The data for VideoRow is passed down from the data prop in RowComponent.
 *
 * @alias module:VideoRow
 *
 * @param {object} props - The component props object.
 * @param {VideoObject} props.data - The VideoObject.
 *
 * @returns {React.ReactElement} - The VideoRow component as MuiTableRow-root.
 *
 * @example
 * <VideoRow data={data} />
 */
export default function VideoRow({ data }) {
  const location = useLocation()
  const { throwAlert } = useAlert()

  const [config, setConfig] = React.useState([])
  const [loading, setLoading] = React.useState({ status: statusTypes.PENDING })
  const [publisher, setPublisher] = React.useState([])

  React.useEffect(() => {
    async function loadData() {
      const parsed = parse(location.search)
      Promise.all([
        Publisher.get(data.publisherId, 'false', parsed.lang || 'en'),
        Configuration.get(),
      ])
        .then((responseData) => {
          setPublisher(responseData[0])
          setConfig(responseData[1])
          setTimeout(() => {
            setLoading({ status: statusTypes.RESOLVED })
          }, 100)
        })
        .catch(() => {
          setPublisher([])
          setLoading({ status: statusTypes.REJECTED })
          throwAlert({
            id: 'publisher_or_configuration_fetch_error',
            message: `Error fetching publisher or configuration for language ${
              parsed.lang || 'en'
            }.`,
            type: 'error',
          })
        })
    }

    loadData()
  }, [data.publisherId, location.search, throwAlert])

  if (loading.status !== statusTypes.RESOLVED) return null
  if (!location.search) return <Redirect to="/videos?lang=en" />
  const parsed = parse(location.search)
  const imageUrl = config.imageUrls.video
  const videoImageUrl = urlReplacer(imageUrl, {
    height: '45',
    id: data.id,
    language: parsed.lang,
    width: '87',
  })
  return (
    <TableRow component="div">
      <TableCell component="div">
        <LazyImage
          alt={data.title}
          height={45}
          src={videoImageUrl}
          width={87}
        />
      </TableCell>
      <TableCell
        align="left"
        component={Link}
        to={`/videos/${data.id}?lang=${parsed.lang}`}
      >
        {data.title}
      </TableCell>
      <TableCell align="left" component="div">
        {data.id}
      </TableCell>
      <TableCell
        align="left"
        component={Link}
        to={`/publishers/${publisher.id}?lang=${parsed.lang}`}
      >
        {publisher.name}
      </TableCell>
      <TableCell align="center" component="div">
        <VideoStatus status={data.status} />
      </TableCell>
    </TableRow>
  )
}

VideoRow.propTypes = {
  data: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.shape({}),
      PropTypes.arrayOf(PropTypes.string),
    ]),
  ).isRequired,
}
