import React from 'react'
import { Grid, Container, makeStyles } from '@material-ui/core'
import Configuration from 'tupos/models/configuration'
import { useFriendlyErrorPage } from 'contexts/friendly-error-message-context'
import RecentAirTableSubmissions from 'components/views/dashboard/recent-airtable-submissions'
import RecentVideoList from 'components/views/dashboard/recent-videos-list'

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
    paddingTop: theme.spacing(4),
    '&:not(:first-child)': {
      paddingBottom: theme.spacing(4),
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0),
      paddingTop: theme.spacing(4),
    },
  },
}))

export default function Dashboard() {
  const [config, setConfig] = React.useState()
  const { throwFriendlyErrorPage } = useFriendlyErrorPage()
  const classes = useStyles()

  React.useEffect(() => {
    async function getConfig() {
      try {
        const configResponse = await Configuration.get()
        setConfig(configResponse)
      } catch (error) {
        throwFriendlyErrorPage({
          message: error.message,
          title: 'Failed to fetch the configuration',
        })
      }
    }

    if (
      throwFriendlyErrorPage !== 'undefined' &&
      typeof throwFriendlyErrorPage === 'function'
    ) {
      getConfig()
    }
  }, [throwFriendlyErrorPage])

  return (
    <Container maxWidth="md">
      <Grid container={true}>
        <Grid item={true} xs={12} sm={6} className={classes.container}>
          <RecentVideoList config={config} />
        </Grid>
        <Grid item={true} xs={12} sm={6} className={classes.container}>
          <RecentAirTableSubmissions config={config} />
        </Grid>
      </Grid>
    </Container>
  )
}
