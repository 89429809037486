import React from 'react'
import DataTable from 'components/layout/data-table'
import imgMeeting from 'assets/img/meeting.png'
import imgPeopleCelebrating from 'assets/img/people-celebrating.png'
import Pager from 'components/ui/pager'
import PropTypes from 'prop-types'
import VideoHead from 'components/views/publisher/video-head'
import VideoRow from 'components/views/publisher/video-row'
import { AlertBar, AlertProvider } from 'components/layout/alert-bar'
import {
  Box,
  Button,
  Collapse,
  Grid,
  LinearProgress,
  Paper,
} from '@material-ui/core'
import { FriendlyError } from 'components/ui/friendly-error-page'
import { statusTypes, videoStatusTypes } from 'utils'

export default function VideosTable({
  getVideosStatus,
  nextPage,
  language,
  pageSize,
  publisherId,
  selectedPage,
  videos,
  videoStatusType,
}) {
  const isLoaded = getVideosStatus === statusTypes.RESOLVED
  const hasNoVideos = videos.length === 0

  if ([statusTypes.IDLE, statusTypes.PENDING].includes(getVideosStatus))
    return <LinearProgress />

  if (isLoaded && hasNoVideos && videoStatusType === videoStatusTypes.PUBLISHED)
    return (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        mb={4}
        mt={4}
      >
        <FriendlyError
          imageCredit="Illustrations from https://www.ls.graphics/"
          imageHeight={300}
          imageWidth={363}
          imageSrc={imgMeeting}
          title="Consider uploading your first video"
        />
        <Box mt={2} alignSelf="center">
          <Button
            color="primary"
            href={`/videos/add/?publisher=${publisherId}&lang=${language}`}
            target="_blank"
            variant="contained"
          >
            Upload a Video
          </Button>
        </Box>
      </Box>
    )

  if (isLoaded && hasNoVideos && videoStatusType === videoStatusTypes.FAILED)
    return (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        mt={4}
        mb={4}
      >
        <FriendlyError
          imageCredit="Illustrations from https://www.ls.graphics/"
          imageHeight={347}
          imageWidth={306}
          imageSrc={imgPeopleCelebrating}
          title="Looks like there's nothing to review!"
        />
      </Box>
    )

  return (
    <AlertProvider>
      <AlertBar />
      <Collapse in={isLoaded}>
        <Grid item={true}>
          <Paper style={{ flexGrow: 1 }}>
            <DataTable
              dataSource={videos}
              HeadComponent={VideoHead}
              RowComponent={VideoRow}
              pager={
                <Pager
                  dataLength={videos.length}
                  hasNextPage={nextPage}
                  loading={videos === undefined}
                  onNext={true}
                  onPrevious={true}
                  pageNumber={selectedPage}
                  pageSize={pageSize}
                />
              }
            />
          </Paper>
        </Grid>
      </Collapse>
    </AlertProvider>
  )
}

VideosTable.propTypes = {
  getVideosStatus: PropTypes.string.isRequired,
  language: PropTypes.string.isRequired,
  nextPage: PropTypes.bool.isRequired,
  pageSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  publisherName: PropTypes.string,
  publisherId: PropTypes.string.isRequired,
  selectedPage: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  videos: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.array, PropTypes.number, PropTypes.object]),
  ),
  videoStatusType: PropTypes.string,
}

VideosTable.defaultProps = {
  publisherName: '',
  videoStatusType: '',
}
