import React from 'react'
import PropTypes from 'prop-types'
import {
  TextField,
  MenuItem,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
} from '@material-ui/core'
import { bibleESVBooksData } from 'utils/bible-book-data'
import useBibleReferenceParser from 'hooks/use-bible-reference-parser'

/**
 * TextField input used to generate Bible reference usfms, based on static ESV Bible data.
 *
 * @param {object} props - The passed params. All remaining props are sent to the Material-UI TextField for further customization.
 * @param {Function} props.onEnter - The function run whenever the Enter key is pressed while input is in focus.
 */
export default function BibleReferenceTextField({ onEnter, ...props }) {
  const [bibleReference, setBibleReference] = React.useState('')
  const bibleInputRef = React.useRef(null)
  const bookMenuRef = React.useRef(null)

  const {
    usfm,
    bookData,
    chapterStart,
    chapterEnd,
    verseStart,
    error,
  } = useBibleReferenceParser(bibleReference)

  const filteredBookChoices = bibleESVBooksData
    .filter(({ human: humanReadableReference }) => {
      if (bibleReference === '') return true // to allow user to see all books at first

      const showBook = new RegExp(bibleReference, 'i').test(
        humanReadableReference,
      )
      return showBook
    })
    .sort((a, b) => {
      if (a.human < b.human) {
        return -1
      }
      if (a.human > b.human) {
        return 1
      }
      return 0
    })

  /**
   * @param {object} event - An input event.
   * @param {object} event.target - The input event target.
   * @param {string} event.target.value - The event value.
   */
  function handleInput({ target: { value } }) {
    // if a book usfm doesn't exist, then don't start typing chapter
    // give them space and time to select a remaining book
    const isLastKeyAnInteger = /\d$/.test(value)
    if (
      !usfm &&
      isLastKeyAnInteger &&
      value.length > 1 // because some books start with a number
    ) {
      return
    }

    setBibleReference((prevValue) => {
      const deleteKeyWasPressed = prevValue.trim().length > value.length

      // if we're backspacing after selecting a book and there's not yet a chapter,
      // then just reset the usfm and book
      if (usfm && !chapterStart && deleteKeyWasPressed) {
        return ''
      }

      // don't allow user to type more if end chapter is added, as no
      // more needs to be added.
      if (chapterEnd && !isLastKeyAnInteger) {
        return deleteKeyWasPressed ? value : prevValue
      }

      return value
    })
  }

  const handleBookSelection = (bookSelection) => {
    setBibleReference(`${bookSelection} `)
    bibleInputRef.current.focus()
  }

  function getHelperText() {
    let helperText = ''
    if (!bibleReference) return helperText

    if (!verseStart && bookData) {
      const numberOfChapters = bookData.max_num_of_chapters
      helperText = `${bookData.human} has ${numberOfChapters} total chapter${
        numberOfChapters > 1 ? 's' : ''
      }`
    }

    if (bookData && chapterStart && bibleReference.includes(':')) {
      const numberOfVerses = bookData.max_num_of_verses[chapterStart - 1] // zero indexed
      helperText = `${bookData.human} has ${numberOfVerses} total verse${
        numberOfVerses > 1 ? 's' : ''
      }`
    }

    return helperText
  }

  const bookMenuIsOpen = Boolean(!bookData?.usfm && bibleReference.length > 0)

  // These key values should work on all devices.
  // https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/key/Key_Values#Whitespace_keys
  function handleKeyPress({ key }) {
    if (error) return

    if (key === 'Enter') {
      // Given only a book is selected, on Enter key press, set the Bible reference to the entire
      // book, including all chapters.
      if (bookData && !chapterStart) {
        setBibleReference(`${bookData.human} 1-${bookData.max_num_of_chapters}`)
        return
      }

      // if at least book and chapter start is chosen,
      // then you have a valid USFM
      if (bookData && chapterStart) {
        onEnter(usfm)
        setBibleReference('')
      }
    }

    if (key === 'ArrowDown' && bookMenuIsOpen) {
      bookMenuRef.current.firstChild.focus()
    }
  }

  return (
    <>
      <TextField
        autoFocus={true}
        inputRef={bibleInputRef}
        helperText={error || getHelperText()}
        error={Boolean(error)}
        aria-label="Bible Reference Input"
        placeholder="Enter Bible Reference"
        value={bibleReference}
        type="text"
        onChange={handleInput}
        onKeyUp={handleKeyPress}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
      <Popper
        open={bookMenuIsOpen}
        anchorEl={bibleInputRef.current}
        role={undefined}
        transition={true}
        disablePortal={true}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper
              style={{
                width: 200,
                maxHeight: 250,
                overflowY: 'scroll',
              }}
            >
              <ClickAwayListener
                onClickAway={() => bibleInputRef.current.blur()}
              >
                <MenuList id="menu-list-bible-books" ref={bookMenuRef}>
                  {filteredBookChoices.length > 0 ? (
                    filteredBookChoices.map(({ human, usfm: bookUsfm }) => (
                      <MenuItem
                        onClick={() => handleBookSelection(human)}
                        key={bookUsfm}
                      >
                        {human}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem value="" disabled={true}>
                      <em>No Result</em>
                    </MenuItem>
                  )}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  )
}

BibleReferenceTextField.propTypes = {
  onEnter: PropTypes.func.isRequired,
}
