/* eslint-disable no-param-reassign */

// This came from index.js of npm package array-move v2.1.0 https://github.com/sindresorhus/array-move

const arrayMoveMutate = (array, from, to) => {
  array.splice(to < 0 ? array.length + to : to, 0, array.splice(from, 1)[0])
}

const arrayMove = (array, from, to) => {
  array = array.slice()
  arrayMoveMutate(array, from, to)
  return array
}

module.exports = arrayMove
// TODO: Remove this for the next major release
module.exports.default = arrayMove

module.exports.mutate = arrayMoveMutate
