/**
 * @module VideoRow
 */

import React from 'react'
import Configuration from 'tupos/models/configuration'
import LazyImage from 'components/ui/lazy-image'
import PropTypes from 'prop-types'
import VideoStatus from 'components/ui/video-status'

import { Button, TableCell, TableRow } from '@material-ui/core'
import { Link, Redirect, useLocation } from 'react-router-dom'
import { parse, statusTypes, urlReplacer } from 'utils'
import { useAlert } from 'components/layout/alert-bar'

/**
 * @typedef VideoObject
 * @param {number} id - The video ID.
 * @param {number} publisherId - The video's publisher ID.
 * @param {string} status - The status of whether or not the video was published, or ready to be published.
 * @param {string} title - The title of the video.
 */

/**
 * VideoRow displays each video details in each row in the publisher's videos view.
 * The data for VideoRow is passed down from the data prop in RowComponent.
 *
 * @alias module:VideoRow
 *
 * @param {object} props - The component props object.
 * @param {VideoObject} props.data - The VideoObject.
 *
 * @returns {React.ReactElement} - The VideoRow component as MuiTableRow-root.
 *
 * @example
 * <VideoRow data={data} />
 */
export default function VideoRow({ data }) {
  const location = useLocation()
  const { throwAlert } = useAlert()

  const [config, setConfig] = React.useState([])
  const [loading, setLoading] = React.useState({ status: statusTypes.PENDING })

  React.useEffect(() => {
    async function loadData() {
      try {
        const configResponse = await Configuration.get()
        setConfig(configResponse)
        setTimeout(() => {
          setLoading({ status: statusTypes.RESOLVED })
        }, 100)
      } catch (error) {
        setLoading({ status: statusTypes.REJECTED })
        throwAlert({
          id: 'configuration-fetch-error',
          message: "Error fetching configuration for this publisher's videos.",
          type: 'error',
        })
      }
    }

    loadData()
  }, [throwAlert])

  if (loading.status !== statusTypes.RESOLVED) return null
  if (!location.search) return <Redirect to="/videos?lang=en" />
  const parsed = parse(location.search)
  const imageUrl = config.imageUrls.video
  const videoImageUrl = urlReplacer(imageUrl, {
    height: '45',
    id: data.id,
    language: parsed.lang,
    width: '87',
  })
  const videoUrl = `/videos/${data.id}?lang=${parsed.lang}`

  return (
    <TableRow component="div">
      <TableCell component="div">
        <LazyImage
          alt={data.title}
          height={45}
          src={videoImageUrl}
          width={87}
        />
      </TableCell>
      <TableCell align="left" component={Link} to={videoUrl}>
        {data.title}
      </TableCell>
      <TableCell align="left" component="div">
        {data.id}
      </TableCell>
      <TableCell align="center" component="div">
        <VideoStatus status={data.status} />
      </TableCell>
      <TableCell align="right" component="div">
        <Button color="primary" href={videoUrl}>
          Edit
        </Button>
      </TableCell>
    </TableRow>
  )
}

VideoRow.propTypes = {
  data: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.shape({}),
      PropTypes.arrayOf(PropTypes.string),
    ]),
  ).isRequired,
}
