import React from 'react'
import { bibleESVBooksData } from 'utils/bible-book-data'

/**
 * @typedef {Object} BibleBookData
 * @property {string} usfm - USFM of the book
 * @property {string} human - Human readable name of the book
 * @property {number} max_num_of_chapters - Amount of chapters in a given book
 * @property {Array} max_num_of_verses - Array containing number of verses in a given chapter. (e.g. book.max_num_of_verses[chapterNum - 1]) Remember, this is a zero-indexed array.
 */

/**
 * Bible data typedef object. Data returned from Bible Ref Parser Hook
 * @typedef {Object} BibleData
 * @property {string} book - Name of the Bible book
 * @property {BibleBookData} bookData - Data of a given Bible book
 * @property {BibleBookData[]} bookList - Array of Books in the Bible
 * @property {string} usfm - Constructed USFM from parsed bibleText input
 * @property {number} chapterStart - Starting Bible book chapter number.
 * @property {number} chapterEnd - End chapter number of Bible book number range.
 * @property {number} verseStart - Starting verse number.
 * @property {number} verseEnd - Ending verse number of verses range.
 * @property {string} error - Returns detailed string if error is present.
 */

/**
 * Takes a string of a Bible reference, parses it, and outputs a USFM and other helpful bible data.
 * USFM data is constructed using static ESV Bible data.
 *
 * @param {string} bibleText - Bible reference (i.e. '1 John 4:9', 'Romans 8', 'Jeremiah 29:11-12')
 * @return {BibleData}
 */
export default function useBibleReferenceParser(bibleText) {
  const [book, setBook] = React.useState('')
  const [bookData, setBookData] = React.useState()
  const [chapterStart, setChapterStart] = React.useState()
  const [chapterEnd, setChapterEnd] = React.useState()
  const [verseStart, setVerseStart] = React.useState()
  const [verseEnd, setVerseEnd] = React.useState()
  const [error, setError] = React.useState('')

  function constructUsfm() {
    if (!bookData) return ''

    let usfmContructor = ''

    usfmContructor += bookData.usfm || ''
    usfmContructor += chapterStart && !chapterEnd ? `.${chapterStart}` : ''

    if (chapterEnd) {
      const prefix = usfmContructor
      usfmContructor = ''

      if (chapterStart > chapterEnd) return prefix

      for (let i = chapterStart; i <= chapterEnd; i += 1) {
        usfmContructor += `${prefix}.${i}`
        if (i !== chapterEnd) usfmContructor += '+'
      }

      return usfmContructor
    }

    if (verseStart && !verseEnd) {
      usfmContructor += verseStart ? `.${verseStart}` : ''
    } else if (verseEnd) {
      const prefixWithChapter = usfmContructor
      usfmContructor = ''

      if (verseStart > verseEnd) return prefixWithChapter

      for (let i = verseStart; i <= verseEnd; i += 1) {
        usfmContructor += `${prefixWithChapter}.${i}`
        if (i !== verseEnd) usfmContructor += '+'
      }

      return usfmContructor
    }

    return usfmContructor
  }

  React.useEffect(() => {
    const resetBibleState = () => {
      setBook('')
      setBookData(null)
      setChapterStart(null)
      setChapterEnd(null)
      setVerseStart(null)
      setVerseEnd(null)
      setError('')
    }

    try {
      if (!bibleText) {
        resetBibleState()
        return
      }

      const bibleReferenceRegex = /(?<book>((\d\s)?[A-z]?\s?)+)(?:(?<chapterStart>\d{1,3})(?:-(?<chapterEnd>\d{1,3}))?)?(?::(?:(?<verseStart>\d{1,3})(?:-(?<verseEnd>\d{1,3}))?)?)?/
      const sanitizedText = bibleText.replace(/\\/g, '')
      const parsedData = bibleReferenceRegex.exec(sanitizedText)

      const {
        book: bibleBook = '',
        chapterStart: chStart,
        chapterEnd: chEnd,
        verseStart: vStart,
        verseEnd: vEnd,
      } = parsedData.groups

      setBook(bibleBook)

      if (!bookData) return

      setChapterStart(parseInt(chStart, 10))
      setChapterEnd(parseInt(chEnd, 10))
      setVerseStart(parseInt(vStart, 10))
      setVerseEnd(parseInt(vEnd, 10))
    } catch (e) {
      setError('Something went wrong.')
    }
  }, [bibleText, bookData])

  React.useEffect(() => {
    if ((chapterEnd || chapterStart) && bookData) {
      const maxNumOfVerses = bookData.max_num_of_verses[chapterStart - 1] // zero indexed

      if (
        bookData.max_num_of_chapters < chapterEnd ||
        bookData.max_num_of_chapters < chapterStart
      ) {
        setError('Chapter number is too high.')
      } else if (chapterStart > chapterEnd) {
        setError('Start chapter is higher than end chapter.')
      } else if (maxNumOfVerses < verseStart || maxNumOfVerses < verseEnd) {
        setError('Verse number is too high.')
      } else if (verseStart > verseEnd) {
        setError('Verse start is higher than verse end.')
      } else {
        setError('')
      }
    }
  }, [bookData, chapterStart, chapterEnd, verseStart, verseEnd])

  React.useEffect(() => {
    if (book) {
      const selectedBook = bibleESVBooksData.find(
        (bookObj) => bookObj.human.toLowerCase() === book.toLowerCase().trim(),
      )

      if (selectedBook) {
        setBookData(selectedBook)
      } else {
        setBookData(null)
      }
    }
  }, [book])

  return {
    book: book.trim(),
    bookData,
    bookList: bibleESVBooksData,
    usfm: constructUsfm(),
    chapterStart,
    chapterEnd,
    verseStart,
    verseEnd,
    error,
  }
}
