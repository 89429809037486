import React from 'react'
import PropTypes from 'prop-types'
import { Chip, Box, Fade, Typography } from '@material-ui/core'
import VideoStatus from '../../video-status'
import SubmitButton from '../../submit-button'
import Video from '../../../../tupos/models/video'

function PublisherCardTitle({ language }) {
  return <Chip label={language} />
}

PublisherCardTitle.propTypes = {
  language: PropTypes.string.isRequired,
}

function CollectionCardTitle({ language }) {
  return <Chip label={language} />
}

CollectionCardTitle.propTypes = {
  language: PropTypes.string.isRequired,
}

function VideoCardTitle({ video, language, cardIsExpanded }) {
  // not using derived state here because I cannot easily update parent video
  // object without coupling the LanguageEditCard even more. So, this was
  // my solution.
  const [videoStatus, setVideoStatus] = React.useState(video && video.status)

  const handlePublishVideo = async () => {
    const videoClassInstance = Video.toClass({ json: video, language })
    await videoClassInstance.publish()
    setVideoStatus('published')
  }

  return (
    <Box display="flex" alignItems="center" flexGrow={1}>
      <Box marginRight={2}>
        <Chip color="default" label={language} />
      </Box>
      <Box alignSelf="center">
        {!cardIsExpanded ? (
          <Fade in={true}>
            <Typography color="textPrimary" variant="h3">
              {video.title}
            </Typography>
          </Fade>
        ) : null}
      </Box>
      <Box flexGrow={1} />
      <VideoStatus status={videoStatus} />
      {videoStatus === 'ready' ? (
        <Box marginLeft={2}>
          <SubmitButton
            onClick={handlePublishVideo}
            onError={() => {}}
            onSuccess={() => {}}
            variant="contained"
            color="primary"
          >
            Publish
          </SubmitButton>
        </Box>
      ) : null}
    </Box>
  )
}

VideoCardTitle.propTypes = {
  video: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({}),
      PropTypes.number,
      PropTypes.arrayOf(PropTypes.string),
    ]),
  ),
  language: PropTypes.string.isRequired,
  cardIsExpanded: PropTypes.bool.isRequired,
}

VideoCardTitle.defaultProps = {
  video: undefined,
}

/**
 * # Language Card Title.
 *
 * Title component to be passed within the CardHeader inside of LanguageAddCard
 * or LanguageEditCard.
 *
 * @param {object} props
 * @param {string} props.type - Type of object being passed (i.e. 'video', 'collection', or 'publisher').
 * @param {object} props.object - The object instance of the type passed. (i.e. A video object or a publisher object).
 * @param {string} props.language - The language of the card and object.
 * @param {boolean} props.cardIsExpanded - A boolean to indicate if the language card is expanded.
 * @returns {JSX} - Node.
 */
export default function LanguageCardTitle({
  type,
  object,
  language,
  cardIsExpanded,
}) {
  // if object is empty, but a language exists, display that as a chip
  if (!object && language) {
    return <Chip label={language} />
  }

  if (!object) {
    return null
  }

  switch (type) {
    case 'video':
      return (
        <VideoCardTitle
          video={object}
          language={language}
          cardIsExpanded={cardIsExpanded}
        />
      )
    case 'collection':
      return (
        <CollectionCardTitle
          collection={object}
          language={language}
          cardIsExpanded={cardIsExpanded}
        />
      )
    case 'publisher':
      return (
        <PublisherCardTitle
          publisher={object}
          language={language}
          cardIsExpanded={cardIsExpanded}
        />
      )
    default:
      return null
  }
}

LanguageCardTitle.propTypes = {
  type: PropTypes.string.isRequired,
  object: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({}),
      PropTypes.number,
      PropTypes.arrayOf(PropTypes.string),
    ]),
  ),
  language: PropTypes.string.isRequired,
  cardIsExpanded: PropTypes.bool.isRequired,
}

LanguageCardTitle.defaultProps = {
  object: undefined,
}
