/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import PropTypes from 'prop-types'
import { Typography, makeStyles, Button } from '@material-ui/core'
import LazyImage from '../lazy-image'

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
    display: 'block',
  },
  input: {
    display: 'none',
  },
  red: {
    color: 'red',
  },
  thumbnail: {
    maxWidth: '100%',
  },
}))

export default function ImageFileInput({
  id,
  previewWidth,
  previewHeight,
  name,
  imageClassName,
  labelText,
  defaultImage,
  onChange,
  onValidate,
  validateWidth,
  validateHeight,
  validateMaxFileSize,
  validateFileType,
  ...props
}) {
  const classes = useStyles()
  const [image, setImage] = React.useState()
  const [preview, setPreview] = React.useState(() => {
    if (defaultImage) {
      return (
        <LazyImage
          className={`${imageClassName} ${classes.thumbnail}`}
          src={defaultImage}
          alt={labelText}
          width={previewWidth}
          height={previewHeight}
        />
      )
    }
    return null
  })

  const [validationErrors, setValidationErrors] = React.useState([])

  React.useEffect(() => {
    if (validationErrors.length > 0) {
      onValidate(true, id, `${labelText} has errors. Please fix before saving.`)
    } else {
      onValidate(false, id)
    }
  }, [validationErrors, onValidate, id, labelText])

  function readURI(e) {
    if (e.target.files && e.target.files[0]) {
      const fileSize = e.target.files[0].size
      const reader = new FileReader()
      reader.onload = (ev) => {
        setImage({ uri: ev.target.result, fileSize })
      }
      reader.readAsDataURL(e.target.files[0])
    }
  }

  React.useEffect(() => {
    // Validate the image on change
    if (image) {
      const img = new Image()

      img.addEventListener('load', () => {
        const errorMessages = []

        // Validate file size
        if (
          validateMaxFileSize &&
          image.fileSize &&
          image.fileSize > validateMaxFileSize
        ) {
          // Add error message
          errorMessages.push({
            errorId: 'maxFileSize',
            message: `File size must be less than ${(
              validateMaxFileSize / 1e6
            ).toFixed(1)} MB`,
          })
        }

        // Validate width
        if (validateWidth && validateWidth !== img.naturalWidth) {
          // Add error message
          errorMessages.push({
            errorId: 'imageWidth',
            message: `Image width must be ${validateWidth}px`,
          })
        }

        // Validate height
        if (validateHeight && validateHeight !== img.naturalHeight) {
          // Add error message
          errorMessages.push({
            errorId: 'imageHeight',
            message: `Image height must be ${validateHeight}px`,
          })
        }
        setValidationErrors(errorMessages)
      })

      img.src = image.uri
    }

    // Update image preview
    setPreview((prevState) => {
      if (image && image.uri) {
        return (
          <LazyImage
            className={`${imageClassName} ${classes.thumbnail}`}
            src={image.uri}
            alt={labelText}
            width={previewWidth}
            height={previewHeight}
          />
        )
      }
      return prevState
    })
  }, [
    classes.thumbnail,
    image,
    imageClassName,
    labelText,
    previewHeight,
    previewWidth,
    validateHeight,
    validateMaxFileSize,
    validateWidth,
  ])

  function handleChange(e) {
    readURI(e) // maybe call this with webworker or async library?
    if (onChange !== undefined) {
      onChange(e)
    } // propagate to parent component
  }

  const displayValidationErrors = validationErrors.map((error) => {
    return (
      <span key={`${id}_${error.errorId}`} className={classes.red}>
        {error.message}
        <br />
      </span>
    )
  })

  return (
    <div className={classes.root}>
      <label htmlFor={id} className="button">
        <Typography variant="button" display="block" gutterBottom>
          {labelText}
        </Typography>
        <Button variant="outlined" component="span">
          Upload
        </Button>
      </label>
      {validationErrors ? <div>{displayValidationErrors}</div> : null}
      {preview}
      <input
        id={id}
        name={name}
        type="file"
        onChange={handleChange}
        className={classes.input}
        accept={validateFileType}
        {...props}
      />
    </div>
  )
}

ImageFileInput.propTypes = {
  id: PropTypes.string.isRequired,
  previewWidth: PropTypes.number.isRequired,
  previewHeight: PropTypes.number.isRequired,
  name: PropTypes.string,
  imageClassName: PropTypes.string,
  labelText: PropTypes.string,
  defaultImage: PropTypes.string,
  onChange: PropTypes.func,
  onValidate: PropTypes.func,
  validateWidth: PropTypes.number,
  validateHeight: PropTypes.number,
  validateMaxFileSize: PropTypes.number,
  validateFileType: PropTypes.string,
}
ImageFileInput.defaultProps = {
  name: null,
  imageClassName: null,
  labelText: 'Upload an Image',
  defaultImage: null,
  onChange: undefined,
  onValidate: undefined,
  validateWidth: null,
  validateHeight: null,
  validateMaxFileSize: null,
  validateFileType: 'image/jpg, image/jpeg, img/png',
}
