import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles, Box, Typography, Tooltip } from '@material-ui/core'
import LazyImage from '../lazy-image'
import errorIllustrationImg from '../../../assets/img/error-illustration.png'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    height: 'calc(100vh - 64px)', // height of AppBar === 64px
    width: '100%',
    '& > *:not(:first-child)': {
      marginTop: theme.spacing(2),
    },
  },
}))

/**
 * # FriendlyErrorPage.
 *
 * A functional component/container that displays a beautiful, fun illustration
 * while informing the user that an error has occured on the page.
 *
 * @param {object} props
 * @param {string} props.title - Concise title to inform the user of category/type of error.
 * @param {string} props.message - Concise message to inform the user of specifics of the error.
 */
export default function FriendlyErrorPage({ message, title }) {
  const classes = useStyles()

  return (
    <Box className={classes.container}>
      <Tooltip
        title="Illustrations from https://www.ls.graphics/"
        placement="top"
      >
        <Box>
          <LazyImage
            src={errorIllustrationImg}
            alt="Suprised person"
            width={215}
            height={400}
          />
        </Box>
      </Tooltip>
      <Typography variant="h3">{title}</Typography>
      <Typography variant="subtitle1">{message}</Typography>
    </Box>
  )
}

export function FriendlyError({
  message,
  title,
  imageSrc,
  imageHeight,
  imageWidth,
  imageCredit,
}) {
  if (!imageSrc) throw new Error('FriendlyError requires an image source.')

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >
      <Tooltip
        title={imageCredit}
        disableHoverListener={!imageCredit}
        placement="top"
      >
        <Box mb={1}>
          <LazyImage
            src={imageSrc}
            alt="empty state UI"
            width={imageWidth}
            height={imageHeight}
          />
        </Box>
      </Tooltip>
      <Box maxWidth={500}>
        <Typography align="center" variant="h3">
          {title}
        </Typography>
        <Typography align="center" variant="subtitle1">
          {message}
        </Typography>
      </Box>
    </Box>
  )
}

FriendlyError.propTypes = {
  message: PropTypes.string,
  title: PropTypes.string.isRequired,
  imageSrc: PropTypes.string.isRequired,
  imageHeight: PropTypes.number.isRequired,
  imageWidth: PropTypes.number.isRequired,
  imageCredit: PropTypes.string.isRequired,
}

FriendlyError.defaultProps = {
  message: '',
}

FriendlyErrorPage.propTypes = {
  message: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}
