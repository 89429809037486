/* eslint-disable no-console */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { Component } from 'react'
import Button from '@material-ui/core/Button'
import {
  Box,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  Typography,
  withStyles,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import TextField from '@material-ui/core/TextField'
import Divider from '@material-ui/core/Divider'
import { Redirect, withRouter } from 'react-router-dom'
import moment from 'moment'
import { v4 as uuidv4 } from 'uuid'
import * as Sentry from '@sentry/react'
import SecondaryAppBar from '../../components/layout/secondary-app-bar'
import DetailCard from '../../components/layout/detail-card'
import Collections from '../../tupos/models/collections'
import Languages from '../../tupos/models/languages'
import LanguageSelector from '../../components/ui/language-selector'
import CollectionDatePicker from '../../components/views/collection/collection-date-picker'
import VideoSelector from '../../components/views/video/video-selector-list'
import ImageFileInput from '../../components/ui/image-file-input'
import arrayMove from '../../utils/array-move'

const styles = (theme) => ({
  content: {
    maxWidth: 960,
    margin: 'auto',
    padding: theme.spacing(2),
  },

  // Detailed Card width
  card: {
    width: '100%',
  },

  textColor: {
    color: '#6AB750',
    padding: '25px',
  },

  images: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
  },

  textField: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(1),
    width: 375,
  },

  // Hyperlinks
  link: {
    color: 'rgba(0, 0, 0, 0.54)',
    margin: 'auto',
  },
})

class CollectionAdd extends Component {
  constructor(props) {
    super(props)
    this.state = {
      collection: new Collections({}),
      open: false,
      thumbnailFile: null,
      languageList: ['en'],
      publishDates: [null, null],
    }

    this.handleDateChange = this.handleDateChange.bind(this)
    this.handleFileSelect = this.handleFileSelect.bind(this)
    this.handleVideoItemCheck = this.handleVideoItemCheck.bind(this)
  }

  componentDidMount() {
    this.handleReserveId()
    this.handleFetchLanguages()
  }

  // Reserve collection id on load and set in state so it can be used during save
  handleReserveId = async () => {
    const { collection } = this.state
    try {
      const reserveIdResponse = await collection.reserveCollectionId()

      collection.id = reserveIdResponse.id
      this.setState({ collection })
    } catch (error) {
      Sentry.captureException(error)
    }
  }

  handleFetchLanguages = async () => {
    try {
      const languageResponse = await Languages.get('video')
      this.setState({
        languageList: languageResponse,
      })
    } catch (error) {
      Sentry.captureException(error)
    }
  }

  /**
   * Copied from Tommy's publisher-add-language function
   * Please see that function for needed updates/edits.
   */
  handlePresignedUpload = async (file, url, params) => {
    const formData = new FormData()

    Object.entries(params).forEach(([key, value]) => {
      formData.append(key, value)
    })

    if (!file) {
      console.error('No file set')
    }

    formData.append('file', file)

    const response = fetch(url, { method: 'POST', body: formData })
      .then((fetchResponse) => {
        return fetchResponse
      })
      .catch((error) => {
        Sentry.captureException(error)
      })
    return response
  }

  handleDateChange = (date) => {
    this.setState({
      publishDates: date,
    })
  }

  // Text field input change
  handleInputChange = (e) => {
    const { collection } = this.state
    collection[e.target.name] = e.target.value
    this.setState({
      collection,
    })
  }

  handleLanguageChange = (selectedLanguage) => {
    const { collection } = this.state
    collection.language = selectedLanguage

    this.setState({
      collection,
    })
  }

  handleFileSelect = (event) => {
    this.setState({
      thumbnailFile: event.target.files[0],
    })
  }

  handleSelectedVideosSortEnd = ({ oldIndex, newIndex }) => {
    const { collection } = this.state
    collection.videos = arrayMove(collection.videos, oldIndex, newIndex)
    this.setState({ collection })
  }

  // Saving a function
  handleSave = async (event) => {
    const { history } = this.props
    const { collection, thumbnailFile, publishDates } = this.state
    event.preventDefault()

    collection.start = moment(publishDates[0]).dayOfYear()
    collection.end = moment(publishDates[1]).dayOfYear()

    try {
      // Upload thumbnail
      await collection.getPresignedRequest(collection.id).then((response) => {
        // On success, actually attempt our S3 file upload!
        const thumbnailUploadResponse = this.handlePresignedUpload(
          thumbnailFile,
          response.upload_url,
          response.params,
        )

        return thumbnailUploadResponse
      })

      // Add the collection
      await collection.addCollections(collection.id)

      // Once the collection is added, update the videos list
      await collection.updateDatesAndOrder(collection.language)
    } catch (error) {
      Sentry.captureException(error)
    }

    // If ID can be set in state, then this can be changed to use state
    return history.push(
      `/collections/${collection.id}?lang=${collection.language}`,
    ) // Redirect to the new collection
  }

  handleVideoItemCheck = (checked, value) => {
    const { collection } = this.state
    const videosArray = [...collection.videos]

    if (!checked) {
      const index = videosArray.indexOf(value)
      if (index !== -1) {
        videosArray.splice(index, 1)
      }
    } else {
      videosArray.push(parseInt(value, 10))
    }
    collection.videos = videosArray
    this.setState({ collection })
  }

  // Dialog open
  handleDialogOpen = () => {
    this.setState({ open: true })
  }

  // Dialog Close
  handleDialogClose = () => {
    this.setState({ open: false })
  }

  render() {
    const { classes, match } = this.props

    const { collection, open, publishDates, languageList } = this.state
    if (!match.params.uuid)
      return <Redirect to={`/collections/add/${uuidv4()}`} />

    return (
      <div>
        <SecondaryAppBar
          actionComponent={
            <Button
              color="primary"
              onClick={this.handleDialogOpen}
              variant="contained"
            >
              Save
            </Button>
          }
          passItBack={true}
          title="Add a New Collection"
        />
        <Dialog onClose={this.handleDialogClose} open={open}>
          <DialogTitle disableTypography={true}>
            <Typography variant="h3">
              Are you ready to save this new collection?
            </Typography>
          </DialogTitle>
          <DialogActions>
            <Button color="primary" onClick={this.handleDialogClose}>
              No
            </Button>
            <Button color="primary" onClick={this.handleSave}>
              Yes
            </Button>
          </DialogActions>
        </Dialog>

        <Grid className={classes.content} container={true} spacing={5}>
          <DetailCard>
            <Grid item={true} xs={12}>
              <Box mb={2}>
                <LanguageSelector
                  changed={this.handleLanguageChange}
                  current={collection.language}
                  fullWidth={false}
                  langs={languageList}
                />
              </Box>
              <Divider />
            </Grid>
            <Grid item={true} xs={6}>
              <TextField
                className={classes.textField}
                defaultValue=""
                id="title"
                label="Title"
                multiline={true}
                name="title"
                onChange={this.handleInputChange}
                rowsMax="5"
              />
            </Grid>
            <Grid item={true} xs={6}>
              <TextField
                className={classes.textField}
                defaultValue=""
                id="description"
                label="Description"
                multiline={true}
                name="description"
                onChange={this.handleInputChange}
                rowsMax="5"
              />
            </Grid>

            <Grid item={true} xs={6}>
              <CollectionDatePicker
                onChange={this.handleDateChange}
                value={publishDates}
              />
            </Grid>

            <Grid item={true} xs={12}>
              {/* TODO BLKBSTR-168 add onValidate handler */}
              <ImageFileInput
                id="thumbnail_image"
                labelText="Thumbnail"
                onChange={this.handleFileSelect}
                onValidate={() => {}}
                previewHeight={450}
                previewWidth={800}
                validateFileType="image/jpg, image/jpeg"
              />
            </Grid>
          </DetailCard>
        </Grid>
        <Grid className={classes.content} container={true} spacing={5}>
          <Grid item={true} xs={12}>
            <VideoSelector
              currentVideos={collection.videos}
              language={collection.language}
              onCheck={this.handleVideoItemCheck}
              onSortEnd={this.handleSelectedVideosSortEnd}
            />
          </Grid>
        </Grid>
      </div>
    )
  }
}

CollectionAdd.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  history: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.shape({}),
      PropTypes.func,
    ]),
  ).isRequired,
  match: PropTypes.shape({
    params: PropTypes.objectOf(PropTypes.string),
  }).isRequired,
}
export default withRouter(withStyles(styles)(CollectionAdd))
