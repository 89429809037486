import React from 'react'
import PropTypes from 'prop-types'
import { statusTypes } from 'utils'
import ReadyToPublishVideoTable from 'components/views/publisher/bulk-video-publisher/ready-to-publish-video-table'
import BulkPublishProcessingList from 'components/views/publisher/bulk-video-publisher/bulk-publish-processing-list'
import Video from 'tupos/models/video'

const createVideoPublishObject = (video, language) => ({
  object: {
    ...video.toObject(),
    language,
  },
  publishStatus: statusTypes.IDLE,
  isSelected: false,
  errors: [],
})

const getVideos = async ({ publisherId, language, videoStatus = '*' }) =>
  Video.getCollection({
    fields: 'title,id,orientation',
    page: '*',
    publisher: publisherId,
    language,
    orientation: '*',
    types: '*',
    status: videoStatus,
  })

const publishVideo = async (video) => {
  const videoClass = Video.toClass({
    json: video.object,
    language: video.object.language,
  })

  return videoClass.publish()
}

export default function BulkVideoPublisher({
  publisherId,
  publisherLanguages,
}) {
  const [videos, setVideos] = React.useState([])
  const [isReadyToBulkPublish, setIsReadyToBulkPublish] = React.useState(false)
  const [currentUploadIndex, setCurrentUploadIndex] = React.useState(0)
  const [videoRetrievalStatus, setVideoRetrievalStatus] = React.useState(
    statusTypes.IDLE,
  )
  const [bulkUploadStatus, setBulkUploadStatus] = React.useState(
    statusTypes.IDLE,
  )

  const updateVideo = (videoId, fieldsToUpdate) => {
    setVideos((prevVideos) =>
      prevVideos.map((vid) => {
        // find video
        if (vid.object.id === videoId) {
          // update video
          const updatedVideoObject = {
            ...vid,
            ...fieldsToUpdate,
          }
          return updatedVideoObject
        }
        return vid
      }),
    )
  }

  const startBulkPublish = async () => {
    setIsReadyToBulkPublish(true)
    setBulkUploadStatus(statusTypes.PENDING)

    let hasAPublishError = false

    const selectedVideos = videos.filter((vid) => vid.isSelected)

    // publish videos one by one
    for (let i = 0; i < selectedVideos.length; i += 1) {
      setCurrentUploadIndex(i)

      try {
        // let user know video is publishing
        updateVideo(selectedVideos[i].object.id, {
          publishStatus: statusTypes.PENDING,
        })

        // eslint-disable-next-line no-await-in-loop
        await publishVideo(selectedVideos[i])

        // let user know video is published
        updateVideo(selectedVideos[i].object.id, {
          publishStatus: statusTypes.SUCCESS,
        })
      } catch (e) {
        // set video publishStatus has been rejected
        updateVideo(selectedVideos[i].object.id, {
          publishStatus: statusTypes.REJECTED,
          errors: [e.message || 'Failed to publish'],
        })

        hasAPublishError = true
      }
    }

    setBulkUploadStatus(
      hasAPublishError ? statusTypes.WARNING : statusTypes.RESOLVED,
    )
  }

  // Gets all videos ready to publish for a given publisher and all its respective languages
  React.useEffect(() => {
    // minimize extra api calls by adding a fail-safe to discontinue api calls if this unmounts
    let isSubscribed = true

    const isReadyToSetUp = Boolean(publisherLanguages.length > 0 && publisherId)
    if (isReadyToSetUp) {
      const getReadyVideosForAllLangs = async () => {
        setVideoRetrievalStatus(statusTypes.PENDING)

        const readyVideos = []
        for (let i = 0; i < publisherLanguages.length; i += 1) {
          if (!isSubscribed) {
            setVideoRetrievalStatus(statusTypes.IDLE)
            return
          }

          // eslint-disable-next-line no-await-in-loop
          const { rows: newVideos } = await getVideos({
            publisherId,
            language: publisherLanguages[i],
            videoStatus: 'ready',
          })

          if (newVideos && newVideos.length > 0) {
            for (let j = 0; j < newVideos.length; j += 1) {
              readyVideos.push(
                createVideoPublishObject(newVideos[j], publisherLanguages[i]),
              )
            }
          }
        }

        if (isSubscribed) {
          setVideoRetrievalStatus(statusTypes.RESOLVED)
          setVideos(readyVideos)
        }
      }

      getReadyVideosForAllLangs()
    }

    return () => {
      isSubscribed = false
    }
  }, [publisherLanguages, publisherId])

  const selectedVideos = videos.filter((vid) => vid.isSelected)

  if (isReadyToBulkPublish) {
    return (
      <BulkPublishProcessingList
        currentUploadIndex={currentUploadIndex}
        rows={selectedVideos}
        status={bulkUploadStatus}
      />
    )
  }

  return (
    <ReadyToPublishVideoTable
      videos={videos}
      handleVideoUpdate={setVideos}
      handlePublish={startBulkPublish}
      selectedVideos={selectedVideos}
      videoRetrievalStatus={videoRetrievalStatus}
    />
  )
}

BulkVideoPublisher.propTypes = {
  publisherId: PropTypes.number.isRequired,
  publisherLanguages: PropTypes.arrayOf(PropTypes.string),
}

BulkVideoPublisher.defaultProps = {
  publisherLanguages: undefined,
}
