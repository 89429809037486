import bibleESVDataJson from './bible-data-esv-en.json'

/**
 * @typedef {Object} BibleBookData
 * @property {string} usfm - USFM of the book
 * @property {string} human - Human readable name of the book
 * @property {number} max_num_of_chapters - Amount of chapters in a given book
 * @property {Array} max_num_of_verses - Array containing number of verses in a given chapter. (e.g. book.max_num_of_verses[chapterNum - 1]) Remember, this is a zero-indexed array.
 */

/**
 * Data is based on the ESV Bible.
 *
 * @return {BibleBookData[]} - Array of Bible Book Data objects.
 */
export const bibleESVBooksData = Object.keys(bibleESVDataJson).map((key) => ({
  usfm: key,
  human: bibleESVDataJson[key].human,
  max_num_of_chapters: bibleESVDataJson[key].max_verse.length,
  max_num_of_verses: bibleESVDataJson[key].max_verse,
}))

export default bibleESVBooksData
export { bibleESVDataJson }
