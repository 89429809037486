import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  makeStyles,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Collapse,
  IconButton,
  Button,
  CircularProgress,
  Typography,
  Tooltip,
} from '@material-ui/core'
import { green, red } from '@material-ui/core/colors'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import DeleteIcon from '@material-ui/icons/Delete'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import ButtonDialog from 'components/ui/button-dialog'
import appLanguagesJson from 'tupos/models/languages/app-languages.json'
import Collections from '../../../../tupos/models/collections'
import CollectionLanguageFields from '../../../views/collection/collection-language-fields'
import Publisher from '../../../../tupos/models/publisher'
import PublisherLanguageFields from '../../../views/publisher/publisher-language-fields'
import Video from '../../../../tupos/models/video'
import VideoLanguageFields from '../../../views/video/video-language-fields'
import LanguageCardTitle from '../language-card-title'
import { statusTypes } from '../../../../utils/constants'

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: theme.spacing(2),
    width: '100%',
    '&:not(:first-child)': {
      marginTop: 15,
    },
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  delete: {
    color: theme.danger,
  },
  textField: {
    marginBottom: 15,
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  cardHeader: {
    padding: `
      ${theme.spacing(1)}px 
      ${theme.spacing(1)}px 
      ${theme.spacing(1)}px 
      ${theme.spacing(2)}px
    `,
    '& .MuiCardHeader-action': {
      margin: 0,
    },
  },
}))

async function getObject({ type, id, language }) {
  switch (type) {
    case 'collection': {
      const result = await Collections.get(id, language)
      return result
    }
    case 'publisher': {
      const result = await Publisher.get(id, true, language)
      return result
    }
    case 'video': {
      const result = await Video.get(id, language)
      return result
    }
    default:
      return null
  }
}

function LanguageFields({ type, object, onInputChange, onValidate }) {
  function renderTypeFields() {
    switch (type) {
      case 'collection':
        return (
          <CollectionLanguageFields
            collection={object}
            onInputChange={onInputChange}
            onValidate={onValidate}
            hasThumbnail={true}
          />
        )
      case 'publisher':
        return (
          <PublisherLanguageFields
            publisher={object}
            onInputChange={onInputChange}
            onValidate={onValidate}
            hasThumbnails={true}
          />
        )
      case 'video':
        return (
          <VideoLanguageFields
            variant="update"
            video={object}
            onInputChange={onInputChange}
            onValidate={onValidate}
          />
        )
      default:
        return null
    }
  }

  return <>{renderTypeFields()}</>
}

LanguageFields.propTypes = {
  type: PropTypes.string.isRequired,
  object: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool,
      PropTypes.shape({}),
      PropTypes.arrayOf(
        PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      ),
    ]),
  ).isRequired,
  onInputChange: PropTypes.func.isRequired,
  onValidate: PropTypes.func.isRequired,
}

function LanguageEditCard({
  language,
  type,
  id,
  onSave,
  onDelete,
  disableDelete,
  isExpanded,
  saveButtonLabel,
}) {
  const classes = useStyles()
  const [expanded, setExpanded] = useState(isExpanded)
  const [hasBeenTouched, setHasBeenTouched] = React.useState(false)
  const [object, setObject] = useState()
  const [saving, setSaving] = React.useState({ status: statusTypes.IDLE })
  const [deleting, setDeleting] = React.useState({ status: statusTypes.IDLE })

  const [errorMessages, setErrorMessages] = React.useState([])

  const handleValidation = React.useCallback(
    (hasErrors, itemId, errorMessage) => {
      // If we pass in 'true'
      if (hasErrors) {
        setErrorMessages(
          (prevErrors) =>
            // check if error message already exists
            prevErrors.some((error) => error.itemId === itemId)
              ? prevErrors // error exists, don't add it to the list
              : [...prevErrors, { itemId, message: errorMessage }], // error does not exist, add it to the list
        )
      } else {
        // If false, filter out errors that match this item's id
        setErrorMessages((prevState) => {
          return prevState.filter((error) => error.itemId !== itemId)
        })
      }
    },
    [],
  )

  const handleFetchObject = React.useCallback(
    async (objType, objId, objLang) => {
      const objectResponse = await getObject({
        type: objType,
        id: objId,
        language: objLang,
      })
      const objectObject = objectResponse.toObject()
      objectObject.language = objLang
      setObject(objectObject)
    },
    [],
  )

  const handleExpandClick = async () => {
    setExpanded((prevExpanded) => !prevExpanded)

    // get the object if it doesn't yet exist
    if (!object) {
      handleFetchObject(type, id, language)
    }
  }

  // get video object on initial load for the title component
  useEffect(() => {
    if (type === 'video' && type && id) {
      handleFetchObject(type, id, language)
    }
  }, [type, id, language, handleFetchObject])

  function handleInputChange(event) {
    const {
      target: { name, value, type: eventType, files },
    } = event
    setHasBeenTouched(true)
    setObject({
      ...object,
      [name]: eventType === 'file' ? files[0] : value,
    })
  }

  async function handleSave() {
    try {
      setSaving({ status: statusTypes.PENDING })
      await onSave({ object, language })
      setSaving({ status: statusTypes.SUCCESS })

      // reset fields to make it clear to the person their updates were completed
      setHasBeenTouched(false)
      setTimeout(() => {
        setSaving({ status: statusTypes.IDLE })
      }, 1500)
    } catch (error) {
      setSaving({ status: statusTypes.ERROR, error: { message: error } })
    }
  }

  async function handleDelete() {
    try {
      setDeleting({ status: statusTypes.PENDING })
      await onDelete(language)
      setDeleting({ status: statusTypes.SUCCESS })
    } catch (error) {
      setDeleting({ status: statusTypes.ERROR, error: { message: error } })
    }
  }

  const isVideoPending =
    type === 'video' && object && object.status === 'unready'

  let deleteContentText = ''
  if (type === 'collection' || type === 'publisher') {
    deleteContentText = `Sorry, before you can delete this ${type}, you must first delete all ${
      appLanguagesJson?.[language]?.english || language.toUpperCase()
    } videos associated with it.`
  } else if (type === 'video') {
    deleteContentText =
      'Sorry, before you can delete this video, you must first remove it from any Collections it’s in.'
  }

  return (
    <Card className={classes.root} elevation={0} variant="outlined">
      <CardHeader
        title={
          <LanguageCardTitle
            type={type}
            object={object}
            language={language}
            cardIsExpanded={expanded}
          />
        }
        action={
          <Tooltip
            placement="top"
            title={
              isVideoPending ? 'Preparing video... Will be ready soon!' : ''
            }
          >
            <IconButton
              className={`${classes.expand} ${
                expanded ? classes.expandOpen : ''
              }`}
              onClick={!isVideoPending ? handleExpandClick : null}
              aria-expanded={expanded}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </IconButton>
          </Tooltip>
        }
        className={classes.cardHeader}
      />
      <Collapse in={expanded} timeout="auto" unmountOnExit={true}>
        <CardContent>
          {object ? (
            <LanguageFields
              type={type}
              object={object}
              onInputChange={handleInputChange}
              onValidate={handleValidation}
            />
          ) : null}
        </CardContent>
        <CardActions>
          <Tooltip
            placement="right-end"
            disableHoverListener={Boolean(errorMessages.length === 0)}
            title={errorMessages.map((error) => {
              return <p key={error.itemId}>{error.message}</p>
            })}
          >
            <span>
              <Button
                color={
                  saving.status === statusTypes.ERROR ? 'secondary' : 'primary'
                }
                variant="contained"
                onClick={handleSave}
                disabled={Boolean(
                  errorMessages.length > 0 ||
                    saving.status === statusTypes.PENDING ||
                    !hasBeenTouched,
                )}
              >
                {saving.status === statusTypes.PENDING ? (
                  <>
                    Saving
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  </>
                ) : (
                  saveButtonLabel
                )}
              </Button>
            </span>
          </Tooltip>
          {saving.status === statusTypes.SUCCESS ? (
            <>
              <CheckCircleOutlineIcon style={{ color: green[500] }} />
              <Typography variant="srOnly">Success</Typography>
            </>
          ) : null}
          {saving.status === statusTypes.ERROR ? (
            <>
              <ErrorOutlineIcon style={{ color: red[500] }} />
              <Typography variant="srOnly">Error</Typography>
            </>
          ) : null}
          <ButtonDialog
            actionComponent={
              <Tooltip title={`Delete this ${type} language`}>
                <IconButton
                  aria-label={`Delete ${type} language ${language}`}
                  disabled={
                    disableDelete ||
                    Boolean(deleting.status === statusTypes.PENDING)
                  }
                >
                  {deleting.status === statusTypes.PENDING ? (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  ) : (
                    <DeleteIcon />
                  )}
                </IconButton>
              </Tooltip>
            }
            dialogConfirmText="Delete"
            dialogContentText={deleteContentText}
            dialogDenyText="Cancel"
            dialogPrompt={`Delete ${type} Language?`}
            handleDialogConfirm={handleDelete}
            handleDialogDeny={() => {}} // do nothing, just close the dialog
          />
        </CardActions>
      </Collapse>
    </Card>
  )
}

LanguageEditCard.propTypes = {
  language: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  onSave: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  disableDelete: PropTypes.bool,
  isExpanded: PropTypes.bool,
  saveButtonLabel: PropTypes.string,
}

LanguageEditCard.defaultProps = {
  isExpanded: false,
  saveButtonLabel: 'Save',
  disableDelete: false,
}

export default LanguageEditCard
