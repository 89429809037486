/* eslint-disable no-restricted-globals */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { Component } from 'react'
import {
  Divider,
  Grid,
  IconButton,
  Typography,
  withStyles,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import EditIcon from '@material-ui/icons/Edit'
import ArrowBack from '@material-ui/icons/ArrowBack'
import StarIcon from '@material-ui/icons/Star'
import { Link, Redirect, withRouter } from 'react-router-dom'
import moment from 'moment'
import { getFeaturedCollectionById } from 'utils'
import DataTable from '../../components/layout/data-table'
import SecondaryAppBar from '../../components/layout/secondary-app-bar'
import DetailCard from '../../components/layout/detail-card'
import CollectionDetailRow from '../../components/views/collection/collection-detail-row'
import Video from '../../tupos/models/video'
import Collections from '../../tupos/models/collections'
import Pager from '../../components/ui/pager'
import getParamsFromProps from '../../utils/get-params-from-props'
import Languages from '../../tupos/models/languages'
import LanguageSelector from '../../components/ui/language-selector'
import { withFriendlyErrorPage } from '../../contexts/friendly-error-message-context'

const styles = (theme) => ({
  content: {
    maxWidth: 960,
    margin: 'auto',
    padding: theme.spacing(2),
  },
  subtitle: {
    display: 'flex',
    alignItems: 'center',
    '& svg': { marginRight: 4 },
  },
})

class CollectionDetail extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      collection: [],
      videos: [],
      nextPage: false,
      pageSize: '',
      languages: [],
    }
  }

  componentDidMount() {
    this.loadData()
  }

  componentDidUpdate(prevProps) {
    if (
      getParamsFromProps(this.props, 'page', '1') !==
        getParamsFromProps(prevProps, 'page', '1') ||
      getParamsFromProps(this.props, 'lang', 'en') !==
        getParamsFromProps(prevProps, 'lang', 'en')
    ) {
      this.loadData()
    }
  }

  loadData = async () => {
    this.setState({
      loading: true,
      initialized: false,
    })
    const {
      match: {
        params: { id },
      },
      throwFriendlyErrorPage,
    } = this.props

    const languageFromUrl = getParamsFromProps(this.props, 'lang', undefined)
    let language = languageFromUrl

    try {
      const languages = await Languages.get('collection', id)

      if (!language) {
        language = languages?.includes('en') ? 'en' : languages?.[0]
      }

      const collection = await Collections.get(id, language)

      const { rows: videos, nextPage, pageSize } = await Video.getCollection({
        collection: id,
        fields: '*',
        page: getParamsFromProps(this.props, 'page', '1'),
        language,
        orientation: '*',
        types: '*',
      })

      this.setState(
        {
          collection,
          videos,
          nextPage,
          pageSize,
          languages,
          selectedLanguage: language,
        },
        () => {
          setTimeout(() => {
            this.setState({
              loading: false,
              initialized: true,
            })
          }, 100)
        },
      )
    } catch (error) {
      this.setState({
        loading: false,
        collection: [],
        videos: [],
        nextPage: false,
      })
      throwFriendlyErrorPage({
        message: `Failed to get collection with id (${id}) and language (${language}).`,
      })
    }
  }

  render() {
    const { classes, location, match, history } = this.props
    const {
      loading,
      initialized,
      collection,
      videos,
      nextPage,
      pageSize,
      languages,
      selectedLanguage,
    } = this.state

    if (loading || !initialized) return null

    const languageFromUrl = getParamsFromProps(this.props, 'lang', undefined)
    if (!languageFromUrl)
      return (
        <Redirect
          to={`/collections/${match.params.id}?lang=${selectedLanguage}`}
        />
      )

    const handleLanguageChange = (chosenLanguage) => {
      history.push(`/collections/${collection.id}?lang=${chosenLanguage}`)
    }

    return (
      <>
        <SecondaryAppBar
          actionComponent={
            <IconButton
              aria-label="Edit Collection"
              component={Link}
              to={`edit/${collection.id}${location.search}`}
              variant="contained" // location.search will append lang param
            >
              <EditIcon color="action" />
            </IconButton>
          }
          backComponent={
            <IconButton onClick={history.goBack}>
              <ArrowBack />
            </IconButton>
          }
          subtitle={
            getFeaturedCollectionById(collection.id) ? (
              <Typography className={classes.subtitle} variant="caption">
                <StarIcon fontSize="inherit" />
                Featured Collection
              </Typography>
            ) : null
          }
          title="Collection Detail"
        />
        <Grid className={classes.content}>
          <DetailCard>
            <Grid item={true} xs={12}>
              <LanguageSelector
                changed={handleLanguageChange}
                current={languageFromUrl}
                langs={languages}
              />
              <Divider />
            </Grid>
            <Grid item={true}>
              <Typography variant="h3">{collection.title}</Typography>
              <Typography>{collection.description}</Typography>
            </Grid>
          </DetailCard>
        </Grid>
        <Grid className={classes.content} container={true}>
          <Grid item={true} xs={2}>
            <Typography variant="subtitle2">Display Date</Typography>
            <Typography>
              {isNaN(collection.start)
                ? 'Immediately'
                : moment().dayOfYear(collection.start).format('MMMM D')}
            </Typography>
          </Grid>
          <Grid item={true} xs={2}>
            <Typography variant="subtitle2">End Date</Typography>
            <Typography>
              {isNaN(collection.end)
                ? 'Never'
                : moment().dayOfYear(collection.end).format('MMMM D')}
            </Typography>
          </Grid>
        </Grid>
        <Grid className={classes.content} container={true}>
          <DataTable
            dataSource={videos}
            lang={languageFromUrl}
            pager={
              <Pager
                dataLength={videos.length}
                hasNextPage={nextPage}
                loading={loading}
                onNext={true}
                onPrevious={true}
                pageNumber={getParamsFromProps(this.props, 'page', '1')}
                pageSize={pageSize}
              />
            }
            RowComponent={CollectionDetailRow}
          />
        </Grid>
      </>
    )
  }
}

CollectionDetail.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  history: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.shape({}),
      PropTypes.func,
    ]),
  ).isRequired,
  match: PropTypes.shape({
    params: PropTypes.objectOf(PropTypes.string),
  }).isRequired,
  location: PropTypes.objectOf(PropTypes.string).isRequired,
  throwFriendlyErrorPage: PropTypes.func.isRequired, // from withFriendlyErrorPage
}

export default withFriendlyErrorPage(
  withRouter(withStyles(styles)(CollectionDetail)),
)
