/* eslint-disable no-underscore-dangle */
import { TuposModel } from '@youversion/tupos-base'
// import setDate from '@youversion/tupos-base/dist/setters/date'
import setNumber from '@youversion/tupos-base/dist/setters/number'
import setString from '@youversion/tupos-base/dist/setters/string'
import api4 from '@youversion/tupos-base/dist/fetchers/api4'
import { preventLanguageAction } from 'utils'

// This object is used to cache publisher responses
const publisherResult = {}

/** *
 * Publisher model.
 *
 * @augments TuposModel
 */
class Publisher extends TuposModel {
  constructor(json, language) {
    super(json)
    if (!json || typeof json !== 'object') return
    this.trackingId = json.tracking_id
    this.url = json.url
    this.description = json.description
    this.name = json.name
    this.publisherCollectionResult = {}
    this.id = json.id
    this.language = json.language || language
  }

  /** Convert Publisher to simple object */
  toObject() {
    return {
      tracking_id: this.trackingId,
      url: this.url,
      description: this.description,
      name: this.name,
      id: this.id,
      language: this.language,
    }
  }

  /**
   * Convert object to Publisher.
   */
  static toClass({ json, language }) {
    return new Publisher(json, language)
  }

  /**
   * Fetch a Publisher Resource.
   */
  static async get(id, forceRefresh = false, language) {
    let result

    // We are caching the publisher responses after getting them,
    // but with a flag `forceRefresh` it's easy to get the newest
    // publisher data.
    if (forceRefresh || typeof publisherResult[id] === 'undefined') {
      publisherResult[id] = TuposModel.get(
        api4({
          endpoint: 'movies',
          method: 'publishers/:id',
          version: '4.0',
          auth: true,
          parseJson: true,
          urlParams: {
            id,
          },
          additionalHeaders: {
            'Accept-Language': this.language || language,
          },
        }),
      )

      // let's get that publisher!
      result = await publisherResult[id]

      // cache this response
      publisherResult[id] = result

      // there responses mean we got an error from the api
      if (typeof publisherResult[id] !== 'object') {
        throw new Error(`Failed to retrieve publisher at ID (${id})`)
      } else if (result && result.message) {
        throw new Error(`Publisher: ${result.message}`)
      }
    } else if (typeof publisherResult[id].then === 'function') {
      // I think if we get here, it means that the publisher
      // was requested again just before it finished actually
      // getting the result, so we'll get that for them real quick!
      result = await publisherResult[id]
    } else {
      // If we get here, it means we have a cached version of
      // this specific publisher id.
      result = publisherResult[id]
    }

    // Let's say there was an error that occurred during a
    // previous call of this specific publisher, then we
    // still need to check and see if the error exists, so
    // we can properly handle it.
    if (result.message && /Resource not found/i.test(result.message)) {
      throw new Error(result.message)
    }

    return new Publisher(result)
  }

  /**
   * Fetch a Collection of Publishers Resources.
   */
  static async getCollection(fields, page, pageSize, language = 'en') {
    const json = await TuposModel.get(
      api4({
        endpoint: 'movies',
        method: 'publishers',
        version: '4.0',
        auth: true,
        parseJson: true,
        params: {
          fields,
          page,
          page_size: pageSize,
        },
        additionalHeaders: {
          'Accept-Language': language,
        },
      }),
    )

    if (json && json.message) throw new Error(json.message)

    if (!Array.isArray(json.data)) {
      return { rows: [], nextPage: null, pageSize: null }
    }

    const rows = json.data.map((item) => new Publisher(item))
    return { rows, nextPage: json.next_page, pageSize: json.page_size }
  }

  /**
   * Get fields/presigned-post response for uploading a Banner or Logo image
   * for a Publisher.
   *
   * @param {string} imageType - One of 'banner' or 'logo', per https://jira.lifechurch.tv/browse/BLKBSTR-13.
   * @param {string} languageTag - A valid language tag.
   */
  async getPresignedRequest(languageTag, imageType) {
    preventLanguageAction(languageTag) // Prevent disallowed languages from submitting to the API
    // TODO: Error handling would be GREAT.
    const json = await TuposModel.get(
      api4({
        endpoint: 'movies',
        // This might break, because `:presign` might interfere with Tupos parsing:
        method:
          'publishers/:id:presign?language=:language&image_type=:imageType',
        version: '4.0',
        auth: true,
        parseJson: undefined,
        urlParams: {
          id: this.id,
          language: languageTag,
          imageType,
        },
      }),
    )
    if (typeof json !== 'object') throw new Error()
    if (json && json.message) throw new Error(json.message)
    return json
  }

  /**
   * Prior to saving a collection, we need to reserve the ID per API spec.
   */
  // eslint-disable-next-line class-methods-use-this
  async reservePublisherId() {
    const json = await TuposModel.get(
      api4({
        endpoint: 'movies',
        method: 'publishers:reserve',
        version: '4.0',
        auth: true,
        parseJson: undefined,
        fetchArgs: { method: 'POST' },
        additionalHeaders: {
          'Content-Type': 'application/json',
        },
      }),
    )
    if (typeof json !== 'object') throw new Error()
    return json
  }

  /**
   * Delete a Publisher Resource.
   *
   * @param {string} language - Locale code for publisher.
   *
   * @throws {Error} - If delete fails, a helpful error is thrown.
   */
  async delete(language) {
    const response = await TuposModel.get(
      api4({
        auth: true,
        bodyParams: {
          language,
        },
        endpoint: 'movies',
        fetchArgs: { method: 'DELETE' },
        method: 'publishers/:id',
        parseJson: false,
        urlParams: {
          id: this.id,
        },
        version: '4.0',
      }),
    )

    // https://restfulapi.net/http-methods/ for DELETE
    if (response?.message || ![200, 202, 204].includes(response.status)) {
      throw new Error(
        'Sorry, before you can delete this Publisher, you must first delete every language associated with it.',
      )
    }
  }

  /** Update a Publisher Resource(Name & URL) */
  async updateNameUrl() {
    const json = await TuposModel.get(
      api4({
        endpoint: 'movies',
        method: 'publishers/:id',
        version: '4.0',
        auth: true,
        parseJson: undefined,
        urlParams: {
          id: this.id,
        },
        fetchArgs: { method: 'PUT' },
        bodyParams: {
          name: this.name,
          url: this.url,
          tracking_id: this.trackingId || undefined,
        },
      }),
    )

    if (typeof json !== 'object') throw new Error()

    return new Publisher(json)
  }

  /**
   * Add a new Language + Description to a Publisher Resource.
   *
   * MUST happen AFTER Banner+Logo images are in place, or the API call will fail.
   */
  async addLanguage(newLanguageTag, newDescription) {
    preventLanguageAction(newLanguageTag) // Prevent disallowed languages from submitting to the API
    const json = await TuposModel.get(
      api4({
        endpoint: 'movies',
        method: 'publishers',
        version: '4.0',
        auth: true,
        parseJson: undefined,
        urlParams: {
          // No params, per https://jira.lifechurch.tv/browse/BLKBSTR-13
        },
        fetchArgs: { method: 'POST' },
        bodyParams: {
          id: this.id,
          language: newLanguageTag,
          description: newDescription,
          created_dt: new Date().toISOString(),
        },
      }),
    )
    if (typeof json !== 'object') throw new Error()
    return new Publisher(json)
  }

  /**
   * Update a Publisher Resource(Description & Language).
   */
  async updateDescriptionLanguage(language) {
    const json = await TuposModel.get(
      api4({
        endpoint: 'movies',
        method: 'publishers/:id',
        version: '4.0',
        auth: true,
        parseJson: undefined,
        urlParams: {
          id: this.id,
        },
        fetchArgs: { method: 'PUT' },
        bodyParams: {
          description: this.description,
          language,
        },
        additionalHeaders: {
          'Accept-Language': language,
        },
      }),
    )

    if (typeof json !== 'object') throw new Error()

    return new Publisher(json)
  }

  /** Adding a Publisher Resource */
  async addPublisher() {
    preventLanguageAction(this.language) // Prevent disallowed languages from submitting to the API
    const json = await TuposModel.get(
      api4({
        endpoint: 'movies',
        method: 'publishers',
        version: '4.0',
        auth: true,
        parseJson: undefined,
        fetchArgs: { method: 'POST' },
        bodyParams: {
          id: this.id,
          name: this.name || undefined,
          url: this.url || undefined,
          description: this.description || undefined,
          language: this.language,
          created_dt: new Date().toISOString(),
          tracking_id: this.trackingId || undefined,
        },
      }),
    )

    if (typeof json !== 'object') throw new Error()

    return new Publisher(json)
  }

  /** @type {string} */
  get trackingId() {
    return this._trackingId
  }

  set trackingId(trackingId) {
    this._trackingId = setString(trackingId, 'trackingId')
  }

  get language() {
    return this._language
  }

  set language(language) {
    this._language = setString(language, 'language')
  }

  /** @type {string} */
  get url() {
    return this._url
  }

  set url(url) {
    this._url = setString(url, 'url')
  }

  /** @type {Date} */
  get description() {
    return this._description
  }

  set description(description) {
    this._description = setString(description, 'description')
  }

  /** @type {string} */
  get name() {
    return this._name
  }

  set name(name) {
    this._name = setString(name, 'name')
  }

  /** @type {number} */
  get id() {
    return this._id
  }

  set id(id) {
    this._id = setNumber(id, 'id')
  }
}

export default Publisher
