/**
 * Replaces URL param or adds given param if it doesn't exist,
 * while keeping all existing params intact. Returns updated url.
 * */
export default function replaceUrlParam(location, paramKey, paramValue) {
  if (!location) throw new Error('location object was not passed')
  if (!paramKey || !paramValue)
    throw new Error('new param key or param value was not passed')

  const { search, pathname } = location

  const urlSearchParams = new URLSearchParams(search)
  const paramExists = urlSearchParams.has(paramKey)

  if (paramExists) {
    urlSearchParams.delete(paramKey)
  }

  urlSearchParams.append(paramKey, paramValue)

  return `${pathname}?${urlSearchParams.toString()}`
}
