import React from 'react'
import PropTypes from 'prop-types'
import { Card, CardContent, Grid, makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: theme.spacing(2),
    width: '100%',
  },
}))

const DetailCard = ({ children }) => {
  const classes = useStyles()
  return (
    <Card className={classes.card} variant="outlined">
      <CardContent>
        <Grid container={true} spacing={5}>
          <>{children}</>
        </Grid>
      </CardContent>
    </Card>
  )
}

DetailCard.propTypes = {
  children: PropTypes.node.isRequired,
}

export default DetailCard
