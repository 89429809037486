/**
 * @module AuthError
 */
import React from 'react'
import { Button, Typography } from '@material-ui/core'
import { useYouVersionAuth } from '@youversion/react'

/**
 * To be displayed when the user has a valid YouVersion token which does not meet the authentication requirements for this app.
 *
 * @returns {React.ReactElement} - The AuthError component.
 */
export function AuthError() {
  const { signOut } = useYouVersionAuth()

  function handleSignOut() {
    signOut()
    window.location.reload()
  }

  return (
    <>
      <Typography variant="h2">Auth error</Typography>
      <Button onClick={handleSignOut} variant="contained">
        Sign Out
      </Button>
    </>
  )
}
