import React from 'react'
import PropTypes from 'prop-types'
import { TextField, makeStyles } from '@material-ui/core'
import ImageFileInput from '../../../ui/image-file-input'
import Configuration from '../../../../tupos/models/configuration'
import urlReplacer from '../../../../utils/url-replacer'
import { statusTypes } from '../../../../utils/constants'
import { useAlert } from '../../../ui/alerts'

const useStyles = makeStyles(() => ({
  textField: {
    marginBottom: 15,
  },
}))

function CollectionLanguageFields({
  collection,
  onInputChange,
  onValidate,
  hasThumbnail,
}) {
  const classes = useStyles()
  const { throwAlert } = useAlert()
  const [thumbnailUrl, setThumbnailUrl] = React.useState('')
  const [loading, setLoading] = React.useState({ status: statusTypes.PENDING })

  React.useEffect(() => {
    async function getConfiguration() {
      try {
        const config = await Configuration.get()
        const imageUrl = config.imageUrls.collection
        const collectionImageUrl = urlReplacer(imageUrl, {
          // Original size: 2000 x 1125
          width: '800',
          height: '450',
          id: collection.id,
          language: collection.language,
        })
        setThumbnailUrl(collectionImageUrl)
        setLoading({ status: statusTypes.RESOLVED })
      } catch (error) {
        setLoading({ status: statusTypes.REJECTED })
        throwAlert({
          type: statusTypes.ERROR,
          id: `image_url_schema_error_${collection.language}`,
          message: `Error loading image url for language ${collection.language.toUppercase()}.`,
        })
      }
    }
    if (hasThumbnail) {
      getConfiguration()
    } else {
      setLoading({ status: statusTypes.RESOLVED })
    }
  }, [hasThumbnail, collection.id, collection.language, throwAlert])

  React.useEffect(() => {
    if (!collection.title) {
      onValidate(
        true,
        collection.language ? `${collection.language}_title` : 'title',
        'Collection title is required',
      )
    } else {
      onValidate(
        false,
        collection.language ? `${collection.language}_title` : 'title',
      )
    }
  }, [onValidate, collection.language, collection.title])

  React.useEffect(() => {
    if (!collection.description) {
      onValidate(
        true,
        collection.language
          ? `${collection.language}_description`
          : 'description',
        'Collection description is required',
      )
    } else {
      onValidate(
        false,
        collection.language
          ? `${collection.language}_description`
          : 'description',
      )
    }
  }, [onValidate, collection.description, collection.language])

  React.useEffect(() => {
    if (!hasThumbnail && !collection.thumbnailFile) {
      onValidate(
        true,
        collection.language ? `${collection.language}_thumbnail` : 'thumbnail',
        'Collection thumbnail is required',
      )
    } else {
      onValidate(
        false,
        collection.language ? `${collection.language}_thumbnail` : 'thumbnail',
      )
    }
  }, [onValidate, hasThumbnail, collection.thumbnailFile, collection.language])

  return (
    <>
      <TextField
        id="title"
        label="Title"
        name="title"
        value={collection.title}
        className={classes.textField}
        fullWidth
        onChange={onInputChange}
      />
      <TextField
        id="description"
        label="Description"
        name="description"
        value={collection.description}
        className={classes.textField}
        fullWidth
        multiline
        rows="5"
        onChange={onInputChange}
      />
      {loading.status === statusTypes.RESOLVED ? (
        <ImageFileInput
          id={
            collection.language
              ? `${collection.language}_thumbnail_image`
              : 'thumbnail_image'
          }
          name="thumbnailFile"
          labelText="Thumbnail (2000x1125px)"
          onChange={onInputChange}
          defaultImage={thumbnailUrl}
          onValidate={onValidate}
          previewWidth={800}
          previewHeight={450}
          validateWidth={2000}
          validateHeight={1125}
          validateMaxFileSize={1000000}
          validateFileType="image/jpg, image/jpeg"
        />
      ) : null}
    </>
  )
}

CollectionLanguageFields.propTypes = {
  collection: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool,
      PropTypes.shape({}),
      PropTypes.arrayOf(
        PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      ),
    ]),
  ).isRequired,
  onInputChange: PropTypes.func.isRequired,
  onValidate: PropTypes.func.isRequired,
  hasThumbnail: PropTypes.bool,
}

CollectionLanguageFields.defaultProps = {
  hasThumbnail: false,
}

export default CollectionLanguageFields
