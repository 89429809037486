/**
 * @module preventLanguageAction
 */

const disallowedLanguages = {
  pt_BR: {
    validEquivalent: 'pt',
  },
}

/**
 * Throw an error to prevent action on a disallowed language.
 *
 * @param {string} language - A language tag. (e.g. 'en', 'de', 'pt_BR')
 *
 * @alias module:preventLanguageAction
 *
 * @throws {Error} - Throws an error if the language is not allowed.
 *
 * @example
 * // throws an error
 * preventLanguageAction('pt_BR')
 */
export function preventLanguageAction(language) {
  if (disallowedLanguages[language]) {
    throw new Error(
      `Language tag '${language}' is not allowed. Try '${disallowedLanguages[language].validEquivalent}' instead.`,
    )
  }
}
