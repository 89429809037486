import React from 'react'
import PropTypes from 'prop-types'
import { TextField, makeStyles } from '@material-ui/core'
import { imageDimensions } from 'utils'
import ImageFileInput from '../../../ui/image-file-input'
import Configuration from '../../../../tupos/models/configuration'
import urlReplacer from '../../../../utils/url-replacer'
import { useAlert } from '../../../ui/alerts'

const useStyles = makeStyles(() => ({
  textField: {
    marginBottom: 15,
  },
}))

/**
 * Publisher language form fields.
 *
 * @param {Object} props
 * @param {Object} props.publisher Publisher object
 * @param {Function} props.onInputChange Function to handle input change
 * @param {Function} props.setHasValidationErrors Function to handle validation reporting.
 * @param {Boolean} [hasThumbnails] True/false if the image fields have existing image files
 */
export default function PublisherLanguageFields({
  publisher,
  onInputChange,
  onValidate,
  hasThumbnails,
}) {
  const classes = useStyles()
  const { throwAlert } = useAlert()
  const [bannerUrl, setBannerUrl] = React.useState('')
  const [logoUrl, setLogoUrl] = React.useState('')
  const [loading, setLoading] = React.useState({ status: 'loading' })

  React.useEffect(() => {
    async function getConfiguration() {
      try {
        const config = await Configuration.get()
        const publisherLogoImageUrl = urlReplacer(
          config.imageUrls.publisherLogo,
          {
            size: imageDimensions.PUBLISHER_LOGO.size,
            id: publisher.id,
            language: publisher.language,
          },
        )
        const publisherBannerImageUrl = urlReplacer(
          config.imageUrls.publisherBanner,
          {
            width: imageDimensions.PUBLISHER_BANNER.width,
            height: imageDimensions.PUBLISHER_BANNER.height,
            id: publisher.id,
            language: publisher.language,
          },
        )
        setLogoUrl(publisherLogoImageUrl)
        setBannerUrl(publisherBannerImageUrl)
        setLoading({ status: 'loaded' })
      } catch (error) {
        throwAlert({
          type: 'error',
          id: 'image_url_config_error',
          message: `Error loading image url data`,
        })
      }
    }
    if (hasThumbnails) {
      getConfiguration()
    } else {
      setLoading({ status: 'loaded' })
    }
  }, [hasThumbnails, publisher.id, publisher.language, throwAlert])

  React.useEffect(() => {
    if (!publisher.description) {
      onValidate(
        true,
        publisher.language
          ? `${publisher.language}_description`
          : 'description',
        'Publisher description is required',
      )
    } else {
      onValidate(
        false,
        publisher.language
          ? `${publisher.language}_description`
          : 'description',
      )
    }
  }, [publisher.description, publisher.language, onValidate])

  React.useEffect(() => {
    if (!hasThumbnails && !publisher.bannerFile) {
      onValidate(
        true,
        publisher.language ? `${publisher.language}_banner` : 'banner',
        'Publisher banner is required',
      )
    } else {
      onValidate(
        false,
        publisher.language ? `${publisher.language}_banner` : 'banner',
      )
    }
  }, [publisher.bannerFile, publisher.language, onValidate, hasThumbnails])

  React.useEffect(() => {
    if (!hasThumbnails && !publisher.logoFile) {
      onValidate(
        true,
        publisher.language ? `${publisher.language}_logo` : 'logo',
        'Publisher logo is required',
      )
    } else {
      onValidate(
        false,
        publisher.language ? `${publisher.language}_logo` : 'logo',
      )
    }
  }, [publisher.logoFile, publisher.language, onValidate, hasThumbnails])

  return (
    <>
      <TextField
        id={
          publisher.language
            ? `${publisher.language}_description`
            : 'description'
        }
        label="Description"
        name="description"
        value={publisher.description}
        className={classes.textField}
        fullWidth={true}
        multiline={true}
        rows="5"
        onChange={onInputChange}
        required={true}
      />
      {loading.status === 'loaded' ? (
        <>
          <ImageFileInput
            id={
              publisher.language
                ? `${publisher.language}_banner_image`
                : 'banner_image'
            }
            name="bannerFile"
            labelText={`Banner (${imageDimensions.PUBLISHER_BANNER.width}x${imageDimensions.PUBLISHER_BANNER.height}px)`}
            onChange={onInputChange}
            defaultImage={bannerUrl}
            onValidate={onValidate}
            previewWidth={800}
            previewHeight={267}
            validateWidth={imageDimensions.PUBLISHER_BANNER.width}
            validateHeight={imageDimensions.PUBLISHER_BANNER.height}
            validateMaxFileSize={1000000}
            validateFileType="image/jpg, image/jpeg"
          />
          <ImageFileInput
            id={
              publisher.language
                ? `${publisher.language}_logo_image`
                : 'logo_image'
            }
            name="logoFile"
            labelText={`Logo (${imageDimensions.PUBLISHER_LOGO.size}x${imageDimensions.PUBLISHER_LOGO.size}px)`}
            onChange={onInputChange}
            defaultImage={logoUrl}
            onValidate={onValidate}
            previewWidth={imageDimensions.PUBLISHER_LOGO.size}
            previewHeight={imageDimensions.PUBLISHER_LOGO.size}
            validateWidth={imageDimensions.PUBLISHER_LOGO.size}
            validateHeight={imageDimensions.PUBLISHER_LOGO.size}
            validateMaxFileSize={1000000}
            validateFileType="image/png"
          />
        </>
      ) : null}
    </>
  )
}

PublisherLanguageFields.propTypes = {
  publisher: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool,
      PropTypes.shape({}),
      PropTypes.arrayOf(
        PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      ),
    ]),
  ).isRequired,
  onInputChange: PropTypes.func.isRequired,
  onValidate: PropTypes.func.isRequired,
  hasThumbnails: PropTypes.bool,
}

PublisherLanguageFields.defaultProps = {
  hasThumbnails: false,
}
