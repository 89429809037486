import React from 'react'
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom'
import YVAppBar from 'components/layout/yv-app-bar'

// Collection
import CollectionAdd from 'containers/collection/collection-add'
import CollectionDetail from 'containers/collection/collection-detail'
import CollectionEdit from 'containers/collection/collection-edit'
import CollectionList from 'containers/collection/collection-list'

// Dashboard
import Dashboard from 'containers/dashboard'

// Publisher
import PublisherAdd from 'containers/publisher/publisher-add'
import PublisherDetail from 'containers/publisher/publisher-detail'
import PublisherEdit from 'containers/publisher/publisher-edit'
import PublisherList from 'containers/publisher/publisher-list'

// Video
import VideoAdd from 'containers/video/video-add'
import VideoDetail from 'containers/video/video-detail'
import VideoList from 'containers/video/video-list'

import { AlertBar, AlertProvider } from 'components/layout/alert-bar'
import { FriendlyErrorPageProvider } from 'contexts/friendly-error-message-context'
import { makeStyles } from '@material-ui/core/styles'
import { useYouVersionAuth } from '@youversion/react/providers'

const useStyles = makeStyles((theme) => ({
  mainContent: {
    paddingTop: 64,
    [theme.breakpoints.down('sm')]: {
      paddingTop: 56,
    },
    marginLeft: 0,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}))

export function AppAuthenticated() {
  const classes = useStyles()
  const { signOut, user } = useYouVersionAuth()

  return (
    <Router>
      <>
        <YVAppBar user={user} signOut={signOut} />
        <main className={classes.mainContent}>
          <FriendlyErrorPageProvider>
            <div>
              <Switch>
                <Route path="/" exact={true}>
                  <Dashboard />
                </Route>
                <Route path="/collections" exact={true}>
                  <CollectionList />
                </Route>
                <Route path="/collections/add/:uuid?" exact={true}>
                  <CollectionAdd />
                </Route>
                <Route path="/collections/:id" exact={true}>
                  <CollectionDetail />
                </Route>
                <Route path="/publishers" exact={true}>
                  <PublisherList />
                </Route>
                <Route path="/publisher/add" exact={true}>
                  <PublisherAdd />
                </Route>
                <Route path="/publishers/:id" exact={true}>
                  <AlertProvider>
                    <AlertBar />
                    <PublisherDetail />
                  </AlertProvider>
                </Route>
                <Route path="/publishers/edit/:id" exact={true}>
                  <PublisherEdit />
                </Route>
                <Route path="/videos" exact={true}>
                  <AlertProvider>
                    <AlertBar />
                    <VideoList />
                  </AlertProvider>
                </Route>
                <Route path="/videos/add/:uuid?" exact={true}>
                  <VideoAdd />
                </Route>
                <Route path="/videos/:id" exact={true}>
                  <VideoDetail />
                </Route>
                <Route path="/collections/edit/:id" exact={true}>
                  <CollectionEdit />
                </Route>
                <Redirect to="/" />
              </Switch>
            </div>
          </FriendlyErrorPageProvider>
        </main>
      </>
    </Router>
  )
}
