/* eslint-disable jsx-a11y/alt-text, jsx-a11y/media-has-caption */
import React from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import {
  Box,
  Button,
  Grid,
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
  Paper,
  Tab,
  Tabs,
  Tooltip,
  Typography,
  makeStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import ArrowBack from '@material-ui/icons/ArrowBack'
import BulkVideoPublisher from 'components/views/publisher/bulk-video-publisher'
import BulkVideoUploader from 'components/views/publisher/bulk-video-uploader'
import Configuration from 'tupos/models/configuration'
import EditIcon from '@material-ui/icons/Edit'
import Languages from 'tupos/models/languages'
import LanguageSelector from 'components/ui/language-selector'
import LaunchIcon from '@material-ui/icons/Launch'
import LazyImage from 'components/ui/lazy-image'
import PropTypes from 'prop-types'
import Publisher from 'tupos/models/publisher'
import SecondaryAppBar from 'components/layout/secondary-app-bar'
import TranslateIcon from '@material-ui/icons/Translate'
import Video from 'tupos/models/video'
import VideosTable from 'components/views/publisher/videos-table'
import {
  imageDimensions,
  replaceUrlParam,
  statusTypes,
  urlReplacer,
} from 'utils'
import { Api4Client } from '@youversion/api/4.0/core'
import { useAlert } from 'components/layout/alert-bar'
import { useFriendlyErrorPage } from 'contexts/friendly-error-message-context'
import { useYouVersionAuth } from '@youversion/react'
import normalizeUrl from 'normalize-url'
import DetailModal from './publisher-detail-language-modal'

const useStyles = makeStyles((theme) => ({
  // Detailed Card width
  card: {
    width: '100%',
  },

  // Language Chips
  chip: {
    margin: theme.spacing(1),
  },

  content: {
    margin: 'auto',
    maxWidth: 960,
    padding: theme.spacing(2),
  },
  imgPublisherLogo: {
    borderRadius: '8px',
    height: 150,
    width: 150,
  },
  imgPublisherBanner: {
    borderRadius: '8px',
    height: `${imageDimensions.PUBLISHER_BANNER.height}px`,
    [theme.breakpoints.down('sm')]: {
      borderRadius: 0,
      height: `calc(100vw * ${
        imageDimensions.PUBLISHER_BANNER.height /
        imageDimensions.PUBLISHER_BANNER.width
      })`,
      marginLeft: -16,
      marginRight: -16,
      marginTop: -24,
      width: '100vw',
    },
  },

  // Hyperlinks
  link: {
    color: 'rgba(0, 0, 0, 0.54)',
  },

  noMargin: {
    margin: 0,
  },
  pubNameContainer: {
    marginBottom: theme.spacing(1),
  },
  tabs: {
    flexGrow: 1,
  },
  view: {
    minHeight: '125vh', // this makes it easy to switch between video tabs without jumpiness
  },
  videosContainer: {
    marginTop: theme.spacing(2),
  },
}))

/* https://material-ui.com/components/tabs/ */
function TabPanel(props) {
  const { children, currentTab, tabValue, ...other } = props

  return (
    <div
      aria-labelledby={`wrapped-tab-${tabValue}`}
      hidden={currentTab !== tabValue}
      id={`wrapped-tabpanel-${tabValue}`}
      role="tabpanel"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...other}
    >
      {currentTab === tabValue ? <Box mt={2}>{children}</Box> : null}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  currentTab: PropTypes.string.isRequired,
  tabValue: PropTypes.string.isRequired,
}

const getVideoData = async (
  pageNumber,
  publisherId,
  language,
  videoStatus = '*',
) =>
  Video.getCollection({
    language,
    orientation: '*',
    page: pageNumber,
    publisher: publisherId,
    status: videoStatus,
    types: '*',
  })

function PublisherDetail() {
  const classes = useStyles()
  const history = useHistory()
  const theme = useTheme()

  const { getToken } = useYouVersionAuth()
  const { throwAlert } = useAlert()
  const { id: publisherId } = useParams()

  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))
  const selectedPageNumber =
    new URLSearchParams(history.location.search).get('page') || 1
  const { throwFriendlyErrorPage } = useFriendlyErrorPage()

  const [config, setConfig] = React.useState()
  const [currentTab, setCurrentTab] = React.useState(() => {
    const availableTabs = ['uploaded', 'failed', 'airtable', 'ready']
    const tabParam = new URLSearchParams(history.location.search).get('tab')
    return availableTabs.includes(tabParam) ? tabParam : 'uploaded'
  })
  const [langVideoPairs, setLangVideoPairs] = React.useState({})
  const [nextPage, setNextPage] = React.useState(false)
  const [openLanguageModal, setOpenLanguageModal] = React.useState(false)
  const [pageSize, setPageSize] = React.useState()
  const [publisher, setPublisher] = React.useState()
  const [publisherLanguages, setPublisherLanguages] = React.useState([])
  const [selectedLanguage, setSelectedLanguage] = React.useState(() =>
    new URLSearchParams(history.location.search).get('lang'),
  )
  const [selectedVideosStatus, setSelectedVideosStatus] = React.useState('*')
  const [videos, setVideos] = React.useState([])
  const [videoRetreivalStatus, setVideoRetreivalStatus] = React.useState(
    statusTypes.IDLE,
  )

  // Once video status state is updated, get new videos of that video status
  React.useEffect(() => {
    const setNewVideos = (videosResponse) => {
      setVideos(videosResponse.rows)
      setNextPage(videosResponse.nextPage)
      setPageSize(videosResponse.pageSize)
    }

    const getNewVideos = async () => {
      setVideoRetreivalStatus(statusTypes.PENDING)
      const videosResponse = await getVideoData(
        selectedPageNumber,
        publisherId,
        selectedLanguage,
        selectedVideosStatus,
      )
      setNewVideos(videosResponse)
      // There is no rejected because if the call fails, it'll send
      // back an empty response in Tupos.
      setVideoRetreivalStatus(statusTypes.RESOLVED)
    }

    if (config && selectedLanguage && selectedVideosStatus) {
      getNewVideos()
    }
  }, [
    config,
    publisherId,
    selectedPageNumber,
    selectedLanguage,
    selectedVideosStatus,
    publisherLanguages,
  ])

  // Once the videos tab is changed, update the video status to
  // trigger useEffect that gets videos of that status
  React.useEffect(() => {
    const resetVideosState = () => {
      setVideoRetreivalStatus(statusTypes.IDLE)
      setVideos([])
    }
    const resetPageParam = () => {
      history.replace(replaceUrlParam(history.location, 'page', 1))
    }

    const videoStatusPerTab = {
      uploaded: 'published',
      ready: null,
      airtable: null,
      failed: 'failed',
    }

    resetVideosState()
    resetPageParam()

    const newVideosStatus = videoStatusPerTab[currentTab]
    setSelectedVideosStatus(newVideosStatus)
  }, [currentTab, history])

  // Gets config and publisher languages
  React.useEffect(() => {
    const getConfigAndPublisherLangs = async () => {
      const configResponse = await Configuration.get()

      const publisherLangsResponse = await Languages.get(
        'publisher',
        publisherId,
      )
      setConfig(configResponse)
      setPublisherLanguages(publisherLangsResponse)
    }

    try {
      getConfigAndPublisherLangs()
    } catch (e) {
      throwFriendlyErrorPage({
        message: e.message,
        title: 'Failed to get publisher languages',
      })
    }
  }, [publisherId, throwFriendlyErrorPage])

  // Sets selected language if absent
  React.useEffect(() => {
    if (!selectedLanguage && publisherLanguages?.length > 0) {
      const nextAvailableLang = publisherLanguages.includes('en')
        ? 'en'
        : publisherLanguages[0]
      setSelectedLanguage(nextAvailableLang)
      history.replace(
        replaceUrlParam(history.location, 'lang', nextAvailableLang),
      )
    }
  }, [selectedLanguage, publisherLanguages, history])

  // Gets publisher for on selected language update
  React.useEffect(() => {
    const getPublisher = async () => {
      try {
        const publisherResponse = await Publisher.get(
          publisherId,
          true,
          selectedLanguage,
        )
        setPublisher(publisherResponse)
      } catch (e) {
        throwFriendlyErrorPage({
          message: e.message,
          title: `Publisher not found with lang (${selectedLanguage})`,
        })
      }
    }

    if (publisherId && selectedLanguage) {
      getPublisher()
    }
  }, [selectedLanguage, publisherId, throwFriendlyErrorPage])

  // Count all videos for the publisher.
  React.useEffect(() => {
    async function countVideosByLanguage() {
      const token = await getToken()

      const publisherLangsResponse = await Languages.get(
        'publisher',
        publisherId,
      )
      Promise.all(
        publisherLangsResponse.map((lang) => {
          return Api4Client.get({
            token,
            subDomain: 'movies',
            path: 'videos',
            options: {
              headers: {
                'Accept-Language': lang,
              },
              cache: 'no-cache',
            },
            params: {
              fields: 'id',
              page: '*',
              publisher: publisherId,
              types: '*',
              status: 'published',
              orientation: '*',
            },
          })
        }),
      )
        .then((videosResponse) => {
          const languageData = {}
          videosResponse.forEach((videoResponse, index) => {
            languageData[publisherLangsResponse[index]] =
              videoResponse.$data || []
          })
          setLangVideoPairs(languageData)
        })
        .catch(() => {
          throwAlert({
            type: statusTypes.ERROR,
            id: `api_4_client_get_videos_error`,
            message: `Error fetching videos by languages for a publisher.`,
          })
        })
    }
    countVideosByLanguage()
  }, [getToken, publisherId, throwAlert])

  const allVideosLength = React.useMemo(() => {
    let count = 0
    Object.values(langVideoPairs).forEach((value) => {
      count += value.length
    })
    return count
  }, [langVideoPairs])

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue)
  }

  if (!config || !publisher) return null

  const publisherBannerUrl = urlReplacer(config.imageUrls.publisherBanner, {
    height: imageDimensions.PUBLISHER_BANNER.height,
    id: publisher.id,
    language: selectedLanguage,
    width: imageDimensions.PUBLISHER_BANNER.width,
  })
  const publisherLogoUrl = urlReplacer(config.imageUrls.publisherLogo, {
    id: publisher.id,
    language: selectedLanguage,
    size: '150',
  })

  const handleLanguageChange = (chosenLanguage) => {
    setSelectedLanguage((prevLanguage) => {
      if (chosenLanguage !== prevLanguage) {
        // Update url param for new lang
        history.push(replaceUrlParam(history.location, 'lang', chosenLanguage))
        setOpenLanguageModal(false)
      }
      return chosenLanguage
    })
  }

  const languagesString =
    publisherLanguages.length === 1 ? 'language' : 'languages'

  const videosTable = (
    <VideosTable
      getVideosStatus={videoRetreivalStatus}
      language={selectedLanguage}
      nextPage={nextPage}
      pageSize={pageSize}
      publisherId={publisherId}
      selectedPage={selectedPageNumber}
      videos={videos}
      videoStatusType={selectedVideosStatus}
    />
  )

  return (
    <div className={classes.view}>
      <SecondaryAppBar
        actionComponent={
          <>
            <Tooltip title="Change Publisher Language">
              <IconButton
                aria-label="Change Publisher Language"
                onClick={() => setOpenLanguageModal(true)}
              >
                <TranslateIcon color="action" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Edit Publisher">
              <IconButton
                aria-label="Edit Publisher"
                component={Link}
                replace={true}
                to={`edit/${publisher.id}${history.location.search}`} // location.search will append lang param
              >
                <EditIcon color="action" />
              </IconButton>
            </Tooltip>
          </>
        }
        backComponent={
          <IconButton onClick={history.goBack}>
            <ArrowBack />
          </IconButton>
        }
        passItBack={true}
        title="Publisher Detail"
      />

      <Grid className={classes.content} container={true}>
        <Grid container={true} item={true}>
          <Box mt={1} mb={2} width="100%">
            <LazyImage
              alt={`${publisher.name} Banner`}
              className={classes.imgPublisherBanner}
              height={309}
              src={publisherBannerUrl}
              width={928}
            />
          </Box>
        </Grid>
        <Grid item={true} xs={12}>
          <Box>
            <Box display="flex" flexGrow={1} mt={1}>
              <Box flexGrow={1}>
                <Typography gutterBottom={true} variant="h2">
                  {publisher.name}
                </Typography>
                <Typography color="textSecondary" variant="body1">
                  {publisher.description}
                </Typography>
                <Box mt={2}>
                  <Button
                    aria-label={`Open ${publisher.name} website`}
                    href={normalizeUrl(publisher.url, { forceHttps: true })}
                    target="_blank"
                    variant="outlined"
                  >
                    Publisher Website &nbsp;
                    <LaunchIcon fontSize="small" />
                  </Button>
                </Box>
              </Box>
              {isDesktop ? (
                <Box ml={2}>
                  <LazyImage
                    alt={`${publisher.name} Logo`}
                    className={classes.imgPublisherLogo}
                    height={150}
                    src={publisherLogoUrl}
                    width={150}
                  />
                </Box>
              ) : null}
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Dialog
        aria-labelledby="change-publisher-language"
        onClose={() => setOpenLanguageModal(false)}
        open={openLanguageModal}
      >
        <DialogTitle id="change-publisher-language">
          Change Publisher Language
        </DialogTitle>
        <DialogContent>
          <Box mb={2}>
            <LanguageSelector
              changed={handleLanguageChange}
              current={selectedLanguage}
              fullWidth={true}
              langs={publisherLanguages}
            />
          </Box>
        </DialogContent>
      </Dialog>

      <Grid className={classes.content} container={true} direction="column">
        <Grid container={true}>
          <Grid item={true} xs={12}>
            <Box alignItems="center" display="flex" flexDirection="row">
              <Box flexGrow={1}>
                <Typography gutterBottom={true} variant="h2">
                  Videos
                </Typography>
                <Typography
                  color="textSecondary"
                  gutterBottom={true}
                  variant="body1"
                >
                  {publisher.name} has {allVideosLength} published videos in{' '}
                  {publisherLanguages?.length} {languagesString}.
                </Typography>
                {Object.keys(langVideoPairs).length > 0 ? (
                  <DetailModal languages={langVideoPairs} />
                ) : null}
              </Box>
              <Tooltip title="Add New Video">
                <IconButton
                  aria-label="Add New Video"
                  component={Link}
                  to={`/videos/add/?publisher=${publisher.id}&lang=${selectedLanguage}`}
                >
                  <AddIcon color="action" fontSize="small" variant="outlined" />
                </IconButton>
              </Tooltip>
            </Box>
          </Grid>
        </Grid>
        <Grid
          className={classes.videosContainer}
          component={Paper}
          container={true}
          direction="column"
          variant="outlined"
        >
          <Paper className={classes.tabs}>
            <Tabs
              centered={true}
              indicatorColor="primary"
              onChange={handleTabChange}
              textColor="primary"
              value={currentTab}
            >
              <Tab
                aria-label="published videos tab"
                label="PUBLISHED"
                value="uploaded"
              />
              <Tab
                aria-label="ready to publish videos tab"
                label="READY TO PUBLISH"
                value="ready"
                wrapped={true}
              />
              <Tab
                aria-label="airtable videos tab"
                label="AirTable Submissions"
                value="airtable"
                wrapped={true}
              />
              <Tab
                aria-label="failed videos tab"
                label="FAILED"
                value="failed"
              />
            </Tabs>
          </Paper>
          <TabPanel
            className={classes.tabPanel}
            currentTab={currentTab}
            tabValue="uploaded"
          >
            {videosTable}
          </TabPanel>
          <TabPanel
            className={classes.tabPanel}
            currentTab={currentTab}
            tabValue="ready"
          >
            <BulkVideoPublisher
              config={config}
              publisherId={parseInt(publisherId, 10)}
              publisherLanguages={publisherLanguages}
            />
          </TabPanel>
          <TabPanel
            className={classes.tabPanel}
            currentTab={currentTab}
            tabValue="airtable"
          >
            <BulkVideoUploader
              publisher={publisher}
              publisherLanguages={publisherLanguages}
            />
          </TabPanel>
          <TabPanel
            className={classes.tabPanel}
            currentTab={currentTab}
            tabValue="failed"
          >
            {videosTable}
          </TabPanel>
        </Grid>
      </Grid>
    </div>
  )
}

export default PublisherDetail
