/**
 * @module VideoList
 */

import React from 'react'
import DataTable from 'components/layout/data-table'
import LanguageSelector from 'components/ui/language-selector'
import Pager from 'components/ui/pager'
import SearchBar from 'components/ui/search-bar'
import SecondaryAppBar from 'components/layout/secondary-app-bar'
import Video from 'tupos/models/video'
import VideoHead from 'components/views/video/video-head'
import VideoRow from 'components/views/video/video-row'
import { useYouVersionAuth, EmptyState } from '@youversion/react'
import PropTypes from 'prop-types'

import getParamsFromRouterHook from 'utils/get-params-from-router-hook'

import { Box, Grid, makeStyles } from '@material-ui/core'
import { statusTypes } from 'utils/constants'
import { useAlert } from 'components/layout/alert-bar'
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import { searchVideo, fetchLanguages } from 'api/search-videos'

const useStyles = makeStyles((theme) => ({
  content: {
    maxWidth: 960,
    margin: 'auto',
    padding: theme.spacing(2),
  },
  chip: {
    margin: theme.spacing(1),
  },
}))

/**
 * VideoList displays all videos available in a specified language.
 *
 * @alias module:VideoList
 *
 * @returns {React.ReactElement} - The VideoList component.
 *
 * @example
 * <VideoList />
 */
export default function VideoList() {
  const classes = useStyles()
  const { throwAlert } = useAlert()

  const [currentLanguage, setCurrentLanguage] = React.useState('')
  const [loading, setLoading] = React.useState({ status: statusTypes.PENDING })
  const [languages, setLanguages] = React.useState([])
  const [videosResponse, setVideosResponse] = React.useState({
    nextPage: false,
    pageSize: '',
    rows: [],
  })
  const [searchQuery, setSearchQuery] = React.useState('')

  const history = useHistory()
  const location = useLocation()
  const match = useRouteMatch()
  const { getToken } = useYouVersionAuth()

  const urlLanguage = getParamsFromRouterHook({
    defaultValue: 'en',
    location,
    paramName: 'lang',
  })
  const urlPage = getParamsFromRouterHook({
    defaultValue: '1',
    location,
    paramName: 'page',
  })
  const urlQuery = getParamsFromRouterHook({
    defaultValue: '',
    location,
    paramName: 'query',
  })

  const fetchVideos = React.useCallback(async () => {
    try {
      let searchResponse = null
      // Check for search query in url.
      if (urlQuery !== '') {
        try {
          const token = await getToken()
          searchResponse = await searchVideo({
            query: urlQuery,
            urlLanguage,
            token,
            urlPage,
          })
        } catch (error) {
          return new Error(
            JSON.stringify({
              type: statusTypes.ERROR,
              id: `api_4_client_get_videos_error`,
              message: `Error searching videos `,
            }),
          )
        }
      } else {
        searchResponse = await Video.getCollection({
          collection: match.params.id,
          page: urlPage,
          language: urlLanguage,
          orientation: '*',
          types: '*',
        })
      }
      return searchResponse
    } catch (error) {
      return new Error(
        JSON.stringify({
          id: 'video_fetch_error',
          message: `Error fetching videos for collection with query: ${urlQuery}, language: ${urlLanguage}, and page: ${urlPage}.`,
          type: 'error',
        }),
      )
    }
  }, [getToken, match.params.id, urlLanguage, urlPage, urlQuery])

  React.useEffect(() => {
    setSearchQuery(urlQuery)
  }, [urlQuery])

  React.useEffect(() => {
    async function loadData() {
      try {
        const [videosResponses, languagesResponse] = await Promise.all([
          fetchVideos(),
          fetchLanguages(),
        ])
        setVideosResponse(videosResponses)
        setLanguages(languagesResponse)
        setCurrentLanguage(urlLanguage)
        setLoading({ status: statusTypes.RESOLVED })
      } catch (error) {
        setLoading({ status: statusTypes.REJECTED })
        throwAlert(JSON.parse(error.message))
      }
    }
    loadData()
  }, [fetchVideos, throwAlert, urlLanguage])

  function handleClearSearch() {
    setSearchQuery('')
    history.push(`/videos?lang=${currentLanguage}`)
  }

  function handleLanguageChange(selectedLanguage) {
    history.push(`/videos?lang=${selectedLanguage}`)
  }

  function handleSearchInputChange(event) {
    setSearchQuery(event.target.value)
  }

  function handleSearchRedirect(event) {
    event.preventDefault()
    history.push(`/videos?lang=${currentLanguage}&query=${searchQuery}`)
  }

  const hasRequestBeenResolved = Boolean(
    loading.status === statusTypes.RESOLVED,
  )
  const isVideoListEmpty = Boolean(
    hasRequestBeenResolved && !videosResponse?.rows?.length,
  )

  return (
    <div>
      <SecondaryAppBar title="Videos" />
      <Grid className={classes.content} container={true}>
        <Grid container={true} item={true} justify="center" xs={12}>
          <Box alignItems="center" display="flex" mb={2}>
            <LanguageSelector
              changed={handleLanguageChange}
              current={currentLanguage}
              langs={languages}
              variant="standard"
            />
            <Box ml={2}>
              <SearchBar
                fieldLabel="Search..."
                onClear={handleClearSearch}
                onInputChange={handleSearchInputChange}
                onSearchEnter={handleSearchRedirect}
                query={searchQuery}
              />
            </Box>
          </Box>
        </Grid>
        {isVideoListEmpty ? (
          <EmptyState
            headerText="No videos found."
            subtitleText="Adjust your search and try again."
          />
        ) : (
          <Dataview
            videosResponse={videosResponse}
            loading={loading}
            location={location}
          />
        )}
      </Grid>
    </div>
  )
}

const Dataview = ({ videosResponse, loading, location }) => {
  const nextPage = React.useMemo(() => videosResponse.nextPage ?? false, [
    videosResponse,
  ])
  const pageSize = React.useMemo(() => videosResponse.pageSize ?? '', [
    videosResponse,
  ])
  const videos = React.useMemo(() => videosResponse.rows ?? [], [
    videosResponse,
  ])

  return (
    <DataTable
      dataSource={videos}
      HeadComponent={VideoHead}
      RowComponent={VideoRow}
      pager={
        <Pager
          dataLength={videos.length}
          hasNextPage={nextPage}
          loading={loading}
          onPrevious={true}
          onNext={true}
          pageNumber={getParamsFromRouterHook({
            defaultValue: '1',
            location,
            paramName: 'page',
          })}
          pageSize={pageSize}
        />
      }
    />
  )
}

Dataview.propTypes = {
  loading: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  videosResponse: PropTypes.object.isRequired,
}
