import React from 'react'
import PropTypes from 'prop-types'
import EditIcon from '@material-ui/icons/Edit'
import SaveIcon from '@material-ui/icons/Save'
import ClearIcon from '@material-ui/icons/Clear'
import { getReferencesTitle } from '@youversion/utils'
import BibleReferenceSelector from 'components/ui/bible-reference-selector'
import { bibleESVBooksData, bibleESVDataJson } from 'utils/bible-book-data'
import { compressRelatedBibleReferences } from 'utils/compress-bible-references'

import {
  IconButton,
  Typography,
  Box,
  Chip,
  Fade,
  makeStyles,
  CircularProgress,
  LinearProgress,
} from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  hide: {
    visibility: 'hidden',
  },
  btnEditRef: {
    opacity: '0%',
    transition: 'opacity 0.1s ease-in',
  },
  refActions: {
    minHeight: 48,
  },
  references: {
    width: '100%',
    '&:hover $btnEditRef': {
      opacity: '100%',
    },
  },
}))

const getReferenceWithTitle = (reference) => ({
  title: getReferencesTitle({
    bookList: bibleESVBooksData,
    usfmList: reference,
  }).title,
  usfm: reference,
})

export default function VideoReferences({
  references,
  handleReferencesUpdate,
}) {
  const [mutableReferences, setMutableReferences] = React.useState(() =>
    references?.map((reference) => getReferenceWithTitle(reference)),
  )
  const [compressedReferences, setCompressedReferences] = React.useState([])
  const [editModeEnabled, setEditModeEnabled] = React.useState(false)
  const [hasUnsavedChanges, setHasUnsavedChanges] = React.useState(false)
  const [isSaving, setIsSaving] = React.useState(false)

  const classes = useStyles()

  function handleAddReference(reference) {
    const newReferences = []

    // check if is verse or chapter
    const firstOfUsfm = reference.split('+')[0]
    const isChapterRegex = /^[1-3]?[A-z]{2,3}.\d+$/
    if (reference.includes('+') && isChapterRegex.test(firstOfUsfm)) {
      // chapters do split on + delimiters
      newReferences.push(
        ...reference
          .split('+')
          .map((chapterReference) => getReferenceWithTitle(chapterReference)),
      )
    } else {
      // verses don't split on + delimiters
      newReferences.push(getReferenceWithTitle(reference))
    }

    // updates references and removes duplicates
    setMutableReferences((prevReferences) => {
      // references without duplicates (source: https://stackoverflow.com/a/36744732)
      const updatedReferences = [...prevReferences, ...newReferences].filter(
        (referenceObj, index, self) =>
          index ===
          self.findIndex(
            (t) =>
              t.title === referenceObj.title && t.usfm === referenceObj.usfm,
          ),
      )

      return updatedReferences
    })
    setHasUnsavedChanges(true)
  }

  function handleDeleteReference(index) {
    setMutableReferences((prevReferences) => {
      const updatedReferences = [...prevReferences]
      updatedReferences.splice(index, 1)
      return updatedReferences
    })
    setHasUnsavedChanges(true)
  }

  function clearUpdates() {
    setMutableReferences(
      references?.map((reference) => getReferenceWithTitle(reference)),
    )
    setHasUnsavedChanges(false)
    setEditModeEnabled(false)
  }

  async function handleSave() {
    setIsSaving(true)
    await handleReferencesUpdate(mutableReferences.map((refObj) => refObj.usfm))
    setIsSaving(false)
    setHasUnsavedChanges(false)
    setEditModeEnabled(false)
  }

  React.useEffect(() => {
    if (mutableReferences.length > 0 && !editModeEnabled) {
      const compressedBibleReferences = compressRelatedBibleReferences(
        mutableReferences,
        bibleESVDataJson,
      )
      setCompressedReferences(compressedBibleReferences)
    } else if (!editModeEnabled) {
      setCompressedReferences([])
    }
  }, [mutableReferences, editModeEnabled])

  if (!references) return <LinearProgress />

  return (
    <Box className={classes.references}>
      {!editModeEnabled ? (
        <Box display="flex" alignItems="center" className={classes.refActions}>
          <Typography variant="h3" color="textPrimary">
            Bible References
          </Typography>
          <IconButton
            className={classes.btnEditRef}
            aria-label="edit references"
            onClick={() => setEditModeEnabled(true)}
          >
            <EditIcon fontSize="small" />
          </IconButton>
        </Box>
      ) : (
        <Fade in={true}>
          <Box display="flex" justifyContent="space-between">
            <Box mb={1} display="flex" alignItems="center">
              {editModeEnabled ? (
                // helps me enable auto-focus
                <BibleReferenceSelector
                  disabled={isSaving}
                  onEnter={handleAddReference}
                />
              ) : null}
            </Box>
            <Box>
              {hasUnsavedChanges ? (
                <IconButton
                  aria-label="add a reference"
                  color="primary"
                  onClick={handleSave}
                  disabled={isSaving}
                >
                  {!isSaving ? (
                    <SaveIcon fontSize="small" />
                  ) : (
                    <CircularProgress size={20} />
                  )}
                </IconButton>
              ) : null}
              <IconButton
                disabled={isSaving}
                aria-label="cancel updating references"
                onClick={clearUpdates}
              >
                <ClearIcon fontSize="small" />
              </IconButton>
            </Box>
          </Box>
        </Fade>
      )}
      <Box display="flex" flexWrap="wrap">
        {editModeEnabled
          ? mutableReferences.map((ref, index) =>
              ref.title ? (
                <Fade in={true} key={ref.title}>
                  <Box mr={1} mb={1}>
                    <Chip
                      label={ref.title}
                      onDelete={
                        editModeEnabled
                          ? () => handleDeleteReference(index)
                          : null
                      }
                    />
                  </Box>
                </Fade>
              ) : null,
            )
          : compressedReferences.map((ref) => (
              <Fade in={true} key={ref}>
                <Box mr={1} mb={1}>
                  <Chip label={ref} />
                </Box>
              </Fade>
            ))}
        {mutableReferences.length === 0 ? (
          <Typography component="p" variant="subtitle1" color="textSecondary">
            Consider adding a Bible reference.
          </Typography>
        ) : null}
      </Box>
    </Box>
  )
}

VideoReferences.propTypes = {
  references: PropTypes.arrayOf(PropTypes.string),
  handleReferencesUpdate: PropTypes.func.isRequired,
}

VideoReferences.defaultProps = {
  references: [],
}
