import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  Typography,
} from '@material-ui/core/'
import AddIcon from '@material-ui/icons/Add'
import PropTypes from 'prop-types'
import { sortableContainer, sortableElement } from 'react-sortable-hoc'
import { getFeaturedCollectionById } from 'utils'
import { searchVideo } from 'api/search-videos'
import { useYouVersionAuth } from '@youversion/react'
import Video from '../../../../tupos/models/video'
import VideoSelectorItem from './video-selector-item'
import SearchBar from '../../../ui/search-bar'

const SortableItem = sortableElement(({ element }) => (
  <ListItem>{element}</ListItem>
))
const SortableContainer = sortableContainer(({ children }) => {
  return <List>{children}</List>
})

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(1),
  },
  iconAdd: {
    marginRight: theme.spacing(1),
  },
  dialogVideoSelect: {
    [theme.breakpoints.up('sm')]: {
      minWidth: 400,
    },
  },
}))

const VideoSelector = (props) => {
  const {
    onCheck,
    currentVideos,
    onSortEnd,
    language,
    currentCollectionId,
  } = props
  const classes = useStyles()
  const { getToken } = useYouVersionAuth()

  const [open, setOpen] = React.useState(false)
  const [videosList, setVideosList] = React.useState([])
  const [pagination, setPagination] = React.useState({ currentPage: 1 })

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleFilteredVideosList = React.useCallback(
    (oldVideos, newVideos) => {
      // Merge old and new arrays
      const mergeVideos = [...oldVideos, ...newVideos]

      // Remove duplicates
      const videosDuplicatesRemoved = mergeVideos.filter((obj, pos, arr) => {
        return arr.map((mapObj) => mapObj.id).indexOf(obj.id) === pos
      })

      // Filter out collection videos state
      let filteredVideos = videosDuplicatesRemoved.filter(
        (video) => !currentVideos.includes(video.id),
      )

      // Filter out videos of incorrect orientation if is featured collection
      const featuredCollection = getFeaturedCollectionById(currentCollectionId)
      if (featuredCollection) {
        filteredVideos = filteredVideos.filter((video) => {
          return video.orientation === featuredCollection.orientation
        })
      }

      // Return sorted list
      return filteredVideos
    },
    [currentCollectionId, currentVideos],
  )

  async function videosRequest(page) {
    const featuredCollection = getFeaturedCollectionById(currentCollectionId)
    const videosResult = await Video.getAllVideos({
      pageSize: 25,
      page: page ?? pagination.currentPage,
      language,
      orientation: featuredCollection
        ? featuredCollection.orientation
        : undefined,
      status: 'published',
      types: '*',
    })

    setPagination((prevState) => {
      return {
        ...prevState,
        nextPage: videosResult.nextPage,
        pageSize: videosResult.pageSize,
      }
    })

    setVideosList((prevState) => {
      return [...handleFilteredVideosList(prevState, videosResult.rows)]
    })
  }

  const handlePagination = () => {
    videosRequest(pagination.currentPage + 1)
    setPagination((prevState) => {
      return {
        ...prevState,
        currentPage: prevState.currentPage + 1,
      }
    })
  }

  /**
   * Set up SearchBar required functions.
   */
  const [searchQuery, setSearchQuery] = React.useState('')

  const resetVideoCall = (clearSearchQuery) => {
    // 1. Reset search query
    if (clearSearchQuery) {
      setSearchQuery('')
    }

    // 2. Reset videos list
    setVideosList([])

    // 3. Reset pagination
    setPagination((prevState) => {
      return {
        ...prevState,
        currentPage: 1,
      }
    })
  }

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.currentTarget.value)
  }

  const descriptionElementRef = React.useRef(null)

  let unselectedVideosCheckboxes = <p>loading unselected videos...</p>
  let videoTable = null

  if (!language) {
    unselectedVideosCheckboxes = (
      <p>No language set. Please set a language and try again.</p>
    )
  }

  if (currentVideos.length > 0) {
    videoTable = (
      <SortableContainer onSortEnd={onSortEnd}>
        {currentVideos.map((videoId, index) => {
          return (
            <SortableItem
              element={
                <VideoSelectorItem
                  isChecked={true}
                  isDraggable={true}
                  language={language}
                  onCheck={onCheck}
                  videoId={videoId}
                />
              }
              index={index}
              key={`active-item-${videoId}`}
            />
          )
        })}
      </SortableContainer>
    )
  }

  if (Object.keys(videosList).length > 0) {
    // Update the component variable
    unselectedVideosCheckboxes = videosList.map((video) => {
      return (
        <ListItem key={`modal-item-${video.id}`}>
          <VideoSelectorItem
            isChecked={video.checked}
            language={language}
            onCheck={onCheck}
            video={video}
            videoId={video.id}
          />
        </ListItem>
      )
    })
  }

  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef
      if (descriptionElement !== null) {
        descriptionElement.focus()
      }
    }
  }, [open])

  async function searchVideoRequest() {
    const token = await getToken()
    const videosResult = await searchVideo({
      query: searchQuery,
      urlLanguage: language,
      token,
      urlPage: pagination.currentPage,
    })
    setPagination((prevState) => {
      return {
        ...prevState,
        nextPage: videosResult.nextPage,
        pageSize: videosResult.pageSize,
      }
    })

    setVideosList((prevState) => {
      return [...handleFilteredVideosList(prevState, videosResult.rows)]
    })
  }

  function handleSearchClear() {
    resetVideoCall(true)
    videosRequest()
  }

  function handleSearchSubmit(event) {
    event.preventDefault()
    resetVideoCall()
    searchVideoRequest()
  }

  React.useEffect(() => {
    if (language) {
      videosRequest()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language])

  return (
    <>
      <Box alignContent="center" display="flex" width="100%">
        <Button
          className={classes.button}
          onClick={handleClickOpen}
          variant="outlined"
        >
          <AddIcon className={classes.iconAdd} fontSize="small" />
          Add videos
        </Button>
      </Box>
      {videoTable || (
        <Box mt={2}>
          <Typography align="center" color="textSecondary" variant="subtitle1">
            There are no videos for this language. Add some videos, or add this
            language this collections&apos;s existing videos.
          </Typography>
        </Box>
      )}
      <Dialog
        aria-describedby="scroll-dialog-description"
        aria-labelledby="scroll-dialog-title"
        onClose={handleClose}
        open={open}
        scroll="paper"
      >
        <DialogTitle disableTypography={true} id="scroll-dialog-title">
          <Typography variant="h3">Select videos to add</Typography>
          <SearchBar
            fieldLabel="Search for videos"
            onClear={handleSearchClear}
            onInputChange={handleSearchInputChange}
            onSearchEnter={handleSearchSubmit}
            query={searchQuery}
          />
        </DialogTitle>
        <DialogContent className={classes.dialogVideoSelect} dividers={true}>
          {unselectedVideosCheckboxes}
          {pagination.nextPage ? (
            <Box
              display="flex"
              flexGrow={1}
              justifyContent="center"
              mb={2}
              mt={1}
            >
              <Button onClick={handlePagination}>Load more</Button>
            </Box>
          ) : null}
        </DialogContent>
        <DialogActions justify="space-between">
          <Button color="primary" onClick={handleClose}>
            Done
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

VideoSelector.propTypes = {
  onCheck: PropTypes.func.isRequired,
  onSortEnd: PropTypes.func.isRequired,
  currentVideos: PropTypes.arrayOf(PropTypes.number).isRequired,
  language: PropTypes.string.isRequired,
  currentCollectionId: PropTypes.number,
}

VideoSelector.defaultProps = {
  currentCollectionId: undefined,
}

export default VideoSelector
