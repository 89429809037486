import React from 'react'
import Button from '@material-ui/core/Button'
import {
  Grid,
  makeStyles,
  Card,
  CardContent,
  TextField,
  Tooltip,
  CircularProgress,
  CardActions,
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { Redirect, useHistory, useParams, useLocation } from 'react-router-dom'
import LanguageSelector from 'components/ui/language-selector'
import { v4 as uuidv4 } from 'uuid'
import { replaceUrlParam } from 'utils'
import useAsync from '../../hooks/useAsync'
import Publisher from '../../tupos/models/publisher'
import SecondaryAppBar from '../../components/layout/secondary-app-bar'
import Video from '../../tupos/models/video'
import Languages from '../../tupos/models/languages'
import {
  AlertBar,
  AlertProvider,
  useAlert,
} from '../../components/layout/alert-bar'
import VideoLanguageFields from '../../components/views/video/video-language-fields'

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: theme.spacing(2),
    padding: theme.spacing(1),
  },
  content: {
    maxWidth: 960,
    margin: 'auto',
    padding: theme.spacing(2),
  },
  formControl: {
    minWidth: 120,
    width: '100%',
  },
  selectInputSection: {
    marginBottom: theme.spacing(2),
  },
  inputSelect: {
    flexGrow: 1,
    flexBasis: '50%',
  },
  buttonProgress: {
    position: 'absolute',
  },
}))

function alphabetizePublishersList(publishers) {
  return publishers.sort((a, b) => {
    const pubNameA = a.name.toUpperCase()
    const pubNameB = b.name.toUpperCase()
    if (pubNameA > pubNameB) {
      return 1
    }
    if (pubNameA < pubNameB) {
      return -1
    }
    return 0
  })
}

function getPublisherFromId(id, publishers) {
  return publishers?.find((publisher) => publisher.id === id) || null
}

function getPublisherName(publisher) {
  return publisher?.name || ''
}

function isPublisherIdSupported(id, publishers) {
  if (id && publishers.length) {
    return publishers.map((publisher) => publisher.id).includes(id)
  }
  return false
}

function VideoAddPage() {
  const history = useHistory()
  const location = useLocation()

  const { uuid } = useParams()

  const [video, setVideo] = React.useState(new Video({}).toObject())
  const [publishers, setPublishers] = React.useState([])
  const [languages, setLanguages] = React.useState([])
  const [errorMessages, setErrorMessages] = React.useState([])
  const [hasBeenTouched, setHasBeenTouched] = React.useState(false)

  const classes = useStyles()
  const throwAlert = useAlert()

  const handleSave = React.useCallback(async () => {
    try {
      // convert video object back to class
      const videoClass = Video.toClass({
        json: video,
        language: video.language,
      })

      // create the video
      const videoCreateResponse = await videoClass.createVideo(uuid)

      // take user to the newly created video with selected lang
      history.push(
        videoCreateResponse && videoCreateResponse.id
          ? `/videos/${videoCreateResponse.id}?lang=${videoClass.language}`
          : `/videos?lang=${videoClass.language}`,
      )
    } catch (error) {
      throwAlert({
        type: 'error',
        id: 'video_save_error',
        message: `Failed to save the (${video.language}) video.`,
      })
    }
  }, [video, history, uuid, throwAlert])

  // Get list of languages on page load
  React.useEffect(() => {
    async function getAvailableLanguages() {
      const languagesResponse = await Languages.get('publisher')
      setLanguages(languagesResponse)
    }

    getAvailableLanguages()
  }, [])

  // When new languge is selected, get available list of publishers
  React.useEffect(() => {
    async function getPublishersFromLanguage(language) {
      try {
        const { rows: listOfPublishers } = await Publisher.getCollection(
          'name,id',
          '*',
          undefined,
          language,
        )
        setPublishers(alphabetizePublishersList(listOfPublishers))
      } catch {
        // If the above errors out, most likely it's because there are no
        // publishers for the selected language.
        setPublishers([])
      }
    }

    if (video.language) {
      getPublishersFromLanguage(video.language)
    }
  }, [throwAlert, video.language])

  // Create video
  const { execute: saveVideo, pending: videoIsSaving } = useAsync(
    handleSave,
    false,
  )

  async function handleLanguageUpdate(language) {
    history.replace(replaceUrlParam(history.location, 'lang', language))
  }

  const handleInputChange = React.useCallback(async (event) => {
    setHasBeenTouched(true)

    const {
      target: { name, value },
    } = event

    setVideo((prevVideo) => ({
      ...prevVideo,
      [name]: value,
    }))
  }, [])

  const handleValidation = React.useCallback(
    (hasErrors, itemId, errorMessage) => {
      // If we pass in 'true'
      if (hasErrors) {
        setErrorMessages(
          (prevErrors) =>
            // check if error message already exists
            prevErrors.some((error) => error.itemId === itemId)
              ? prevErrors // error exists, don't add it to the list
              : [...prevErrors, { itemId, message: errorMessage }], // error does not exist, add it to the list
        )
      } else {
        // If false, filter out errors that match this item's id
        setErrorMessages((prevState) => {
          return prevState.filter((error) => error.itemId !== itemId)
        })
      }
    },
    [],
  )

  // Set publisher or language if URL param is set
  React.useEffect(() => {
    if (location) {
      const publisherIdParam = new URLSearchParams(location.search).get(
        'publisher',
      )
      const langUrlParam = new URLSearchParams(location.search).get('lang')
      setVideo((prevVideo) => ({
        ...prevVideo,
        // eslint-disable-next-line no-restricted-globals
        publisher_id: !isNaN(publisherIdParam)
          ? Number(publisherIdParam)
          : prevVideo.publisher_id,
        language:
          languages.length > 0 && languages.includes(langUrlParam)
            ? langUrlParam
            : prevVideo.language,
      }))
    }
  }, [languages, location])

  if (!uuid)
    return <Redirect to={`/videos/add/${uuidv4()}${location?.search}`} />

  const publisherSelectInput = (
    <Grid className={classes.inputSelect} item={true}>
      <Tooltip
        placement="top"
        title="Select a language. The list of available publishers for that language will then populate."
        disableHoverListener={Boolean(video.language)}
        disableTouchListener={Boolean(video.language)}
        disableFocusListener={Boolean(video.language)}
      >
        <div>
          <Autocomplete
            disabled={Boolean(!video.language)}
            getOptionLabel={getPublisherName}
            onChange={(event, publisher) => {
              setHasBeenTouched(true)
              setVideo((prevVideo) => ({
                ...prevVideo,
                publisher_id: !publisher
                  ? null
                  : publisher.id || prevVideo.publisher_id,
              }))
            }}
            options={publishers}
            renderInput={(params) => (
              <TextField
                className={classes.formControl}
                variant="outlined"
                label="Publisher"
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...params}
              />
            )}
            value={
              isPublisherIdSupported(video.publisher_id, publishers)
                ? getPublisherFromId(video.publisher_id, publishers)
                : null
            }
          />
        </div>
      </Tooltip>
    </Grid>
  )

  const languageSelectInput = (
    <Grid item={true} className={classes.inputSelect}>
      <LanguageSelector
        langs={languages}
        changed={handleLanguageUpdate}
        current={video.language || ''}
        fullWidth={true}
      />
    </Grid>
  )

  return (
    <div>
      <SecondaryAppBar title="Add New Video" />
      <Grid container={true} className={classes.content}>
        <Card className={classes.card} variant="outlined">
          <CardContent>
            <Grid
              item={true}
              container={true}
              alignItems="flex-start"
              justify="space-between"
              className={classes.selectInputSection}
            >
              <Grid item={true} container={true} spacing={4}>
                {languageSelectInput}
                {publisherSelectInput}
              </Grid>
            </Grid>
            <Grid item={true} container={true}>
              <VideoLanguageFields
                variant="create"
                video={video}
                onInputChange={handleInputChange}
                onValidate={handleValidation}
                hasThumbnail={false}
                showReferences={true}
              />
            </Grid>
          </CardContent>
          <CardActions>
            <Grid item={true} container={true} spacing={2}>
              <Grid item={true}>
                <Tooltip
                  placement="right-end"
                  disableHoverListener={Boolean(
                    errorMessages.length === 0 && hasBeenTouched,
                  )}
                  title={errorMessages.map((error) => {
                    return <p key={error.itemId}>{error.message}</p>
                  })}
                >
                  <span>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={saveVideo}
                      disabled={Boolean(
                        !hasBeenTouched ||
                          errorMessages.length > 0 ||
                          videoIsSaving,
                      )}
                    >
                      {videoIsSaving ? (
                        <>
                          Saving
                          <CircularProgress
                            size={24}
                            className={classes.buttonProgress}
                          />
                        </>
                      ) : (
                        'Create'
                      )}
                    </Button>
                  </span>
                </Tooltip>
              </Grid>
              <Grid item={true}>
                <Button onClick={history.goBack}>Cancel</Button>
              </Grid>
            </Grid>
          </CardActions>
        </Card>
      </Grid>
    </div>
  )
}

function VideoAdd(props) {
  return (
    <AlertProvider>
      <AlertBar />
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <VideoAddPage {...props} />
    </AlertProvider>
  )
}

export default VideoAdd
