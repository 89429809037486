import React from 'react'
import { TableRow, TableCell } from '@material-ui/core'

const VideoHead = () => (
  <TableRow component="div">
    <TableCell component="div" align="left" />
    <TableCell component="div" align="left">
      Title
    </TableCell>
    <TableCell component="div" align="left">
      ID
    </TableCell>
    <TableCell component="div" align="center">
      Status
    </TableCell>
    <TableCell component="div" />
  </TableRow>
)

export default VideoHead
