/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import PropTypes from 'prop-types'
import { Button, CircularProgress, makeStyles, Box } from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check'
import useAsync from '../../../hooks/useAsync'

const useStyles = makeStyles({
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  iconCheck: {
    verticalAlign: 'text-bottom',
  },
})

/**
 * # Submit Button
 *
 * A simple button that handles and displays the current state of the async function (`onClick`)
 * passed to it, as well as executing callback functions for error and success statuses.
 *
 * @param {Object} props
 * @param {JSX.Element} props.children String or text node child. (e.g., `Submit` or`<p>Submit</p>`)
 * @param {Function} props.onClick Callback function to be executed on click.
 * @param {Function} props.onSuccess Callback function to be executed when the onClick function returns successful.
 * @param {Function} props.onError Callback function to be executed when the onClick function returns an error.
 * @param {*} props.props Any additional props spread onto the button.
 *
 * @returns {JSX.Element}
 */
export default function SubmitButton({
  children,
  onClick,
  onError,
  onSuccess,
  ...props
}) {
  const { idle, execute, pending, value, error } = useAsync(
    onClick,
    false, // don't execute immediately
  )

  const classes = useStyles()

  // return error to callback function
  React.useEffect(() => {
    if (error && typeof onError === 'function') {
      onError(error)
    }
  }, [error, onError])

  // return success to callback function
  React.useEffect(() => {
    if (value && typeof onSuccess === 'function') {
      onSuccess()
    }
  }, [value, onSuccess])

  // allows only the button UI to be clicked, not the parent
  const handleClick = (event) => {
    event.stopPropagation()
    execute()
  }

  if (idle || error) {
    return (
      <Button
        variant="contained"
        color="primary"
        {...props}
        onClick={handleClick}
      >
        {children}
      </Button>
    )
  }

  if (pending) {
    return (
      <Button variant="contained" color="primary" {...props} disabled={true}>
        {children}
        <CircularProgress size={24} className={classes.buttonProgress} />
      </Button>
    )
  }

  if (value) {
    return (
      <Button variant="contained" color="primary" {...props} disabled={true}>
        <Box mr={1}>
          <CheckIcon className={classes.iconCheck} fontSize="small" />
        </Box>
        {children}
      </Button>
    )
  }

  return null
}

SubmitButton.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  onError: PropTypes.func,
  onSuccess: PropTypes.func,
}

SubmitButton.defaultProps = {
  onError: null,
  onSuccess: null,
}
