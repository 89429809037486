import React from 'react'
import { TableRow, TableCell } from '@material-ui/core'

const PublisherHead = () => (
  <TableRow component="div">
    <TableCell component="div">Name</TableCell>
    <TableCell align="right" component="div">
      Tracking ID
    </TableCell>
  </TableRow>
)

export default PublisherHead
