import React from 'react'

function useTimeout(callback, delay) {
  const savedCallback = React.useRef()
  const expirationTime = React.useRef(0)

  React.useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  React.useEffect(() => {
    function tick() {
      if (savedCallback.current) {
        savedCallback.current()
      }
    }

    const id = setTimeout(tick, delay)
    expirationTime.current = new Date(Date.now() + delay).getTime()

    return () => clearTimeout(id)
  }, [delay])

  const getRemainingTime = () => expirationTime.current - Date.now()

  return {
    getRemainingTime,
  }
}

export default useTimeout
