/* eslint-disable no-underscore-dangle */
import { TuposModel } from '@youversion/tupos-base'
import setNumber from '@youversion/tupos-base/dist/setters/number'
import setString from '@youversion/tupos-base/dist/setters/string'
import api4 from '@youversion/tupos-base/dist/fetchers/api4'
// hard-coded locales list came from Kasey Schlaudt on 2020-09-16
import appLanguages from './app-languages.json'

/** *
 * Language model.
 *
 * @augments TuposModel
 */
class Languages extends TuposModel {
  constructor(json) {
    super(json)
    if (!json || typeof json !== 'object') return
    this.end = json.end
    this.language = json.language
    this.description = json.description
    this.title = json.title
    this.start = json.start
    this.id = json.id
  }

  /** Convert Languages to simple object */
  toObject() {
    return {
      end: this.end,
      language: this.language,
      description: this.description,
      title: this.title,
      start: this.start,
      id: this.id,
    }
  }

  /**
   * Fetch Language(s) for a particular video, publisher, or collection.
   */
  static async get(type, id) {
    // this param combo indicates a need for all app languages
    if ((type === 'collection' || type === 'publisher') && !id) {
      return Object.keys(appLanguages)
    }

    const json = await TuposModel.get(
      api4({
        endpoint: 'movies',
        method: 'languages',
        version: '4.0',
        auth: true,
        parseJson: true,
        fetchArgs: { cache: 'no-cache' },
        params: {
          type,
          id,
        },
      }),
    )

    if (json && json.message) throw new Error(`Languages: ${json.message}`)
    if (!Array.isArray(json.languages)) throw new Error()

    return json.languages
  }

  /**
   * Fetch a Collection of Collections Resources.
   */
  static async getCollection(fields, page, pageSize) {
    const json = await TuposModel.get(
      api4({
        endpoint: 'movies',
        method: 'publishers',
        version: '4.0',
        auth: true,
        parseJson: true,
        params: {
          fields,
          page,
          page_size: pageSize,
        },
      }),
    )

    if (json && json.message) throw new Error(json.message)
    if (!Array.isArray(json.data)) throw new Error()

    return json.data.map((item) => new Languages(item))
  }

  /** @type {string} */
  get end() {
    return this._end
  }

  set end(end) {
    this._end = setString(end, 'end')
  }

  /** @type {string} */
  get language() {
    return this._language
  }

  set language(language) {
    this._language = setString(language, 'language')
  }

  /** @type {string} */
  get description() {
    return this._description
  }

  set description(description) {
    this._description = setString(description, 'description')
  }

  /** @type {string} */
  get title() {
    return this._title
  }

  set title(title) {
    this._title = setString(title, 'title')
  }

  /** @type {string} */
  get start() {
    return this._start
  }

  set start(start) {
    this._start = setString(start, 'start')
  }

  /** @type {number} */
  get id() {
    return this._id
  }

  set id(id) {
    this._id = setNumber(id, 'id')
  }
}

export default Languages
