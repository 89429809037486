import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
  makeStyles,
} from '@material-ui/core'

const useStyles = makeStyles(() => ({
  refActions: {
    minHeight: 48,
  },
}))

export function VideoCollectionList({ collections, language }) {
  const classes = useStyles()
  return (
    <Box>
      <Box display="flex" alignItems="center" className={classes.refActions}>
        <Typography variant="h3" color="textPrimary">
          Collections This Video Belongs To
        </Typography>
      </Box>
      {collections && collections.length > 0 ? (
        <List>
          {collections.map((collection, index) => {
            return (
              <React.Fragment key={`collection_${collection.id}`}>
                <ListItem
                  button={true}
                  component={Link}
                  to={`/collections/${collection.id}?lang=${language}`}
                >
                  <ListItemText primary={collection.title} />
                </ListItem>
                {index !== collections.length - 1 ? <Divider /> : null}
              </React.Fragment>
            )
          })}
        </List>
      ) : (
        <Typography variant="subtitle1" color="textSecondary">
          There are no collections to display.
        </Typography>
      )}
    </Box>
  )
}

VideoCollectionList.propTypes = {
  collections: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      title: PropTypes.string.isRequired,
    }),
  ).isRequired,
  language: PropTypes.string.isRequired,
}
