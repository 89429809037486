/* eslint-disable no-underscore-dangle */
import { TuposModel } from '@youversion/tupos-base'
import setNumber from '@youversion/tupos-base/dist/setters/number'
import setString from '@youversion/tupos-base/dist/setters/string'
import setArray from '@youversion/tupos-base/dist/setters/array'
import api4 from '@youversion/tupos-base/dist/fetchers/api4'
import { preventLanguageAction } from 'utils'
import setUrl from '../../setters/url'

/** *
 * Video model.
 *
 * @augments TuposModel
 */
class Video extends TuposModel {
  constructor(json, language) {
    super(json)
    this.updatePropsFromJson(json, language)
  }

  updatePropsFromJson(json, language) {
    if (!json || typeof json !== 'object') return
    this.trackingId = json.tracking_id
    this.publisherId = json.publisher_id
    this.runtime = json.runtime
    this.references = json.references
    this.description = json.description
    this.title = json.title
    this.status = json.status
    this.id = json.id
    this.language = json.language || language
    this.sourceUrl = json.sourceUrl || json.source_url || ''
    this.thumbnailSourceUrl =
      json.thumbnailSourceUrl || json.thumbnail_source_url || ''
    this.orientation = json.orientation
    this.type = json.type
    this.createdDt = json.created_dt
  }

  /** Convert Video to simple object */
  toObject() {
    return {
      tracking_id: this.trackingId,
      publisher_id: this.publisherId,
      runtime: this.runtime,
      references: this.references,
      description: this.description,
      title: this.title,
      status: this.status,
      id: this.id,
      orientation: this.orientation,
      type: this.type,
      source_url: this.sourceUrl.url, // why was this not included in the first place??
      thumbnail_source_url: this.thumbnailSourceUrl.url, // why was this not included in the first place??
    }
  }

  /**
   * Convert object to Video class instance.
   */
  static toClass({ json, language }) {
    return new Video(json, language)
  }

  /**
   * Fetch a Video Resource.
   */
  static async get(id, language = 'en') {
    const json = await TuposModel.get(
      api4({
        endpoint: 'movies',
        method: 'videos/:id',
        version: '4.0',
        auth: true,
        parseJson: true,
        urlParams: {
          id,
        },
        additionalHeaders: {
          'Accept-Language': language,
        },
      }),
    )

    if (typeof json !== 'object')
      throw new Error(`Failed to retrieve video at id ${id}`)

    if (json && json.message) throw new Error(json.message)

    return new Video(json, language)
  }

  /** Fetch a Refreshed Video Resource */
  async refresh() {
    const json = await TuposModel.get(
      api4({
        endpoint: 'movies',
        method: 'videos/:id',
        version: '4.0',
        auth: true,
        parseJson: true,
        urlParams: {
          id: this.id,
        },
        additionalHeaders: {
          'Accept-Language': this.language,
        },
      }),
    )

    if (typeof json !== 'object') throw new Error()

    this.updatePropsFromJson(json, this.language)
  }

  /**
   * Fetch a Specific Collection of Video Resources.
   */
  static async getCollection({
    collection,
    fields,
    page,
    pageSize,
    publisher,
    language,
    orientation,
    types,
    status,
  }) {
    const json = await TuposModel.get(
      api4({
        endpoint: 'movies',
        method: 'videos',
        version: '4.0',
        auth: true,
        parseJson: true,
        params: {
          fields,
          page,
          page_size: pageSize,
          publisher, // publisher id
          collection,
          orientation,
          types,
          status,
        },
        fetchArgs: { cache: 'no-cache' },
        additionalHeaders: {
          'Accept-Language': language,
        },
      }),
    )

    if (!Array.isArray(json.data)) {
      return { rows: [], nextPage: null, pageSize: null }
    }

    const rows = json.data.map((item) => new Video(item))
    return { rows, nextPage: json.next_page, pageSize: json.page_size }
  }

  /**
   * Fetch videos for a specific collection id.
   */
  static async getVideosForCollection({
    collection,
    fields = '*',
    orientation = '*',
    page = 1,
    pageSize = 25,
    types = '*',
    language = 'en',
  }) {
    const json = await TuposModel.get(
      api4({
        additionalHeaders: {
          'Accept-Language': language,
        },
        endpoint: 'movies',
        method: 'videos',
        version: '4.0',
        auth: true,
        parseJson: true,
        params: {
          collection,
          fields,
          orientation,
          page_size: pageSize,
          page,
          types,
        },
        fetchArgs: { cache: 'no-cache' },
      }),
    )

    if (!Array.isArray(json.data)) {
      return { rows: [], nextPage: null, pageSize: null }
    }

    const rows = json.data.map((item) => new Video(item))
    return { rows, nextPage: json.next_page, pageSize: json.page_size }
  }

  /**
   * Fetch All the Video Resources.
   */
  static async getAllVideos({
    pageSize,
    page = 1,
    language = 'en',
    orientation,
    status,
    types,
  }) {
    const json = await TuposModel.get(
      api4({
        endpoint: 'movies',
        method: 'videos',
        version: '4.0',
        auth: true,
        parseJson: true,
        params: {
          page,
          page_size: pageSize,
          orientation,
          status,
          types,
        },
        additionalHeaders: {
          'Accept-Language': language,
        },
      }),
    )

    if (!Array.isArray(json.data)) throw new Error()

    if (json && json.message) throw new Error(json.message)

    const rows = json.data.map((item) => new Video(item))

    return { rows, nextPage: json.next_page, pageSize: json.page_size }
  }

  /**
   * Create a Video Resource.
   */
  async createVideo(uuid) {
    preventLanguageAction(this.language) // Prevent disallowed languages from submitting to the API

    let createPreviewParams = {}
    if (this.orientation === 'portrait') {
      createPreviewParams = {
        preview_length: 6,
        preview_start: '00:00:00.0',
      }
    }

    const json = await TuposModel.get(
      api4({
        endpoint: 'movies',
        method: 'videos',
        version: '4.0',
        auth: true,
        parseJson: true,
        fetchArgs: { method: 'POST' },
        bodyParams: {
          created_dt: new Date().toISOString(),
          source_url: this.sourceUrl.url,
          title: this.title,
          description: this.description,
          language: this.language,
          publisher_id: this.publisherId,
          thumbnail_source_url: this.thumbnailSourceUrl.url,
          references: this.references,
          uuid,
          orientation: this.orientation,
          type: this.type,
          ...createPreviewParams,
        },
      }),
    )

    if (typeof json !== 'object') throw new Error()

    if (json && json.message) throw new Error(json.message)

    this.updatePropsFromJson(json, this.language)

    return this.toObject()
  }

  /** Add a Language to a Video Resource */
  async createVideoLanguage() {
    preventLanguageAction(this.language) // Prevent disallowed languages from submitting to the API
    const json = await TuposModel.get(
      api4({
        endpoint: 'movies',
        method: 'videos',
        version: '4.0',
        auth: true,
        parseJson: true,
        fetchArgs: { method: 'POST' },
        bodyParams: {
          created_dt: new Date().toISOString(),
          source_url: this.sourceUrl.url,
          title: this.title,
          description: this.description,
          language: this.language,
          thumbnail_source_url: this.thumbnailSourceUrl.url,
          id: this.id,
          orientation: this.orientation,
          // type: this.type, // Not allowed to pass Type w/ ID.
        },
      }),
    )

    if (typeof json !== 'object') throw new Error()

    if (json && json.message) throw new Error(json.message)

    this.updatePropsFromJson(json, this.language)

    return this.toObject()
  }

  /** Update Video in a Video Resource */
  async updateVideo() {
    const json = await TuposModel.get(
      api4({
        endpoint: 'movies',
        method: 'videos/:id',
        version: '4.0',
        auth: true,
        parseJson: true,
        fetchArgs: { method: 'PUT' },
        urlParams: {
          id: this.id,
        },
        bodyParams: {
          title: this.title,
          description: this.description,
          language: this.language,
          orientation: this.orientation,
          tracking_id: this.trackingId,
        },
        additionalHeaders: {
          'Accept-Language': this.language,
        },
      }),
    )

    if (typeof json !== 'object') throw new Error()
    if (json && json.message) throw new Error(json.message)

    return new Video(json)
  }

  async updateVideosOrder() {
    const json = await TuposModel.get(
      api4({
        endpoint: 'movies',
        method: 'videos/:id',
        version: '4.0',
        auth: true,
        parseJson: true,
        fetchArgs: { method: 'PUT' },
        urlParams: {
          id: this.id,
        },
        bodyParams: {
          title: this.title,
          status: this.status,
          publisher_id: this.publisherId,
          tracking_id: this.trackingId,
        },
      }),
    )

    if (typeof json !== 'object') throw new Error()
    if (json && json.message) throw new Error(json.message)

    return new Video(json)
  }

  /** Update References in a Video Resource */
  async updateReferences() {
    const json = await TuposModel.get(
      api4({
        endpoint: 'movies',
        method: 'videos/:id',
        version: '4.0',
        auth: true,
        parseJson: true,
        fetchArgs: { method: 'PUT' },
        urlParams: {
          id: this.id,
        },
        bodyParams: {
          references: this.references,
        },
      }),
    )

    if (typeof json !== 'object') throw new Error()
    if (json && json.message) throw new Error(json.message)

    return new Video(json)
  }

  /** Publish a Video Resource */
  async publish() {
    preventLanguageAction(this.language) // Prevent disallowed languages from submitting to the API
    const json = await TuposModel.get(
      api4({
        endpoint: 'movies',
        method: 'videos/:id:publish',
        version: '4.0',
        auth: true,
        parseJson: true,
        fetchArgs: { method: 'PUT' },
        urlParams: {
          id: this.id,
        },
        bodyParams: {
          language: this.language,
        },
      }),
    )

    if (typeof json !== 'object') throw new Error()
    if (json && json.message) throw new Error(json.message)

    return new Video(json)
  }

  /** Delete a Video Resource */
  async delete() {
    const response = await TuposModel.get(
      api4({
        endpoint: 'movies',
        method: 'videos/:id',
        version: '4.0',
        auth: true,
        parseJson: false,
        urlParams: {
          id: this.id,
        },
        fetchArgs: { method: 'DELETE' },
        bodyParams: {
          language: this.language,
        },
      }),
    )

    // https://restfulapi.net/http-methods/ for DELETE
    if (![200, 202, 204].includes(response.status))
      throw new Error(response.statusText)
  }

  /**
   * Search a Video Resource.
   */
  static async search(query, language = 'en', page = 1) {
    const json = await TuposModel.get(
      api4({
        endpoint: 'search',
        method:
          'videos?language_tag=:language&query=:query&page_size=:page&user_intent=topical',
        version: '4.0',
        auth: true,
        parseJson: true,
        urlParams: {
          query,
          language,
          page,
        },
        additionalHeaders: {
          'Accept-Language': language,
        },
      }),
    )

    if (!Array.isArray(json.data)) {
      return { rows: [], nextPage: null, pageSize: null }
    }

    const rows = json.data.map((item) => new Video(item))

    return { rows, nextPage: json.next_page, pageSize: json.page_size }
  }

  /** @type {string} */
  get trackingId() {
    return this._trackingId
  }

  set trackingId(trackingId) {
    this._trackingId = setString(trackingId, 'trackingId')
  }

  /** @type {number} */
  get publisherId() {
    return this._publisherId
  }

  set publisherId(publisherId) {
    this._publisherId = setNumber(publisherId, 'publisherId')
  }

  /** @type {number} */
  get runtime() {
    return this._runtime
  }

  set runtime(runtime) {
    this._runtime = setNumber(runtime, 'runtime')
  }

  /** @type {string} */
  get references() {
    return this._references
  }

  set references(references) {
    this._references = setArray(references, 'references')
  }

  /** @type {string} */
  get description() {
    return this._description
  }

  set description(description) {
    this._description = setString(description, 'description')
  }

  /** @type {string} */
  get createdDt() {
    return this._createdDt
  }

  set createdDt(createdDt) {
    this._createdDt = setString(createdDt, 'createdDt')
  }

  /** @type {string} */
  get title() {
    return this._title
  }

  set title(title) {
    this._title = setString(title, 'title')
  }

  /** @type {string} */
  get status() {
    return this._status
  }

  set status(status) {
    this._status = setString(status, 'status')
  }

  /** @type {string} */
  get orientation() {
    return this._orientation
  }

  set orientation(orientation) {
    this._orientation = setString(orientation, 'orientation')
  }

  /** @type {string} */
  get language() {
    return this._language
  }

  set language(language) {
    this._language = setString(language, 'language')
  }

  /** @type {string} */
  get type() {
    return this._type
  }

  set type(type) {
    this._type = setString(type, 'type')
  }

  /** @type {number} */
  get id() {
    return this._id
  }

  set id(id) {
    this._id = setNumber(id, 'id')
  }

  /** @type {string} */
  get sourceUrl() {
    return this._sourceUrl
  }

  set sourceUrl(url) {
    this._sourceUrl = setUrl(url, 'sourceUrl')
  }

  /** @type {string} */
  get thumbnailSourceUrl() {
    return this._thumbnailSourceUrl
  }

  set thumbnailSourceUrl(url) {
    this._thumbnailSourceUrl = setUrl(url, 'thumbnailSourceUrl')
  }
}

export default Video
