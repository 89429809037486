import React from 'react'
import PropTypes from 'prop-types'
import { Box, Typography, Paper, List } from '@material-ui/core'
import Video from 'tupos/models/video'
import useAsync from 'hooks/useAsync'
import VideoAndDetails from 'components/ui/video-and-details'
import { Alert } from '@material-ui/lab'

// These will help control showing an alert on this component,
// but only for a limited time.
const rightAboutNow = new Date()
const startDate = new Date(2021, 9, 1)
const endDate = new Date(2021, 11, 1)

export default function RecentVideoList({ config }) {
  const getVideos = React.useCallback(async () => {
    const videosResponse = await Video.getCollection({
      page: '*',
      language: '*',
      orientation: '*',
      types: '*',
    })

    return videosResponse.rows
  }, [])

  const {
    pending: isLoading,
    value: videos,
    error: failedToGetVideos,
  } = useAsync(getVideos)

  const isLoaded = Boolean(videos && videos.length && config && !isLoading)

  let contentComponent = (
    <List>
      {(isLoaded ? videos : Array.from(new Array(3))).map((video, index) => (
        <VideoAndDetails
          key={`${video?.language || ''}-${index}-${video?.id || ''}`}
          video={video}
          config={config}
          language={video?.language || ''}
        />
      ))}
    </List>
  )

  if (failedToGetVideos || videos?.length === 0) {
    contentComponent = (
      <Box m={2}>
        <Typography color="textSecondary">
          <span role="img" aria-label="sad emoji">
            😕
          </span>{' '}
          Failed to load video data
        </Typography>
      </Box>
    )
  }

  return (
    <Box display="flex" flexGrow={1}>
      <Box
        borderRadius={16}
        component={Paper}
        display="flex"
        flexDirection="column"
        flexGrow={1}
        pt={2}
        variant="outlined"
      >
        <Box pl={2} pr={2}>
          <Typography
            gutterBottom={true}
            variant="h2"
            color="textPrimary"
            style={{ marginTop: 0 }}
          >
            Recent Video Uploads
          </Typography>
          {rightAboutNow >= startDate &&
          (rightAboutNow < endDate || !endDate) ? (
            <Alert severity="info" variant="outlined">
              This list will now include the most recent videos from every
              language.
            </Alert>
          ) : null}
        </Box>
        {contentComponent}
      </Box>
    </Box>
  )
}

RecentVideoList.propTypes = {
  config: PropTypes.shape({
    imageUrls: PropTypes.objectOf(PropTypes.string),
  }),
}

RecentVideoList.defaultProps = {
  config: undefined,
}
