import React from 'react'
import Button from '@material-ui/core/Button'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { parse, statusTypes, stringify } from 'utils'

const Pager = ({ pageNumber, pageSize, dataLength, hasNextPage, loading }) => {
  // Until all components that use Pager are converted to function,
  // we will need to check the type of loading that gets passed.
  const isLoading =
    typeof loading === 'object'
      ? loading.status !== statusTypes.RESOLVED
      : loading
  if (dataLength < 1 && isLoading) {
    return null
  }

  const firstItem = pageSize * (pageNumber - 1) + 1
  const nextParams = parse(window.location.search)
  nextParams.page = parseInt(pageNumber, 10) + 1
  const prevParams = parse(window.location.search)
  prevParams.page = parseInt(pageNumber, 10) - 1
  return (
    <>
      {pageNumber > 1 && (
        <Button
          variant="text"
          color="primary"
          component={Link}
          to={{ search: stringify(prevParams) }}
        >
          <KeyboardArrowLeft />
          Previous
        </Button>
      )}
      <Button disabled={true}>
        {firstItem} - {firstItem + dataLength - 1}
      </Button>
      {hasNextPage && (
        <Button
          variant="text"
          color="primary"
          component={Link}
          to={{ search: stringify(nextParams) }}
        >
          Next
          <KeyboardArrowRight />
        </Button>
      )}
    </>
  )
}

Pager.propTypes = {
  pageNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  pageSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  hasNextPage: PropTypes.bool,
  dataLength: PropTypes.number.isRequired,
  // Once all components that use Pager are converted to function,
  // we can make remove oneOfType and bool.
  loading: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      status: PropTypes.string,
    }),
  ]),
}

Pager.defaultProps = {
  pageSize: null,
  hasNextPage: null,
}

export default Pager
