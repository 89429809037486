/* eslint-disable no-console */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { Component } from 'react'
import Button from '@material-ui/core/Button'
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  Typography,
  withStyles,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import TextField from '@material-ui/core/TextField'
import Divider from '@material-ui/core/Divider'
import { imageDimensions } from 'utils'
import * as Sentry from '@sentry/react'
import Publisher from '../../tupos/models/publisher'
import SecondaryAppBar from '../../components/layout/secondary-app-bar'
import DetailCard from '../../components/layout/detail-card'
import Languages from '../../tupos/models/languages'
import LanguageSelector from '../../components/ui/language-selector'
import ImageFileInput from '../../components/ui/image-file-input'

const styles = (theme) => ({
  content: {
    maxWidth: 960,
    margin: 'auto',
    padding: theme.spacing(2),
  },

  // Detailed Card width
  card: {
    width: '100%',
  },

  dottedBorder: {
    border: '1px dotted rgb(212, 212, 212) ',
    width: '360px',
    height: '186px',
    padding: theme.spacing(8),
  },

  images: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
  },

  textField: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(1),
    width: 375,
  },

  // Hyperlinks
  link: {
    color: 'rgba(0, 0, 0, 0.54)',
    margin: 'auto',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
})

class PublisherAdd extends Component {
  constructor(props) {
    super(props)
    this.state = {
      publisher: new Publisher({}),
      open: false,
      languageList: ['en'],
      logoFile: null,
      bannerFile: null,
      errorMessages: [],
      saving: false,
    }
  }

  componentDidMount() {
    this.handleReserveId()
    this.handleFetchLanguages()
  }

  // Reserve collection id on load and set in state so it can be used during save
  handleReserveId = async () => {
    const { publisher } = this.state
    try {
      const reserveIdResponse = await publisher.reservePublisherId()
      publisher.id = reserveIdResponse.id
      this.setState({
        publisher,
      })
    } catch (error) {
      Sentry.captureException(error)
    }
  }

  handleFetchLanguages = async () => {
    try {
      const languageResponse = await Languages.get('publisher')
      this.setState({
        languageList: languageResponse,
      })
    } catch (error) {
      Sentry.captureException(error)
    }
  }

  handleLanguageChange = (selectedLanguage) => {
    const { publisher } = this.state
    publisher.language = selectedLanguage
    this.setState({
      publisher,
    })
  }

  handleInputChange = (e) => {
    const { publisher } = this.state
    publisher[e.target.name] = e.target.value
    this.setState({
      publisher,
    })
  }

  handleSetImageFile = (event) => {
    this.setState({
      [event.target.id]: event.target.files[0],
    })
  }

  handleSetHasValidationErrors = (hasErrors, itemId, errorMessage) => {
    const { errorMessages } = this.state
    // If we pass in 'true'
    if (hasErrors) {
      this.setState((prevState) => ({
        errorMessages: [
          ...prevState.errorMessages,
          { itemId, message: errorMessage },
        ],
      }))
    } else {
      // If false, filter out errors that match this item's id
      const filteredErrors = errorMessages.filter(
        (error) => error.itemId !== itemId,
      )
      this.setState({ errorMessages: filteredErrors })
    }
  }

  handlePreSaveValidations = () => {
    const { errorMessages, logoFile, bannerFile, publisher } = this.state
    // Check that both image files exist
    if (
      (!logoFile || !bannerFile) &&
      !errorMessages.some((error) => error.itemId === 'bothImagesExist')
    ) {
      this.setState((prevState) => {
        return {
          errorMessages: [
            ...prevState.errorMessages,
            {
              itemId: 'bothImagesExist',
              message: 'Both logo and banner are required.',
            },
          ],
        }
      })
    } else if (
      logoFile &&
      bannerFile &&
      errorMessages.some((error) => error.itemId === 'bothImagesExist')
    ) {
      // If false, filter out this error
      const filteredErrors = errorMessages.filter(
        (error) => error.itemId !== 'bothImagesExist',
      )
      this.setState({ errorMessages: filteredErrors })
    }

    // Check that name and URL exist
    if (
      (!publisher.name || !publisher.url) &&
      !errorMessages.some((error) => error.itemId === 'nameAndUrlExist')
    ) {
      this.setState((prevState) => {
        return {
          errorMessages: [
            ...prevState.errorMessages,
            {
              itemId: 'nameAndUrlExist',
              message: 'Both name and URL are required.',
            },
          ],
        }
      })
    } else if (
      publisher.name &&
      publisher.url &&
      errorMessages.some((error) => error.itemId === 'nameAndUrlExist')
    ) {
      // If false, filter out this error
      const filteredErrors = errorMessages.filter(
        (error) => error.itemId !== 'nameAndUrlExist',
      )
      this.setState({ errorMessages: filteredErrors })
    }

    // Check that language has been set
    if (
      !publisher.language &&
      !errorMessages.some((error) => error.itemId === 'languageExists')
    ) {
      this.setState((prevState) => {
        return {
          errorMessages: [
            ...prevState.errorMessages,
            {
              itemId: 'languageExists',
              message: 'You must choose a language.',
            },
          ],
        }
      })
    } else if (
      publisher.language &&
      errorMessages.some((error) => error.itemId === 'languageExists')
    ) {
      // If false, filter out this error
      const filteredErrors = errorMessages.filter(
        (error) => error.itemId !== 'languageExists',
      )
      this.setState({ errorMessages: filteredErrors })
    }
  }

  /**
   * Copied from Tommy's publisher-add-language.
   */
  handlePresignedUpload = async (file, url, params) => {
    const formData = new FormData()
    if (!file) {
      throw new Error('File not found. Please try again.')
    }
    Object.entries(params).forEach(([key, value]) => {
      formData.append(key, value)
    })

    formData.append('file', file)

    const response = fetch(url, { method: 'POST', body: formData })
      .then((fetchResponse) => {
        return fetchResponse
      })
      .catch((error) => {
        Sentry.captureException(error)
      })
    return response
  }

  handleSave = async (e) => {
    const { history } = this.props
    const { publisher, logoFile, bannerFile } = this.state
    e.preventDefault()

    this.setState({ saving: true })

    // Upload logo
    await publisher
      .getPresignedRequest(publisher.language, 'logo')
      .then((response) => {
        // On success, actually attempt our S3 file upload!
        const logoUploadResponse = this.handlePresignedUpload(
          logoFile,
          response.upload_url,
          response.params,
        )

        return logoUploadResponse
      })

    // Upload logo
    await publisher
      .getPresignedRequest(publisher.language, 'banner')
      .then((response) => {
        // On success, actually attempt our S3 file upload!
        const bannerUploadResponse = this.handlePresignedUpload(
          bannerFile,
          response.upload_url,
          response.params,
        )

        return bannerUploadResponse
      })

    await publisher.addPublisher()

    history.push(`/publishers/${publisher.id}?lang=${publisher.language}`)
  }

  handleCheckForErrors = () => {
    const { errorMessages } = this.state
    return errorMessages.length > 0
  }

  handleDialogOpen = async () => {
    await this.handlePreSaveValidations()
    const hasErrors = await this.handleCheckForErrors()
    if (!hasErrors) {
      this.setState({
        open: true,
      })
    }
  }

  handleDialogClose = () => {
    this.setState({
      open: false,
    })
  }

  handleErrorClose = () => {
    this.setState({ errorMessages: [] })
  }

  render() {
    const { classes } = this.props

    const { open, languageList, publisher, errorMessages, saving } = this.state

    const displayErrors = errorMessages.map((error) => {
      return (
        <Alert key={[`error_${error.itemId}`]} severity="error">
          {error.message}
        </Alert>
      )
    })
    return (
      <div>
        <SecondaryAppBar
          actionComponent={
            <Button
              color="primary"
              onClick={this.handleDialogOpen}
              variant="contained"
            >
              Save
            </Button>
          }
          passItBack={true}
          title="Add New Publisher"
        />
        {displayErrors}
        <Dialog onClose={this.handleDialogClose} open={open}>
          <DialogTitle disableTypography={true}>
            <Typography variant="h3">
              Are you ready to save this new publisher?
            </Typography>
          </DialogTitle>
          <DialogActions>
            <Button
              color="primary"
              disabled={saving}
              onClick={this.handleDialogClose}
            >
              No
            </Button>
            <div className={classes.wrapper}>
              <Button
                color="primary"
                disabled={saving}
                onClick={this.handleSave}
              >
                Yes
              </Button>
              {saving && (
                <CircularProgress
                  className={classes.buttonProgress}
                  size={24}
                />
              )}
            </div>
          </DialogActions>
        </Dialog>

        <Grid className={classes.content} container={true} spacing={5}>
          <Grid item={true} xs={6}>
            <TextField
              className={classes.textField}
              id="name"
              label="Name"
              multiline={true}
              name="name"
              onChange={this.handleInputChange}
              rowsMax="5"
            />
          </Grid>
          <Grid item={true} xs={6}>
            <TextField
              className={classes.textField}
              defaultValue=""
              id="url"
              label="URL"
              multiline={true}
              name="url"
              onChange={this.handleInputChange}
              rowsMax="5"
            />
          </Grid>
          <Grid item={true} xs={6}>
            <TextField
              className={classes.textField}
              id="trackingId"
              label="Tracking ID (Optional)"
              name="trackingId"
              onChange={this.handleInputChange}
            />
          </Grid>

          <DetailCard>
            <Grid item={true} xs={12}>
              <LanguageSelector
                changed={this.handleLanguageChange}
                current={publisher.language}
                langs={languageList}
              />
              <br />
              <Divider />
            </Grid>
            <Grid item={true} xs={6}>
              <ImageFileInput
                id="bannerFile"
                labelText={`Banner (${imageDimensions.PUBLISHER_BANNER.width}x${imageDimensions.PUBLISHER_BANNER.height})`}
                onChange={this.handleSetImageFile}
                onValidate={this.handleSetHasValidationErrors}
                previewHeight={267}
                previewWidth={800}
                validateFileType="image/jpg, image/jpeg"
                validateHeight={imageDimensions.PUBLISHER_BANNER.height}
                validateMaxFileSize={1000000}
                validateWidth={imageDimensions.PUBLISHER_BANNER.width}
              />
              <ImageFileInput
                id="logoFile"
                labelText={`Logo (${imageDimensions.PUBLISHER_LOGO.size}x${imageDimensions.PUBLISHER_LOGO.size})`}
                onChange={this.handleSetImageFile}
                onValidate={this.handleSetHasValidationErrors}
                previewHeight={imageDimensions.PUBLISHER_LOGO.size}
                previewWidth={imageDimensions.PUBLISHER_LOGO.size}
                validateFileType="image/png"
                validateHeight={imageDimensions.PUBLISHER_LOGO.size}
                validateMaxFileSize={1000000}
                validateWidth={imageDimensions.PUBLISHER_LOGO.size}
              />
            </Grid>
            <Grid item={true} xs={6}>
              <TextField
                className={classes.textField}
                defaultValue=""
                id="description"
                label="Description"
                multiline={true}
                name="description"
                onChange={this.handleInputChange}
                rowsMax="5"
              />
            </Grid>
          </DetailCard>
        </Grid>
      </div>
    )
  }
}

PublisherAdd.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  history: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.shape({}),
      PropTypes.func,
    ]),
  ).isRequired,
  match: PropTypes.shape({
    params: PropTypes.objectOf(PropTypes.string),
  }).isRequired,
}

export default withRouter(withStyles(styles)(PublisherAdd))
