/**
 * @module SignIn
 */
import React from 'react'
import PropTypes from 'prop-types'
import {
  AsyncForm,
  FormActions,
  FormContent,
  FormError,
  FormLoadError,
  FormLoading,
} from '@youversion/react/components'
import { useSignInModel } from '@youversion/react/providers'
import {
  Box,
  Button,
  LinearProgress,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { ReactComponent as YouVersionLogo } from '@youversion/react/assets/images/youversion-logo.svg'
import bibleAppIcon from '@youversion/react/assets/images/bible-app-icon.png'
import { AIR_TABLE_REQUEST_ACCESS_FORM_URL } from 'utils/constants'

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    justifyContent: 'space-around',
    width: '100vw',
    height: '100vh',
  },
  yvLogo: {
    '& path': {
      fill: theme.palette.text.primary,
    },
  },
  paper: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 500,
    padding: `${theme.spacing(2)}px ${theme.spacing(6)}px ${theme.spacing(
      3,
    )}px`,
    [theme.breakpoints.down('sm')]: {
      padding: `${theme.spacing(2)}px ${theme.spacing(4)}px ${theme.spacing(
        3,
      )}px`,
    },
  },
  header: {
    alignItems: 'flex-end',
    display: 'flex',
    marginBottom: theme.spacing(2),
  },
  storiesTitle: {
    fontWeight: 'bold',
  },
  logo: {
    height: 100,
    width: 100,
  },
  kbeLogo: {
    fill: theme.palette.text.primary,
  },
  form: {
    marginBottom: `${theme.spacing(5)}px`,
    marginTop: theme.spacing(1),
    width: '100%', // Fix IE11 issue.
  },
  submit: {
    marginTop: theme.spacing(3),
  },
  helpContainer: {
    display: 'flex',
    fontSize: '12px',
    justifyContent: 'space-around',
    width: '100%',
  },
  divider: {
    backgroundColor: theme.palette.divider,
    height: '85%',
    width: '1px',
  },
  help: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
  },
  kbeLabel: {
    color: '#57A9D3',
  },
}))

function SignInFormError({ error }) {
  return <Alert severity="error">{error.message}</Alert>
}
SignInFormError.propTypes = {
  error: PropTypes.oneOfType([PropTypes.instanceOf(Error)]),
}
SignInFormError.defaultProps = {
  error: null,
}

function SignInFormActions({ status }) {
  const classes = useStyles()
  return (
    <Button
      className={classes.submit}
      color="primary"
      fullWidth={true}
      disabled={Boolean(status === 'loading')}
      size="large"
      type="submit"
      variant="contained"
    >
      Sign In
    </Button>
  )
}
SignInFormActions.propTypes = {
  status: PropTypes.string,
}
SignInFormActions.defaultProps = {
  status: 'loading',
}

function SignInFormContent({
  handleAttributeChange,
  handleBlur,
  isTouched,
  signInModel,
}) {
  return (
    <>
      <TextField
        autoComplete="email"
        autoFocus={true}
        error={Boolean(isTouched('email') && signInModel.email.$error)}
        fullWidth={true}
        helperText={
          isTouched('email') && signInModel.email.$error
            ? signInModel.email.$error
            : ''
        }
        id="email"
        label="Email Address"
        margin="normal"
        name="email"
        onChange={handleAttributeChange}
        onBlur={handleBlur}
        required={true}
        value={signInModel.email.$value}
        variant="outlined"
      />

      <TextField
        autoComplete="current-password"
        error={Boolean(isTouched('password') && signInModel.password.$error)}
        fullWidth={true}
        helperText={
          isTouched('password') && signInModel.password.$error
            ? signInModel.password.$error
            : ''
        }
        id="password"
        label="Password"
        margin="normal"
        name="password"
        onBlur={handleBlur}
        onChange={handleAttributeChange}
        type="password"
        required={true}
        variant="outlined"
        value={signInModel.password.$value}
      />
    </>
  )
}
SignInFormContent.propTypes = {
  handleAttributeChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  isTouched: PropTypes.func.isRequired,
  // We do not have a proxy type for PropTypes and therefore cannot accurately type signInModel.
  signInModel: PropTypes.any,
}
SignInFormContent.defaultProps = {
  signInModel: {},
}

/**
 * Sign-in Component.
 *
 * @returns {React.ReactElement} - The Sign-in Component.
 */
export default function SignInForm() {
  const {
    error,
    handleAttributeChange,
    handleBlur,
    handleDelete,
    handleSave: handleSignIn,
    model: signInModel,
    isTouched,
    status,
  } = useSignInModel()

  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.paper}>
        <div className={classes.header}>
          <Box height={64} mr={2} width={64}>
            <img alt="The Bible App" src={bibleAppIcon} />
          </Box>
          <Box display="flex" flexDirection="column">
            <YouVersionLogo
              className={classes.yvLogo}
              height="12"
              title="YouVersion Brand Logo"
              width="77.5"
            />
            <Typography
              className={classes.storiesTitle}
              color="textPrimary"
              variant="h4"
            >
              Blockbuster
            </Typography>
          </Box>
        </div>

        <Typography
          align="center"
          color="textPrimary"
          component="h1"
          gutterBottom={true}
          variant="h3"
        >
          Sign In
        </Typography>
        <Typography
          align="center"
          color="textSecondary"
          component="h2"
          variant="subtitle2"
        >
          {`With Your YouVersion ${
            window.API_ENV.toLowerCase() !== 'production' ? 'Staging' : ''
          } Account`}
        </Typography>

        <div className={classes.form}>
          <AsyncForm
            error={error}
            onDelete={handleDelete}
            onSave={handleSignIn}
            status={status}
          >
            <FormLoadError>
              <SignInFormError />
            </FormLoadError>

            <FormLoading>
              <LinearProgress />
            </FormLoading>

            <FormError>
              <SignInFormError />
            </FormError>

            <FormContent>
              <SignInFormContent
                handleAttributeChange={handleAttributeChange}
                handleBlur={handleBlur}
                isTouched={isTouched}
                signInModel={signInModel}
              />
            </FormContent>

            <FormActions>
              <SignInFormActions />
            </FormActions>
          </AsyncForm>
        </div>

        <div className={classes.helpContainer}>
          <div className={classes.help}>
            <Typography variant="body2">New to YouVersion?</Typography>
            <Button
              color="primary"
              href="https://www.bible.com/sign-up"
              target="_blank"
            >
              Create Account
            </Button>
          </div>
          <div>
            <div className={classes.divider} />
          </div>
          <div className={classes.help}>
            <Typography variant="body2">Need Access?</Typography>
            <Button
              color="primary"
              href={AIR_TABLE_REQUEST_ACCESS_FORM_URL}
              target="_blank"
            >
              Request Access
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
