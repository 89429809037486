import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
  Collapse,
  makeStyles,
  IconButton,
  Box,
  Typography,
  Paper,
  Button,
  LinearProgress,
  Fade,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import LaunchIcon from '@material-ui/icons/Launch'
import Video from 'tupos/models/video'
import { statusTypes, fieldVariants } from 'utils/constants'
import { FriendlyError } from 'components/ui/friendly-error-page'
import imgPeopleCelebrating from 'assets/img/people-celebrating.png'
import ButtonDialog from 'components/ui/button-dialog'
import VideoLanguageFields from 'components/views/video/video-language-fields'
import SubmitButton from 'components/ui/submit-button'

const useVideoTableStyles = makeStyles(() => ({
  table: {
    overflow: 'hidden',
  },
  tableContainer: {
    width: '100%',
    tableLayout: 'fixed',
  },
}))

export default function ReadyToPublishVideoTable({
  videos,
  handleVideoUpdate,
  handlePublish,
  selectedVideos,
  videoRetrievalStatus,
}) {
  const [selectAll, setSelectAll] = React.useState(false)
  const [showIndeterminateCheck, setShowIndeterminateCheck] = React.useState(
    false,
  )
  const classes = useVideoTableStyles()

  // Set checkboxes
  React.useEffect(() => {
    if (videos.length > 0) {
      if (videos.every((vid) => vid.isSelected === true)) {
        setSelectAll(true)
        setShowIndeterminateCheck(false)
      } else if (videos.every((vid) => vid.isSelected === false)) {
        setSelectAll(false)
        setShowIndeterminateCheck(false)
      } else {
        setShowIndeterminateCheck(true)
      }
    }
  }, [videos])

  const updateVideoSelect = (videoId, value) => {
    handleVideoUpdate((prevVideos) =>
      prevVideos.map((vid) => {
        if (vid.object.id === videoId) {
          const updatedVideo = vid
          updatedVideo.isSelected = value
          return updatedVideo
        }
        return vid
      }),
    )
  }

  const handleSelectAll = () => {
    const updatedSelectAllValue = !selectAll
    setSelectAll(updatedSelectAllValue)
    handleVideoUpdate((prevVideos) =>
      prevVideos.map((video) => {
        // if errors exist on the video, don't allow it to be selected
        if (video.errors.length > 0) {
          const updatedVideo = video
          updatedVideo.isSelected = false
          return updatedVideo
        }

        const updatedVideo = video
        updatedVideo.isSelected = updatedSelectAllValue
        return updatedVideo
      }),
    )
  }

  if (videos.length === 0 && videoRetrievalStatus === statusTypes.RESOLVED)
    return (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        mt={4}
        mb={4}
      >
        <FriendlyError
          imageCredit="Illustrations from https://www.ls.graphics/"
          title="Looks like there's nothing to review!"
          imageSrc={imgPeopleCelebrating}
          imageHeight={347}
          imageWidth={306}
        />
      </Box>
    )

  const videoError = videos.some((vid) => vid.errors.length > 0)

  return (
    <>
      {[statusTypes.PENDING, statusTypes.IDLE].includes(
        videoRetrievalStatus,
      ) ? (
        <LinearProgress />
      ) : null}
      <Collapse in={Boolean(videoRetrievalStatus === statusTypes.RESOLVED)}>
        <TableContainer component={Paper} classes={{ root: classes.table }}>
          <Box
            ml={2}
            mr={2}
            mt={2}
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Typography variant="h3">
              {selectedVideos.length < 1
                ? 'Videos Ready to Publish'
                : `${selectedVideos.length} Video${
                    selectedVideos.length > 1 ? 's' : ''
                  } Selected`}
            </Typography>
            <ButtonDialog
              dialogPrompt="Skip publishing videos with errors?"
              dialogConfirmText="Publish Anyways"
              dialogDenyText="Fix Errors"
              handleDialogConfirm={handlePublish}
              handleDialogDeny={() => {}}
              skipDialog={!videoError}
              disabled={selectedVideos.length <= 0}
              actionComponent={
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handlePublish}
                >
                  Publish
                </Button>
              }
            />
          </Box>
          <Table
            aria-label="Videos ready to publish"
            className={classes.tableContainer}
          >
            <TableHead>
              <ReadyVideosTableHead
                handleSelectAll={handleSelectAll}
                selectAll={selectAll}
                showIndeterminateCheck={showIndeterminateCheck}
              />
            </TableHead>
            <TableBody>
              {videos.map((vid) => (
                <ReadyToPublishVideoRow
                  video={vid}
                  key={`row_${vid.object.id}_${vid.object.language}`}
                  handleVideoSelect={updateVideoSelect}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Collapse>
    </>
  )
}
ReadyToPublishVideoTable.propTypes = {
  handlePublish: PropTypes.func.isRequired,
  handleVideoUpdate: PropTypes.func.isRequired,
  selectedVideos: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  videoRetrievalStatus: PropTypes.string.isRequired,
  videos: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
}

const useStyles = makeStyles(() => ({
  tableRow: {
    '& > *': {
      borderBottom: 'unset',
    },
  },

  tableCellOpenIcon: {
    width: '1px',
    paddingRight: 0,
  },

  videoTitle: {
    minWidth: 250,
    maxWidth: 250,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}))

export function ReadyToPublishVideoRow({ video, handleVideoSelect }) {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)
  const [cachedVideoFromApi, setCachedVideoFromApi] = React.useState() // used for resetting fields
  const [completeVideoObject, setCompleteVideoObject] = React.useState()
  const [videoUpdateStatus, setVideoUpdateStatus] = React.useState(
    statusTypes.IDLE,
  )
  const [hasEdits, setHasEdits] = React.useState(false)
  const [userPrompt, setUserPrompt] = React.useState('')
  const [error, setError] = React.useState('')

  const clearError = () => setError('')
  const clearUserPrompt = () => setUserPrompt('')

  const handleUpdateVideoToApi = async () => {
    const videoClass = Video.toClass({
      json: completeVideoObject,
      language: completeVideoObject.language,
    })

    try {
      setVideoUpdateStatus(statusTypes.PENDING)
      await videoClass.updateVideo()
      const videoResponse = await videoClass.updateReferences()
      const videoObject = {
        ...videoResponse.toObject(),
        language: video.object.language,
      }
      setCompleteVideoObject(videoObject)
      clearUserPrompt()
      setVideoUpdateStatus(statusTypes.RESOLVED)

      // allows user to see status of update api call for a couple of seconds
      setTimeout(() => {
        setHasEdits(false)
        setVideoUpdateStatus(statusTypes.IDLE)
      }, 2000)
      return true
    } catch {
      setVideoUpdateStatus(statusTypes.REJECTED)
      return false
    }
  }

  const validateVideo = (name, value) => {
    if (name === 'title' && !value) {
      setError('Video title cannot be empty.')
      return false
    }
    clearError()
    return true
  }

  const handleUpdateVideoAttribute = async ({ target: { name, value } }) => {
    const updatedVideo = {
      ...completeVideoObject,
      [name]: value,
    }
    setCompleteVideoObject(updatedVideo)
    validateVideo(name, value)
    setHasEdits(true)
    handleVideoSelect(video.object.id, false)
  }

  const handleResetVideoAttributes = () => {
    setCompleteVideoObject(cachedVideoFromApi)
    setHasEdits(false)
    clearError()
    clearUserPrompt()
  }

  const handleVideoSelection = () => {
    if (hasEdits) {
      setUserPrompt('Must update video before selecting to publish.')
      return
    }

    handleVideoSelect(video.object.id, !video.isSelected)
  }

  React.useEffect(() => {
    const isReadyToGetVideo = Boolean(open && !completeVideoObject)

    if (isReadyToGetVideo) {
      const getCompleteVideoObject = async () => {
        try {
          const videoResponse = await Video.get(
            video.object.id,
            video.object.language,
          )
          const videoObject = {
            ...videoResponse.toObject(),
            language: video.object.language,
          }
          setCompleteVideoObject(videoObject)
          setCachedVideoFromApi(videoObject)
        } catch (videoRetrievalError) {
          setError(videoRetrievalError.message)
        }
      }

      getCompleteVideoObject()
    }
  }, [open, completeVideoObject, video.object.id, video.object.language])

  return (
    <>
      <TableRow className={classes.tableRow}>
        <TableCell className={classes.tableCellOpenIcon}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            onClick={handleVideoSelection}
            disabled={video.errors.length > 0}
            checked={video.isSelected}
            inputProps={{ 'aria-labelledby': 1 }}
          />
        </TableCell>
        <TableCell scope="row" padding="none" className={classes.videoTitle}>
          {completeVideoObject?.title || video.object.title}
          <IconButton
            component={Link}
            to={`/videos/${video.object.id}?lang=${video.object.language}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <LaunchIcon fontSize="small" color="action" />
          </IconButton>
        </TableCell>
        <TableCell align="right">
          <Fade in={hasEdits} style={{ minHeight: 36 }}>
            <div>
              {hasEdits ? (
                <SubmitButton
                  variant="outlined"
                  color="primary"
                  onClick={handleUpdateVideoToApi}
                  onError={(err) => setError(err)}
                >
                  Save Changes
                </SubmitButton>
              ) : null}
              {hasEdits && videoUpdateStatus === statusTypes.IDLE ? (
                <Box ml={1} component="span">
                  <Button onClick={handleResetVideoAttributes}>Cancel</Button>
                </Box>
              ) : null}
            </div>
          </Fade>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          {userPrompt ? (
            <Box mb={2}>
              <Alert severity="info">{userPrompt}</Alert>
            </Box>
          ) : null}
          {error ? (
            <Box mb={2}>
              <Alert severity="error">{error}</Alert>
            </Box>
          ) : null}
          {video.errors.map((err) => (
            <Box mb={2} key={err}>
              <Alert severity="error">{err}</Alert>
            </Box>
          ))}
          <Collapse
            in={Boolean(open && completeVideoObject)}
            unmountOnExit={true}
          >
            <Box mb={3} mt={1}>
              <VideoLanguageFields
                variant={fieldVariants.UPDATE}
                video={completeVideoObject}
                onInputChange={handleUpdateVideoAttribute}
                onValidate={() => {}}
                showReferences={true}
              />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

ReadyToPublishVideoRow.propTypes = {
  video: PropTypes.any.isRequired,
  handleVideoSelect: PropTypes.func.isRequired,
}

export function ReadyVideosTableHead({
  handleSelectAll,
  selectAll,
  showIndeterminateCheck,
}) {
  return (
    <TableRow>
      <TableCell width="48px" />
      <TableCell padding="checkbox">
        <Checkbox
          color="primary"
          indeterminate={showIndeterminateCheck}
          checked={selectAll}
          onChange={handleSelectAll}
          inputProps={{ 'aria-label': 'select all rows' }}
        />
      </TableCell>
      <TableCell width="50%" padding="none">
        Video Title
      </TableCell>
      <TableCell width="50%" align="right" />
    </TableRow>
  )
}

ReadyVideosTableHead.propTypes = {
  handleSelectAll: PropTypes.func.isRequired,
  selectAll: PropTypes.bool.isRequired,
  showIndeterminateCheck: PropTypes.bool.isRequired,
}
