/**
 * @module consolidateVideoIdLists
 */
/**
 * Function to consolidate an entire collection video list and a collection language's video list.
 *
 * @alias module:consolidateVideoIdLists
 *
 * @param {object} params - The function params object.
 * @param {array<string>} params.completeIdList - The complete list of video IDs.
 * @param {array<string>} params.initialLanguageIdList - The language-specific list of video IDs initially fetched from the database. This lets us check for deleted items.
 * @param {array<string>} params.languageIdList - The language-specific list of video IDs.
 *
 * @returns {array} - The consolidated array of video IDs.
 */

export function consolidateVideoIdLists({
  completeIdList,
  initialLanguageIdList,
  languageIdList,
}) {
  if (
    !completeIdList ||
    !Array.isArray(completeIdList) ||
    !initialLanguageIdList ||
    !Array.isArray(initialLanguageIdList) ||
    !languageIdList ||
    !Array.isArray(languageIdList)
  ) {
    throw new Error('Missing or incorrect function params.')
  }

  // Return items in the initial list that are not in the final list.
  const removedItems = initialLanguageIdList.filter(
    (id) => !languageIdList.includes(id),
  )

  // Return items that are neither in the deleted items list nor the final language list.
  const filteredCompleteList = completeIdList.filter(
    (id) => !languageIdList.includes(id) && !removedItems.includes(id),
  )
  return [...languageIdList, ...filteredCompleteList]
}
