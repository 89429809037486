import React from 'react'
import {
  Button,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  makeStyles,
} from '@material-ui/core'
import appLanguagesJSON from 'tupos/models/languages/app-languages.json'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    width: '300px',
    margin: '0 auto',
    '&:focus': {
      outline: 'none',
    },
  },
  container: {
    margin: '0 auto',
    padding: theme.spacing(2, 4, 3),
  },
}))

export default function DetailModal({ languages }) {
  const classes = useStyles()

  const [openLangStatModal, setOpenLangStatModal] = React.useState(false)

  function getOptionLabel(langTag) {
    if (appLanguagesJSON[langTag]?.english) {
      return `${appLanguagesJSON[langTag].english}`
    }
    return `(${langTag})`
  }

  return (
    <div>
      <Button
        onClick={() => setOpenLangStatModal((prevState) => !prevState)}
        variant="outlined"
      >
        See Details
      </Button>
      <Modal
        open={openLangStatModal}
        onClose={() => setOpenLangStatModal((prevState) => !prevState)}
        aria-labelledby="see-details"
        aria-describedby="view-of-number-of-videos-in-each-language"
      >
        <div className={classes.root}>
          <TableContainer className={classes.container} component={Paper}>
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell data-testid="languages-heading">
                    Languages
                  </TableCell>
                  <TableCell align="right" data-testid="total-heading">
                    Total
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody className={classes.table}>
                {Object.keys(languages).map((language) => (
                  <TableRow key={language}>
                    <TableCell
                      component="th"
                      data-testid="language"
                      scope="row"
                    >
                      {getOptionLabel(language)}
                    </TableCell>
                    <TableCell align="right" data-testid="total-languages">
                      {languages[language].length}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </Modal>
    </div>
  )
}

DetailModal.propTypes = {
  languages: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.number)).isRequired,
}
