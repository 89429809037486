import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { Box, Typography, makeStyles, ListItem } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { urlReplacer } from 'utils'
import useAsync from 'hooks/useAsync'
import Publisher from 'tupos/models/publisher'
import LazyImage from 'components/ui/lazy-image'

const thumbnailDimensions = {
  height: 84,
  width: 150,
}

const portraitInnerDimensions = {
  height: 84,
  width: 47,
}

const useStyles = makeStyles((theme) => ({
  videoThumbnail: {
    borderRadius: theme.spacing(1),
  },
  hasPortraitThumbnail: {
    filter: 'brightness(0.25)',
  },
  innerThumbnail: {
    position: 'absolute',
    top: 0,
    left: `calc(50% - ${portraitInnerDimensions.width}px / 2)`,
  },
}))

export default function VideoAndDetails({ video, config, language }) {
  const classes = useStyles()

  const { title, publisherId, status, id, createdDt, orientation } = video

  const getPublisherName = React.useCallback(async () => {
    const publisherResponse = await Publisher.get(publisherId, false, language)
    return publisherResponse.name
  }, [language, publisherId])

  const {
    idle: isIdle,
    pending: isLoading,
    execute: getPublisherData,
    value: publisherName,
    error: failedToGetPublisher,
  } = useAsync(getPublisherName, false)

  React.useEffect(() => {
    if (publisherId && getPublisherData) {
      getPublisherData()
    }
  }, [getPublisherData, publisherId])

  if (failedToGetPublisher) return null

  const isLoaded = Boolean(status && config && publisherName && !isLoading)
  const videoPageUrl = `/videos/${id}?lang=${language}`

  return (
    <ListItem button={true} component={Link} to={videoPageUrl}>
      <Box height={thumbnailDimensions.height} position="relative">
        {isIdle || isLoading ? (
          <Skeleton
            variant="rect"
            width={thumbnailDimensions.width}
            height={thumbnailDimensions.height}
            // don't show loading animation if video status isn't ready
            // because this will end up being the fallback image
            animation={Boolean(status === 'unready')}
            className={classes.videoThumbnail}
          />
        ) : null}
        {isLoaded ? (
          <LazyImage
            alt={`${title} video thumbnail`}
            width={thumbnailDimensions.width}
            height={thumbnailDimensions.height}
            className={classNames(classes.videoThumbnail, {
              [classes.hasPortraitThumbnail]: orientation === 'portrait',
            })}
            // multiplying by 1.5 on image height and width to return
            // a crisp, legible image to the admin
            src={urlReplacer(config.imageUrls.video, {
              width: Math.floor(thumbnailDimensions.width * 1.5),
              height: Math.floor(thumbnailDimensions.height * 1.5),
              id: video.id,
              language,
            })}
          />
        ) : null}
        {isLoaded && orientation === 'portrait' ? (
          <LazyImage
            alt={`${title} video thumbnail`}
            width={portraitInnerDimensions.width}
            height={portraitInnerDimensions.height}
            // multiplying by 2 on image height and width to return
            // a crisp, legible image to the admin
            src={urlReplacer(config.imageUrls.video, {
              width: portraitInnerDimensions.width * 2,
              height: portraitInnerDimensions.height * 2,
              id: video.id,
              language,
            })}
            className={classes.innerThumbnail}
          />
        ) : null}
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="flex-start"
        flexDirection="column"
        ml={2}
        flexGrow={1}
      >
        {isLoading || isIdle ? (
          <>
            <Skeleton variant="text">
              <Typography variant="caption">Publisher Name</Typography>
            </Skeleton>
            <Skeleton variant="text">
              <Typography variant="body2">Title of the Video</Typography>
            </Skeleton>
            <Skeleton variant="text">
              <Typography variant="caption">
                Status • Created DateTime
              </Typography>
            </Skeleton>
          </>
        ) : null}
        {isLoaded ? (
          <>
            <Typography variant="caption" color="textSecondary">
              {publisherName}
            </Typography>
            <Typography variant="body2" color="textPrimary">
              {title}
            </Typography>
            <Typography variant="caption" color="textSecondary">
              {`${status.charAt(0).toUpperCase() + status.slice(1)} • ${moment(
                createdDt,
              ).format('MMM Do, YYYY')}`}
            </Typography>
          </>
        ) : null}
      </Box>
    </ListItem>
  )
}

// Some of these props are not required so I can have the ability to
// render this component as a loading/skeleton component
VideoAndDetails.propTypes = {
  video: PropTypes.shape({
    title: PropTypes.string,
    publisherId: PropTypes.number,
    status: PropTypes.string,
    id: PropTypes.number,
    createdDt: PropTypes.string,
    orientation: PropTypes.string,
  }),
  config: PropTypes.shape({
    imageUrls: PropTypes.objectOf(PropTypes.string),
  }),
  language: PropTypes.string.isRequired,
}

VideoAndDetails.defaultProps = {
  video: {},
  config: undefined,
}
