/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import PropTypes from 'prop-types'
import { IconButton, makeStyles } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import ClearIcon from '@material-ui/icons/Clear'
import { Fuse } from 'components/ui/fuse'

const useStyles = makeStyles((theme) => ({
  alertItem: {
    margin: theme.spacing(1),
  },
}))

const AlertContext = React.createContext()

export function AlertProvider(props) {
  const [alerts, setAlerts] = React.useState([])

  function handleDismissAlert(index) {
    setAlerts((prevState) => {
      const splicedAlerts = [...prevState]
      splicedAlerts.splice(index, 1)
      return splicedAlerts
    })
  }

  /**
   * Throws an alert to the Alerts component
   *
   * @param {Object} newAlert The alert object.
   * @param {String} newAlert.type The alert type. Corresponds to MUI alert severity.
   * @param {String} newAlert.id The alert ID. Must be a unique string.
   * @param {String} newAlert.message The alert message.
   * @param {Function} [newAlert.onDismiss] Custom onDismiss function. Overrides default dismiss behavior.
   * @param {boolean} [newAlert.enableFuse] Enable a fuse timer to auto-dismiss the alert.
   * @example
   * {
   *   type: 'success',
   *   id: 'language_delete_success',
   *   message: `Language ${language.toUpperCase()} successfully deleted.
   *   onDismiss: someFunction,
   *   enableFuse: true,
   * }
   */
  const throwAlert = React.useCallback(
    ({ type, id, message, onDismiss = undefined, enableFuse }) => {
      if (!type || !id || !message) {
        throw new Error('Missing required alert properties.')
      }
      setAlerts((prevState) => {
        // check if alert already exists
        const alertIds = prevState.map((alert) => alert.id)
        if (alertIds.includes(id)) {
          // alert exists. do nothing.
          return prevState
        }
        // alert doesn't exist. add to array.
        return [...prevState, { type, id, message, onDismiss, enableFuse }]
      })
    },
    [],
  )

  const value = { alerts, throwAlert, handleDismissAlert }
  return <AlertContext.Provider value={value} {...props} />
}

export function useAlert() {
  const context = React.useContext(AlertContext)
  if (!context) {
    throw new Error('useAlert must be used within an AlertProvider')
  }
  return context
}

export function Alerts({ variant, ...props }) {
  const classes = useStyles()
  const { alerts: items, handleDismissAlert } = useAlert()
  return (
    <>
      {items.map((item, index) => {
        return (
          <Alert
            className={classes.alertItem}
            variant={variant}
            {...props}
            severity={item.type}
            key={item.id}
            action={
              item.enableFuse ? (
                <Fuse
                  onEnded={
                    item.onDismiss
                      ? () => item.onDismiss(index)
                      : () => handleDismissAlert(index)
                  }
                />
              ) : (
                <IconButton
                  onClick={
                    item.onDismiss
                      ? () => item.onDismiss(index)
                      : () => handleDismissAlert(index)
                  }
                  color="inherit"
                  size="small"
                >
                  <ClearIcon />
                </IconButton>
              )
            }
          >
            {item.message}
          </Alert>
        )
      })}
    </>
  )
}
Alerts.propTypes = {
  variant: PropTypes.string,
}
Alerts.defaultProps = {
  variant: 'filled',
}
