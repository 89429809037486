import React from 'react'
import PropTypes from 'prop-types'
import { useAirTableVideosFromPublisher } from 'hooks/airtable'
import { statusTypes } from 'utils/constants'
import AirTableVideosTable from 'components/views/publisher/bulk-video-uploader/airtable-video-table'
import BulkUploadProcessingList from 'components/views/publisher/bulk-video-uploader/bulk-upload-processing-list'

export default function BulkVideoUploader({ publisherLanguages, publisher }) {
  const {
    videos: airTableVideos,
    setVideos: setAirTableVideos,
    getVideos: getAirTableVideos,
    isReady: airTableIsConfigured,
    isEligible: isEligibleForBulkUpload,
    videosProcessingList,
    startBulkUpload,
    bulkUploadStatus,
    currentUploadIndex,
  } = useAirTableVideosFromPublisher(publisher?.id)

  // Get video rows from AirTable
  React.useEffect(() => {
    if (publisher?.id && isEligibleForBulkUpload) {
      getAirTableVideos()
    }
  }, [publisher.id, isEligibleForBulkUpload, getAirTableVideos])

  const selectedAirTableVideos = airTableVideos.filter(
    (vid) => !vid.$error && vid.is_selected.$value,
  )

  const isReadyToBulkUpload = bulkUploadStatus !== statusTypes.IDLE

  if (isReadyToBulkUpload) {
    return (
      <BulkUploadProcessingList
        publisher={publisher}
        rows={videosProcessingList}
        status={bulkUploadStatus}
        currentUploadIndex={currentUploadIndex}
      />
    )
  }

  return (
    <AirTableVideosTable
      videos={airTableVideos}
      setVideos={setAirTableVideos}
      airTableIsConfigured={airTableIsConfigured}
      isEligibleForBulkUpload={isEligibleForBulkUpload}
      publisherId={publisher.id}
      publisherName={publisher.name}
      publisherLanguages={publisherLanguages}
      onUpload={() => startBulkUpload(selectedAirTableVideos)}
    />
  )
}

BulkVideoUploader.propTypes = {
  publisherLanguages: PropTypes.arrayOf(PropTypes.string),
  publisher: PropTypes.shape({
    tracking_id: PropTypes.number,
    url: PropTypes.string,
    description: PropTypes.string,
    name: PropTypes.string,
    id: PropTypes.number,
    language: PropTypes.string,
  }).isRequired,
}

BulkVideoUploader.defaultProps = {
  publisherLanguages: undefined,
}
