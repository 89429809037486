/* eslint-disable no-underscore-dangle */
import setString from '@youversion/tupos-base/dist/setters/string'

/** *
 * Configuration model.
 */
class VideoUrls {
  constructor(json) {
    if (!json || typeof json !== 'object') return
    this.webm = json.webm
    this.hls = json.hls
  }

  /** Convert VideoUrls to simple object */
  toObject() {
    return {
      webm: this.webm,
      hls: this.hls,
    }
  }

  /** @type {string} */
  get webm() {
    return this._webm
  }

  set webm(webm) {
    this._webm = setString(webm, 'webm')
  }

  /** @type {string} */
  get hls() {
    return this._hls
  }

  set hls(hls) {
    this._hls = setString(hls, 'hls')
  }
}

export default VideoUrls
