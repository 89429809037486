import React from 'react'
import PropTypes from 'prop-types'
import { CircularProgress, CssBaseline, ThemeProvider } from '@material-ui/core'
import { createV3Theme } from '@youversion/react'
import {
  YouVersionAuthContent,
  YouVersionAuthError,
  YouVersionAuthLoading,
  YouVersionAuthProvider,
} from '@youversion/react/providers'
import { AppAuthenticated } from 'views/app-authenticated'
import { AppUnauthenticated } from 'views/app-unauthenticated'
import { AuthError } from 'views/auth-error'

function AuthContent({ hasValidScopes, isSignedIn }) {
  return isSignedIn && hasValidScopes ? (
    <AppAuthenticated />
  ) : (
    <AppUnauthenticated />
  )
}
AuthContent.propTypes = {
  hasValidScopes: PropTypes.bool,
  isSignedIn: PropTypes.bool,
}
AuthContent.defaultProps = {
  hasValidScopes: false,
  isSignedIn: false,
}

export function App() {
  const theme = createV3Theme('light')

  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <YouVersionAuthProvider scopes="write_movies">
          <YouVersionAuthError>
            <AuthError />
          </YouVersionAuthError>
          <YouVersionAuthLoading>
            <CircularProgress />
          </YouVersionAuthLoading>
          <YouVersionAuthContent>
            <AuthContent />
          </YouVersionAuthContent>
        </YouVersionAuthProvider>
      </ThemeProvider>
    </>
  )
}
