/* eslint-disable import/prefer-default-export */

import * as Sentry from '@sentry/react'

const bibleInMemory = {}

const fetchVersion = async (id) => {
  const versionKey = `version-${id}`
  if (typeof bibleInMemory[versionKey] === 'undefined') {
    try {
      const versionResponse = await fetch(
        `https://nodejs.bible.com/api/bible/version/3.1?id=${id}`,
      )
      const version = await versionResponse.json()
      bibleInMemory[versionKey] = version
    } catch (error) {
      Sentry.captureException(error)
    }
  }
  return bibleInMemory[versionKey]
}

const fetchChapter = async (id, reference) => {
  const chapterKey = `chapter-${reference}`
  if (typeof bibleInMemory[chapterKey] === 'undefined') {
    try {
      const chapterResponse = await fetch(
        `https://nodejs.bible.com/api/bible/chapter/3.1/?id=${id}&reference=${reference}`,
      )
      const chapter = await chapterResponse.json()
      bibleInMemory[chapterKey] = chapter
    } catch (error) {
      Sentry.captureException(error)
    }
  }
  return bibleInMemory[chapterKey]
}

const fetchVerses = async (id, reference) => {
  const versesKey = `verse-${reference}`
  if (typeof bibleInMemory[versesKey] === 'undefined') {
    try {
      const versesResponse = await fetch(
        `https://nodejs.bible.com/api/bible/verses/3.1/?id=${id}&references${encodeURIComponent(
          '[]',
        )}=${encodeURIComponent(reference)}`,
      )
      const verses = await versesResponse.json()
      bibleInMemory[versesKey] = verses
    } catch (error) {
      Sentry.captureException(error)
    }
  }
  return bibleInMemory[versesKey]
}

export const getBooks = async (id) => {
  const version = await fetchVersion(id)
  return version.books.map((book) => ({
    value: book.usfm,
    label: book.human,
    chapters: book.chapters.map((chapter) => ({
      value: chapter.usfm,
      label: chapter.human,
    })),
  }))
}

export const getChapter = async (id, reference) => {
  const chapter = await fetchChapter(id, reference)
  return chapter
}

export const getVerses = async (id, reference) => {
  const verses = await fetchVerses(id, reference)
  return verses
}
